import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const PrivateRoute = () => {
    const navigate = useNavigate();
    const location = useLocation();
    localStorage.setItem("lastLocation",location.pathname)
    const {isLoggedIn, setUserLoggedIn, token} = useAuth();
    const checkUserToken = () => {
        const userToken = localStorage.getItem('auth_token');
        if (!userToken) {
            return navigate('/auth/login');
        }
        if (!isLoggedIn && userToken){
            setUserLoggedIn(true)
            return navigate(location.pathname);
        } 
        // return navigate('/add-pet');
    }
    useEffect(() => {
        checkUserToken();
    }, []);
    return (
        <React.Fragment>
            {
                isLoggedIn ? <Outlet /> : null
            }
        </React.Fragment>
    );
}
export default PrivateRoute;