import React from "react";
import "../SelectFacility/SelectFacility.css";

const StepHeader = ({ title, step, subTitle }) => {
  return (
    <div className="">
      <div className="d-flex justify-content-between heading-info mt-0 noto-font">
        <div className="title-size">{title}</div>
        <div className="display-flex-step align-items-center step-btn-section hide-for-mobile hide-for-tablet">{step}</div>
      </div>
      {subTitle && <div className="">
        <div className="paragraph-text margin-top-10 margin-bottom-2-rem noto-font light-weight">{subTitle}</div>
      </div>}
    </div>
  );
};

export default StepHeader;
