import React, { useEffect, useState } from "react";
import { Images } from "../../Constants/Images";
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import SettingsIcon from '@mui/icons-material/Settings';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import "./Header.css";
import useCustomTheme from "../../hooks/useCustomTheme";
import { Link } from "react-router-dom";
import useConfig from "../../hooks/useConfig";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import LogoutIcon from '@mui/icons-material/Logout';
import { Tooltip } from "@mui/material";
import logo from "../../images/logo.png"

const Header = () => {
  const navigate = useNavigate();
  // const {activeClass} = useCustomTheme();
  const {config} = useConfig();
  const {logoUrl} = config;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const bookingLabels = t("data.frontLabels.booking", { returnObjects: true });
  
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    localStorage.removeItem("lang_type");
    localStorage.removeItem("auth_token");
    localStorage.removeItem("lastLocation");
    window.location.reload();
  }
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => setWidth(window.innerWidth);
  const isMobile = width <= 568;

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <div className="container pointer">
      <section className="">
        {isMobile?
         // for mobile
        <div className="d-flex align-items-center pt-4 w-100 justify-content-between">
          <img
            className="a_alda_logo_horizontal-2-2 logo-image-style "
            src={logoUrl}
            onClick={()=>{
              navigate('/');
              // window.location.reload();
            }}
            alt="A_alda_logo_horizontal 2 2"
            onError={(e) => e.target.src = logo}
          />
          <span 
            className="pointer-icon pe-2"
            onClick={handleClick}
          >
            <SettingsIcon />
          </span>
          {/* MY ACCOUNT DROPDOWN MENU */}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem>
              <Link to={`/manage`}
                style={{color: "black"}}
              >
                {bookingLabels?.manage_booking}
              </Link>
            </MenuItem>
            <MenuItem onClick={logout}>{bookingLabels?.logout}</MenuItem>
          </Menu>
        </div>:
        // for desktop
        <div className="d-flex align-items-center pt-4 w-100 justify-content-between container padding-right-0-rem padding-left-mob">
        <img
          className="a_alda_logo_horizontal-2-2 logo-image-style "
          src={logoUrl}
          onClick={()=>{
            navigate('/');
            // window.location.reload();
          }}
          alt="A_alda_logo_horizontal 2 2"
          onError={(e) => e.target.src = logo}
        />
        <div className="pointer-icon">
          <span className="me-3 fs-6">
            <Link to={`/manage`}>
              <u>
                <a href="">{bookingLabels?.manage_booking}</a>
              </u>
            </Link>
          </span>
          <span onClick={logout}>
            <Tooltip title={bookingLabels?.logout}>
              <LogoutIcon/>
            </Tooltip>
          </span>
        </div>
        {/* MY ACCOUNT DROPDOWN MENU */}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem>
            <Link to={`/manage`}
              style={{color: "black"}}
            >
              {bookingLabels?.manage_booking}
            </Link>
          </MenuItem>
          <MenuItem onClick={logout}>{bookingLabels?.logout}</MenuItem>
        </Menu>
      </div>
        }
      </section>
    </div>
  );
};

export default React.memo(Header);
