import {useState, useEffect, useRef, useCallback} from "react";
import {TIMER as timerDelay} from "../Constants/Others";

function useTimer(SEND_OTP_INTERVAL=timerDelay) {
    let TIMER = useRef(null);
    const [timer, setTimer] = useState(SEND_OTP_INTERVAL);
    const [timerStatus, setTimerStatus] = useState(false);

    useEffect(() => {
        if (timer <= 0 || !timer) {
            endTimer();
        }
    }, [timer])

    const startTimer = useCallback(() => {
        setTimer(SEND_OTP_INTERVAL);
        TIMER.current = setInterval(() => {
          if (timer === 0) {
            endTimer();
          }
          setTimer(seconds => seconds - 1);
        }, 1000);
        setTimerStatus(true);
    }, []);
    
    const endTimer = useCallback(() => {
        setTimerStatus(false);
        clearInterval(TIMER.current);
        // setTimer(20);
    }, []);

    return {
        timer,
        timerStatus,
        startTimer,
        endTimer
    }
}

export default useTimer;