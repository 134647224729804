import { useFormikContext } from "formik";
// import { contactNumberFormValidation } from "../../../Constants/Schema";
import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
import 'react-phone-input-2/lib/material.css'
import { FormControl, FormLabel } from "@mui/material";
// import useCustomTheme from "../../../hooks/useCustomTheme";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { resetLogin } from "../../../Redux/auth/slice";
import { LANGUAGES } from "../../../Constants/Constant";

function ContactNumber({loginStatus}) {
    const { t } = useTranslation();
    const {setFieldValue, errors, touched, values, submitForm} = useFormikContext();
    const contactLabels = t("data.frontLabels.contact", { returnObjects: true });
    const dispatch = useDispatch();
    return (
        <>
            <div className="row  m-0 mt-2 w-100">
                <div className="col-12 p-0">
                    <FormControl fullWidth>
                        <FormLabel
                           
                            className={`auth-label redAsterisk`}
                        >
                            {contactLabels?.phone_number}
                        </FormLabel>
                        <PhoneInput
                            autoFormat={false}
                            country={LANGUAGES.JP}
                            countryCodeEditable={false}
                            value={`${values.countryCode}${values.phone}`}
                            onChange={(value, country, e, formattedValue) => {
                                setFieldValue("phone", value.slice(country.dialCode.length))
                                setFieldValue("countryCode", `+${country.dialCode}`)
                            }}
                            onFocus={()=>dispatch(resetLogin())}
                            containerClass={"mb-0"}
                            containerStyle={{
                            }}
                            inputClass="contact-number w-100 h-75"
                            name="phone"
                            defaultErrorMessage="Number not valid"
                            specialLabel=""
                            onKeyDown={e => {
                                if (e.key === "Enter") {
                                    submitForm()
                                }
                            }}
                            inputProps={{
                                autoFocus: true
                              }}
                        />
                        {errors.phone && touched.phone && <small className="text-danger">{errors.phone}</small>}
                    </FormControl>
                </div>
            </div>
        </>
    )
}

export default ContactNumber;