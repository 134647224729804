import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import $ from "jquery";
import StepHeader from "../StepHeader/StepHeader";
import PreferredDoctorCard from "../PreferredDoctorCard/PreferredDoctorCard";
import Button from "../Button/Button";
import CustomCalendar from "../Calendar/CustomCalendar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Images } from "../../Constants/Images";
import MobileVersionCalendar from "../Calendar/MobileVersionCalendar";
import "./SelectDoctor.css";
import "react-multi-carousel/lib/styles.css";
import moment from "moment";
import { setDoctor } from "../../Redux/doctor/slice";
import { getSlots } from "../../Redux/calendar/thunk";
import { getDoctors } from "../../Redux/doctor/thunk";
import useCustomTheme from "../../hooks/useCustomTheme";
import NoDataAvailable from "../NoDataAvailable";
import { colors } from "@mui/material";

$(document).on("click", ".add_remove_style", function () {
  $(this).toggleClass("change-card-style");
});

const SelectDoctor = (props) => {
  const { handleStepAction,navTab} = props;
  const {
    activeClass
} = useCustomTheme();
  const { t } = useTranslation();
  const frontLabels = t("data.frontLabels", { returnObjects: true });
  const dispatch = useDispatch();
  const {config} = useSelector(state => state?.config);
  const CalendarUpdate = useSelector((state) => state.calendar);
  const {steps} = (config && config) || {steps: 6};
  const doctorReducer = useSelector(state => state.doctor);
  const facilityReducer = useSelector(state => state.facility);
  const serviceReducer = useSelector(state => state.service);
  const calendarReducer = useSelector((state) => state.calendar);
  const {facility} = facilityReducer;
  const {doctor, doctors,doctorError,type} = doctorReducer;
  const {service} = serviceReducer;
  const {selectedTime, selectedDateIndex,error,message} = calendarReducer;
  const [state, setState] = useState({
    isDoctorId: doctor?.user_id,
    showMoreDoctors: false,
  });

  const [typeResponse, setTypeResponse] = useState();
  const [dates, setDates] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => setWidth(window.innerWidth);
  const isMobile = width <= 568;

  useEffect(() => {
    
    // if (!doctorCardData) getPreferredDoctorsApiCalls();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  
  useEffect(()=>{
    switch (navTab) {
      case 4:
        document.getElementById('doctor').scrollIntoView({ behavior: 'smooth' }); 
        break;
      case 5:
        document.getElementById('calender').scrollIntoView({ behavior: 'smooth' });
        break;
      default:
        window.scrollTo(0, 0);
    }
  },[navTab,props.timeTabSelected])

  useEffect(() => {
    if (doctors && doctors?.type === "provider" && doctor && doctor?.user_id) {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate());
      const payload = {
        clinicId: facility.id,
        providerId: doctor?.user_id === "Any" ? doctor?.user_id.toLowerCase() : doctor.user_id,
        scheduleType: "in_person",
        serviceId: service.id,
        selected_date_index: 0, //calendar?.selected_date_index || 0,
        select_day: "", //calendar?.select_day || "",
        select_date: moment(currentDate).format("YYYY-MM-DD") //calendar?.select_date || moment(currentDate).format("YYYY-MM-DD")
      }
      dispatch(getSlots(payload))
    } else if (doctors && doctors?.type === "clinic") {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate());
      const payload = {
        clinicId: facility.id,
        providerId: "",
        scheduleType: "in_person",
        serviceId: service.id,
        selected_date_index: 0, //calendar?.selected_date_index || 0,
        select_day: "", //calendar?.select_day || "",
        select_date: moment(currentDate).format("YYYY-MM-DD") // calendar?.select_date || moment(currentDate).format("YYYY-MM-DD")
      }
      dispatch(getSlots(payload))
    }
    else {
        
    }
  }, [doctor]);

  useEffect(() => {
    if (service && service.id && service.service_for === "provider" && facility && facility.id) {
      const payload = {
        serviceId: service?.id,
        clinicId: facility?.id,
        type: service?.service_for,
      };
      dispatch(getDoctors(payload))
    }
   else if (service && service.id && service.service_for === "clinic" && facility && facility.id && selectedDateIndex === -1) {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate());
      const payload = {
        serviceId: service?.id,
        clinicId: facility?.id,
        date: moment(currentDate).format("YYYY-MM-DD"),
        scheduleType: "in_person"
      };
      
      dispatch(getSlots(payload))
      switchToCalendar();
    }
  }, [service, facility])


  const switchToCalendar = () => {
    window.scrollTo(0, 0);
      toggleElementDisplay("doctor-section", "none");
      toggleElementDisplay("mobile-calendar", "block");
  }
  const backToDoctor = () => {
    window.scrollTo(0, 0);
    if(service?.service_for === "provider"){
      toggleElementDisplay("doctor-section", "block");
      toggleElementDisplay("mobile-calendar", "none");
    }else{
      handleStepAction(1)
    }
  }
  const toggleElementDisplay = (elementId, displayValue) => {
    let element = document.getElementById(elementId);
    if (element) {
      element.style.display = displayValue;
    }
  };

  useEffect(()=>{
    doctors?.map((item, index) => {
      // if(item?.user_id === "Any") {
      //   handleDoctorCardBody(item)
      // }
      if(index===0) {
        handleDoctorCardBody(item)
      }
    })
  },[doctors]);

  const handleDoctorCardBody = (item) => {
    const currentDate = CalendarUpdate.selectDate;
    // currentDate.setDate(currentDate.getDate());
    const payload = {
      clinicId: facility.id,
      providerId: item.user_id === "Any" ? item.user_id.toLowerCase() : item.user_id,
      scheduleType: "in_person",
      serviceId: service.id,
      date: moment().format("YYYY-MM-DD")
      // selected_date_index: 0, //calendar?.selected_date_index || 0,
      // select_day: "", //calendar?.select_day || "",
      // select_date: moment(currentDate).format("YYYY-MM-DD") // calendar?.select_date || moment(currentDate).format("YYYY-MM-DD")
    }
    dispatch(setDoctor(item));
    dispatch(getSlots(payload));
    // const newIsDoctorId = (state?.isDoctorId === item?.user_id) ? null : item?.user_id;
    // setState((prevState) => ({
    //   ...prevState,
    //   isDoctorId: newIsDoctorId,
    // }));
    // const selectDoctor = (newIsDoctorId === null) ? null : item;
    // const selectedItem = (newIsDoctorId === null)
    //   ? { "Select Doctor": "" }
    //   : { "Select Doctor": `${item?.kana_lastname} ${item?.kana_firstname} ${item?.lastname}` };
    // dispatch(
    //   DoctorAction({
    //     select_doctor: selectDoctor,
    //     selected_item: selectedItem
    //   })
    // );
    // getPreferredProviderApiCall(item?.user_id);
  };
  const returnDoctorsCardView = (item, index) => {
    return (
      <PreferredDoctorCard
        item={item}
        index={index}
        key={`index ${index}`}
        isDoctorId={doctor?.user_id}
        handleDoctorCardBody={handleDoctorCardBody}
      />
    );
  };
  const handleDoctorsItems = () => {
    setState((prevState) => ({
      ...prevState,
      showMoreDoctors: !prevState.showMoreDoctors,
    }));
  };
  return (
    <div className="first-step pointer">
      {service && service.service_for != "clinic" && <section
        className="bcg-color container padding-130 padding-top-2-188rem padding-zero"
        id="doctor-section"
      >
        <div className="padding-top-first-section">
          <StepHeader
            title={`04. ${frontLabels?.booking?.select_preferred_doctor}`}
            step={`${frontLabels?.booking?.STEP} 04 ${frontLabels?.booking?.OF} 0${steps}`}
            subTitle={frontLabels?.booking?.paragraph_4}
          />
        </div>

        <div id="doctor">
          <div className="facility-card select-doctor-card only-desktop margin-top-2-5rem">
           
              
              {/* {
                doctors?.map((item, index) => {
                  if (!state.showMoreDoctors && index < 5) {
                    return returnDoctorsCardView(item, index);
                  } else if (state.showMoreDoctors) {
                    return returnDoctorsCardView(item, index);
                  }
                })
              } */}
              {doctorError ? (
                <NoDataAvailable subHeadMessage={message}/>
              ) : 
               <div className="row row-cols-lg-5 row-cols-md-2 doctor-img-card row-cols-sm-1 m-0">
                {doctors?.map((item, index) => {
                  if (!state.showMoreDoctors && index < 5) {
                    return returnDoctorsCardView(item, index);
                  } else if (state.showMoreDoctors) {
                    return returnDoctorsCardView(item, index);
                  }
                })}
              </div>
              }
              
            
            {/* SHOW MORE / LESS DOCTORS */}
            {
              (
                doctors?.length > 5 && <div className="text-end pb-4 margin-top-negative-1-5rem">
                  <button className="show-info-details" onClick={handleDoctorsItems}>
                    {!state.showMoreDoctors ? (
                      <span className="noto-font more-bold">
                        {frontLabels?.booking?.show_more_doctors} <img src={Images.show_more} alt={frontLabels?.booking?.show_more_doctors} />
                      </span>
                    ) : (
                      <span className="noto-font more-bold">
                        <img src={Images.show_less} alt={frontLabels?.booking?.show_less_doctors} /> {frontLabels?.booking?.show_less_doctors}
                      </span>
                    )}
                  </button>
                </div>
              )
            }
          </div>

          {/* <div className="container facility-card select-doctor-card only-mobile padding-bottom-4-rem">
            {doctors?.map((item, index) =>
              returnDoctorsCardView(item, index)
            )}
          </div> */}
          <div className="container facility-card select-doctor-card only-mobile padding-bottom-4-rem">
            {error ? (
                  <NoDataAvailable subHeadMessage={message}/>
                ) : 
                doctors?.map((item, index) =>
                  returnDoctorsCardView(item, index)
                )
                }
          </div>
         
          <div className="show-next-page container select-doctor-submit btn-position-fixed padding-zero">
            <Button
              className="next-button previous-button switch-to-facility text-font"
              isLeft
              text={frontLabels?.booking?.previous}
              onClick={() => handleStepAction(1)}
            />
            {doctor ? <Button
              className="next-button switch-to-facility text-font"
              text={frontLabels?.booking?.ancr_next}
              style={activeClass}
              onClick={switchToCalendar}
            /> : <Button
              className="next-button switch-to-facility text-font disabled"
              text={frontLabels?.booking?.ancr_next}
              style={activeClass}
            />}
          </div>

        </div>
      </section>}

      <section className="container second-section padding-130 pt-4  pb-3 padding-zero" id="mobile-calendar">
        <div className="padding-top-first-section " id="calender">
          {!isMobile ? <CustomCalendar dates={dates} selected={true} navTab={navTab} timeTabSelected={props.timeTabSelected} /> : <MobileVersionCalendar />}
        </div>
        <div className="show-next-page select-doctor-submit btn-position-fixed padding-zero">
          <Button
            className="next-button previous-button switch-to-facility text-font"
            isLeft
            text={frontLabels?.booking?.previous}
            onClick={backToDoctor}
          />
          {((service.service_for === "clinic" &&
            (selectedDateIndex > -1) && selectedTime) ||
            (service.service_for === "provider" && doctor?.user_id &&
              (selectedDateIndex > -1) && selectedTime)) ?
            <Button
              className="next-button switch-to-facility text-font"
              text={frontLabels?.booking?.ancr_next}
              style={activeClass}
              onClick={() => {
                handleStepAction(3)
              }}
            /> :
            <Button
              className="next-button switch-to-facility text-font disabled"
              text={frontLabels?.booking?.ancr_next}
              style={activeClass}
              onClick={backToDoctor}
            />
          }
        </div>
      </section>

      <div className="padding-btn-bottom hide-for-mobile">
        <div className="show-next-page  next-previous-button ">
          <Button
            className="btn btn-lg previous-button-style font-text btn-prv-color"
            isLeft
            text={frontLabels?.booking?.previous}
            onClick={() => handleStepAction(1)}
          />
          {((service && service?.service_for === "clinic" &&
            selectedTime) ||
            (service && service?.service_for === "provider" && doctor?.user_id &&
            selectedTime)) ? (
            <Button
              className={`btn btn-lg go-next-page font-text`}
              style={activeClass}
              text={frontLabels?.booking?.ancr_next}
              onClick={() => {
                handleStepAction(3)
              }}
            />
          ) : (
            <Button
              className="btn btn-lg go-next-page font-text disabled"
              style={activeClass}
              text={frontLabels?.booking?.ancr_next}
            />
          )}
        </div>
      </div>

    </div>
  );
};
export default React.memo(SelectDoctor);
