import React, { useEffect } from "react";
import SessionSummary from "../SessionSummary/SessionSummary";
import { useTranslation } from "react-i18next";
import "./Thankyou.css";
import useCustomTheme from "../../hooks/useCustomTheme";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {resetAppointment} from "../../Redux/appointment/slice";
import { resetCalendar } from "../../Redux/calendar/slice";
import { resetService } from "../../Redux/service/slice";
import { resetDoctor } from "../../Redux/doctor/slice";
import { resetFacility } from "../../Redux/facility/slice";
import { resetPet } from "../../Redux/pet/slice";
import DisclaimerImg from "../../images/disclaimer.svg";
import CalendarImg from "../../images/calendar.svg";
import Header from "../Header";
import useCurrentLanguage from "../../hooks/useCurrentLanguage";
import { LANGUAGES } from "../../Constants/Constant";

const ThankYou = (props) => {
  const { t } = useTranslation();
  const lang = useCurrentLanguage();
  const dispatch = useDispatch();
  const frontLabels = t("data.frontLabels", { returnObjects: true });
  const {
    activeClass
  } = useCustomTheme();

  const onHandleResetAppointment = () => {
    dispatch(resetAppointment());
    dispatch(resetCalendar());
    dispatch(resetService());
    dispatch(resetDoctor());
    dispatch(resetFacility());
    dispatch(resetPet());
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // return (
  //   <div className="tankyou-contianer">
  //   <div className="first-step text-center thanks-section pointer">
  //     <div className="container thankYou-container padding-bottom-5-rem">
  //       <img src={CalendarImg} alt="" />
        // {/* <div className="thankyou mt-1 title-text noto-font">{`${frontLabels?.booking?.thank_you}`}</div> */}
        // <div className="thankyou mt-1 title-text noto-font">Thank You</div>
        // <div className="thankyou-sub font-size-1-563rem">
        //   {/* {`${frontLabels?.booking?.for_booking_an_appointment}`} */}
        //   for booking an appointment
        // </div>
  //       <div className="col-md-6 mt-1 m-auto align-items-center summary-cards-padding">
  //         <div className="card padding-1-5rem cards-padding border-around-card" style={{background:"#FEFEFE"}}>
  //           <SessionSummary />
  //         </div>
  //       </div>

  //       <div className="suggestion-text mt-3 d-flex justify-content-center align-items-center ">
  //         <div>
            // <img
            //   src={DisclaimerImg}
            //   className="icon-size"
            //   alt="disclaimer"
            // />
  //         </div>
  //         <div className="margin-left-6px font-size-13px">{`${frontLabels.booking.paragraph_8}`}</div>
  //       </div>
        // <div>
        //   <Link
        //     to={"/manage"}
        //   >
        //     <button
        //       type="button"
        //       className="btn-lg mt-3 btn btn-success manage-button font-gottam semi-bold"
        //       style={activeClass}
        //       onClick={onHandleResetAppointment}
        //     >
        //       Manage Booking
        //     </button>
        //   </Link>
        // </div>
  //     </div>
  //   </div>
  //   </div>
  // );
  return(
    <div className="thnakYouPage-main-contianer">
      <Header />
      <div className="thnakYouPage-contianer">
        <div className="heading-contianer">
          <img src={CalendarImg} alt="" className="calender-img"/>
          {/* <div className="thankyou mt-1 title-text noto-font">{`${frontLabels?.booking?.thank_you}`}</div> */}
         {lang == LANGUAGES.EN ? <><div className="thankyou-heading noto-font">Thank You </div>
          <div className="thankyou-sub font-size-1-563rem">
            for booking an appointment
          </div> </>  :  <div className="thankyou-heading noto-font">ご予約ありがとうございます</div>
          }
        </div>
        <div className="session-summary-conainer">
          <SessionSummary />
        </div>
        <div className="disclaimer-container">
          <img
              src={DisclaimerImg}
              className="icon-size"
              alt="disclaimer"
          />
          <span className="disclaimer-text">
            {`${frontLabels?.booking?.paragraph_8}`}
          </span>
        </div>
        <div className="manage-booking-btn">
          <Link
            to={"/manage"}
          >
            <button
              type="button"
              className="btn-lg btn btn-success font-gottam semi-bold"
              style={activeClass}
              onClick={onHandleResetAppointment}
            >
              {frontLabels?.booking?.manage_booking}
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
};
export default React.memo(ThankYou);
