import CircularProgress from '@mui/material/CircularProgress';

export default function Loader() {
    return (
        <>
            <div className="d-flex justify-content-center align-items-center" style={{height: "100vh"}}>
                <CircularProgress />
            </div>
        </>
    )
}