import { useEffect } from 'react';

const Clarity = ({ projectId, commonProjectId }) => {
  useEffect(() => {
    if (projectId && commonProjectId) {
      // console.warn("Both projectId and commonProjectId are provided. Initializing both instances of Clarity.");
    }

    if (projectId) {
      // console.log('Initializing Microsoft Clarity with Project ID:', projectId);
      initializeClarity(projectId);
    }

    if (commonProjectId) {
      // console.log('Initializing Microsoft Clarity with Common Project ID:', commonProjectId);
      initializeClarity(commonProjectId);
    }

  }, [projectId, commonProjectId]);

  const initializeClarity = (clarityProjectId) => {
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = `https://www.clarity.ms/tag/${i}`;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, 'clarity', 'script', clarityProjectId);
  };

  return null;
};

export default Clarity;
