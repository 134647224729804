import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../../Services/Api/Index";
import { API_PATH } from '../../Constants/ApiPath';
import { API_TYPE } from "../../Constants/ApiType";
import { updateIsInvalidScreen } from "./slice";
import { LANGUAGES } from "../../Constants/Constant";

export const confirmation = createAsyncThunk(
  "auth/confirmation",
  async (payload, { rejectWithValue }) => {
    try {
      const lang_code = localStorage.getItem("lang_type");
      const response = await Axios({
        method: API_TYPE.POST,
        url: API_PATH.VERIFY,
        data: payload,
        headers: {
          "Accept-Language": lang_code || LANGUAGES.JP,
        },
      });
      const data = await response.data;
      if (data.status === "success" && data.statuscode === 200) {
        return { data: data.data, message: data.message, email: data.data.email };
      } else if (data.status === "error" && data.statuscode === 400 && data.data && data.data.is_access === "new_user") {
        return { data: data.data, message: data.message, status: true };
      } else if (payload.login_type === "pet_id" && data.data.is_access && ["pet_email_sent", "pet_email"].includes(data.data.is_access)) {
        return { ...data, is_access: data.data.is_access, client_id: data.data.client_id, message: data.message}
      }
      throw data.message
      // throw new Error(data.message || "Something went wrong!");
    } catch (err) {
      if (typeof err === "object" && err.message) {
        err = err.message;
      }
      return rejectWithValue(err);
    }
  }
);

export const sendOtp = createAsyncThunk(
  "auth/sendOtp",
  async (payload, { rejectWithValue }) => {
    try {
      const lang_code = localStorage.getItem("lang_type");
      const response = await Axios({
        method: API_TYPE.POST,
        url: API_PATH.SEND_OTP,
        data: payload,
        headers: {
          "Accept-Language": lang_code || LANGUAGES.JP,
        },
      });
      const data = await response.data;
      if (data.status === "success" && data.statuscode === 200) {
        data["loginStatus"] = "otpSent";
        return data;
      }
      throw data.message || "Something went wrong!";
    } catch (err) {
      if (typeof err === "object" && err.message) {
        err = err.message;
      }
      return rejectWithValue(err);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "auth/verifyOtp",
  async (payload, { rejectWithValue }) => {
    try {
      const lang_code = localStorage.getItem("lang_type");
      const response = await Axios({
        method: API_TYPE.POST,
        url: API_PATH.VERIFY_OTP,
        data: payload,
        headers: {
          "Accept-Language": lang_code || LANGUAGES.JP,
        },
      });
      const data = await response.data;
      if (data.status === "success" && data.statuscode === 200) {
        return { ...data, status: data.data.is_access || payload.type };
      }
      throw data.message || "Something went wrong!";
    } catch (err) {
      if (typeof err === "object" && err.message) {
        err = err.message;
      }
      return rejectWithValue(err);
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async (payload, { rejectWithValue }) => {
    try {
      const lang_code = localStorage.getItem("lang_type");
      const response = await Axios({
        method: API_TYPE.POST,
        url: API_PATH.VERIFY_EMAIL,
        data: payload,
        headers: {
          "Accept-Language": lang_code || LANGUAGES.JP,
        },
      });
      const data = await response.data;
      if (data.status === "success" && data.statuscode === 200) {
        return { status: "emailSent", message: data.message };
      }
      throw data.message || "Something went wrong!";
    } catch (err) {
      if (typeof err === "object" && err.message) {
        err = err.message;
      }
      return rejectWithValue(err);
    }
  }
);

export const verifyRegistrationToken = createAsyncThunk(
  "auth/verifyRegistrationToken",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const lang_code = localStorage.getItem("lang_type");
      const response = await Axios({
        method: API_TYPE.POST,
        url: API_PATH.VERIFY_REGISTRATION_TOKEN,
        data: payload,
        headers: {
          "Accept-Language": lang_code || LANGUAGES.JP,
        },
      });
      const data = await response.data;
      if (data.status === "success" && data.statuscode === 200) {
        return { data: data.data };
      } else if (data.statuscode === 400 && data.status === "error" && data.data.is_access === "invalid_token") {
        localStorage.removeItem('auth_token');
        dispatch(updateIsInvalidScreen(true));
      }
      throw data.message || "Something went wrong!";
    } catch (err) {
      if (typeof err === "object" && err.message) {
        err = err.message;
      }
      return rejectWithValue(err);
    }
  }
);

export const register = createAsyncThunk(
  "auth/register",
  async (payload, { rejectWithValue }) => {
    try {
      const lang_code = localStorage.getItem("lang_type");
      const response = await Axios({
        method: API_TYPE.POST,
        url: API_PATH.USER_REGISTER,
        data: payload,
        headers: {
          "Accept-Language": lang_code || LANGUAGES.JP,
        },
      });
      const data = await response.data;
      if (data.status === "success" && data.statuscode === 200 && data.data.auth_token) {
        localStorage.setItem("auth_token", data.data.auth_token)
        return { token: data.data.auth_token };
      } else {
        throw data.message;
      }
    } catch (err) {
      if (typeof err === "object" && err.message) {
        err = err.message;
      }
      return rejectWithValue(err);
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (payload, { rejectWithValue }) => {
    try {
      const lang_code = localStorage.getItem("lang_type");
      const response = await Axios({
        method: API_TYPE.POST,
        url: API_PATH.LOGIN,
        data: payload,
        headers: {
          "Accept-Language": lang_code || LANGUAGES.JP,
        },
      });
      const data = await response.data;
      if (data.status === "success" && data.statuscode === 200 && data.data.auth_token) {
        localStorage.setItem("auth_token", data.data.auth_token)
        return { token: data.data.auth_token };
      }
      throw data.message || "Something went wrong!";
    } catch (err) {
      if (typeof err === "object" && err.message) {
        err = err.message;
      }
      return rejectWithValue(err);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (payload, { rejectWithValue }) => {
    try {
      const lang_code = localStorage.getItem("lang_type");
      const response = await Axios({
        method: API_TYPE.POST,
        url: API_PATH.RESET_PASSWORD,
        data: payload,
        headers: {
          "Accept-Language": lang_code || LANGUAGES.JP,
        },
      });
      const data = await response.data;
      if (data.status === "success" && data.statuscode === 200) {
        return { ...data, type: payload.login_type };
      }
      throw data.message;
    } catch (err) {
      if (typeof err === "object" && err.message) {
        err = err.message;
      }
      return rejectWithValue(err);
    }
  }
);

export const resetPasswordTokenVerify = createAsyncThunk(
  "auth/resetPasswordTokenVerify",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const lang_code = localStorage.getItem("lang_type");
      const response = await Axios({
        method: API_TYPE.POST,
        url: API_PATH.VERIFY_RESET_PASSWORD_TOKEN,
        data: payload,
        headers: {
          "Accept-Language": lang_code || LANGUAGES.JP,
        },
      });
      const data = await response.data;
      if (data.status === "success" && data.statuscode === 200 && data.data.client_id) {
        return { clientId: data.data.client_id };
      } else if (data.statuscode === 400 && data.status === "error" && data.data.is_access === "invalid_token") {
        localStorage.removeItem('auth_token');
        dispatch(updateIsInvalidScreen(true));
      }
      throw data.message || "Something went wrong!";
    } catch (err) {
      if (typeof err === "object" && err.message) {
        err = err.message;
      }
      return rejectWithValue(err);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (payload, { rejectWithValue }) => {
    try {
      const lang_code = localStorage.getItem("lang_type");
      const response = await Axios({
        method: API_TYPE.POST,
        url: API_PATH.CREATE_PASSWORD,
        data: payload,
        headers: {
          "Accept-Language": lang_code || LANGUAGES.JP,
        },
      });
      const data = await response.data;
      if (data.status === "success" && data.statuscode === 200 && data.data.auth_token) {
        localStorage.setItem("auth_token", data.data.auth_token);
        const lastLocation = localStorage.getItem("lastLocation");
        window.location.href = !!lastLocation ? lastLocation : "/manage";
        return { ...data, message: data.message, token: data.data.auth_token };
      }
      throw data.message || "Something went wrong!";
    } catch (err) {
      if (typeof err === "object" && err.message) {
        err = err.message;
      }
      return rejectWithValue(err);
    }
  }
);