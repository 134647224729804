import React, { useEffect } from "react";
import DoneIcon from "@mui/icons-material/Done";
import TestingImage from "../../images/TestingImage.jpg";
import { Images } from "../../Constants/Images";
import "./PreferredDoctorCard.css";
import useConfig from "../../hooks/useConfig";
import useCustomTheme from "../../hooks/useCustomTheme";
import { useTranslation } from "react-i18next";

const PreferredDoctorCard = (props) => {
  const { kana_firstname, kana_lastname, lastname,firstname, profile_picture } = props?.item;
  const { t } = useTranslation();
  const formLabels = t("data.frontLabels", { returnObjects: true });
  const {config} = useConfig();
  const {btn_clinic_bg_color} = useCustomTheme();
  const { handleDoctorCardBody,index } = props;

  useEffect(() => {

  }, [props?.item, props?.isDoctorId])
  return (
    <>
      <div className="col-12 card-width-for-tab row-cols-img pointer-icon" onClick={() => handleDoctorCardBody(props?.item)}>
        <div className={`card card-1 position-relative d-flex flex-row-class overflow-hidden cards-dimensions  margin-bottom-3-rem margin-bottom-2-rem border-around-card`}
          style={{
            
              background: (props?.isDoctorId === props?.item?.user_id ? btn_clinic_bg_color : ""),
              color:(props?.isDoctorId === props?.item?.user_id ? "white" : "")
            
          }}
        >
          <div className="tick-icons">
            {!(props?.isDoctorId === props?.item?.user_id) ? (
              <div className="checked-mark">
                <DoneIcon
                  className="in-active-tick"
                  onClick={() => handleDoctorCardBody(props?.item)}
                />
              </div>
            ) : (
              <p className="tick-mark-box"
                style={{background: btn_clinic_bg_color}}
              >
                <DoneIcon
                  className="active-tick"
                  onClick={() => handleDoctorCardBody(props?.item)}
                />
              </p>
            )}
          </div>

          <div className="doctor-image-position">
            <div className="">
              {props?.item?.kana_lastname === "Any" ? (
                <img src={Images.DefaultDoctorImage} alt="default" className="doctors-img-size p-0 default-image-border border-default-img" />
              ) : (
                profile_picture ?
                <img 
                  src={`${config?.imageBaseUrl}/userprofiles/${profile_picture}`}
                  alt="img" 
                  className="doctors-img-size p-0 image-border-radius img-border" 
                  onError={e => {
                    e.target.src = Images.DefaultDoctorImage;
                  }}
                /> : 
                <img src={Images.DefaultDoctorImage} alt="img" className="doctors-img-size p-0 default-image-border border-default-img" />
              )}
            </div>
          </div>
          <div className="card-content-area default-alignment">
            <div className={`d-flex w-100 set-height ${props?.item?.kana_lastname !== "Any" && 'padding-left-9px'}`}>
              {props?.item?.kana_lastname === "Any" ? (
                <div>
                  <div className="mb-2 text-center w-100 default-text font-gottam-bold">
                    {`${kana_lastname}`}
                  </div>
                </div>
              ) : (
                <div className="doctor-name font-gottam-bold truncated-text-2-lines">
                  {index===0 ? formLabels?.booking?.any: <>{`${lastname} ${firstname}`}</>}
                  {/* {kana_lastname} {kana_firstname} */}
                  {/* {lastname} */}
                </div>
              )}
              {props?.item?.specialization === "" ? (
                <> </>
              ) : (
                <div className="doctor-specility truncated-text-2-lines noto-font" style={{color:(props?.isDoctorId === props?.item?.user_id ? "white" : "")}}>
                  {/* {props?.item?.specialization} */} {props?.item?.kana_lastname !== "Any" && props?.item?.name}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(PreferredDoctorCard);
