import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import {LoginValidation } from '../../Constants/Schema';
import { Form, Formik } from 'formik';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CheckYourEmail from './LoginForm/CheckYourEmail';
import OtpForm from './OtpForm';
import EnterEmail from './LoginForm/EnterEmail';
import useCustomTheme from '../../hooks/useCustomTheme';
import ContactNumber from './LoginForm/ContactNumber';
import EmailForm from './LoginForm/EmailForm';
import { confirmation, forgotPassword } from '../../Redux/auth/thunk';
import useTimer from '../../hooks/useTimer';
import { TailSpin } from 'react-loader-spinner';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import SubHeading from './SubHeading';
import { toast } from 'react-toastify';
import { TOAST_TIMER } from '../../Constants/Others';
import { resetLogin } from '../../Redux/auth/slice';
import { useTranslation } from 'react-i18next';
import useCurrentLanguage from '../../hooks/useCurrentLanguage';
import { LANGUAGES } from '../../Constants/Constant';

export const TYPE = {
    0: "contact",
    1: "email"
}
const INITIAL_VALUE = {
    phone: "",
    countryCode: "+81",
    email: "",
    petId: "",
};
const StyledTab = styled(Tab)(({ theme, tab }) => ({
    '@media (max-width: 576px)': {
        width: tab == 0 ? "8rem" : tab == 1 ? "4rem" : "7rem"
    },
}));

const ForgotPasswordPage = () => {
    let globaltimeout = null; 
    const { timer, timerStatus, startTimer, endTimer } = useTimer();
    const dispatch = useDispatch();
    const lang = useCurrentLanguage();
    const auth = useSelector(state => state.auth);
    const { loginStatus, error, message, loading, token, clientId, forgotPasswordSuccess, forgotPasswordError } = auth;
    const [initForm, setInitForm] = useState(INITIAL_VALUE);
    const [tab, setTab] = useState(0);
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => setWidth(window.innerWidth);
    const { btn_clinic_bg_color, } = useCustomTheme();
    const navigate = useNavigate()
    const [feildStatus, setFeildStatus] = useState(false)
    const [recentError, setRecentError] = useState(null);
    const { t } = useTranslation();
    const bookingtLabels = t("data.frontLabels.booking", { returnObjects: true });
    const frontLabels = t("data.frontLabels", { returnObjects: true });
    const isMobile = width <= 568;
    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const FORM_VALIDATION =  LoginValidation();


    const onHandleChangeTab = (e, tab, resetForm) => {
        setFeildStatus(false);
        setTab(tab)
        setInitForm(INITIAL_VALUE);
        resetForm();
        dispatch(resetLogin())
    }


    useEffect(() => {
        if (forgotPasswordSuccess) {
            startTimer();
            setFeildStatus(true)
            toast.success(forgotPasswordSuccess);
        } else if (forgotPasswordError) {
            startTimer();
            toast.error(bookingtLabels?.something_went_wrong||"Something went wrong!", { autoClose: TOAST_TIMER });
        }
    }, [forgotPasswordSuccess, forgotPasswordError]);


    useEffect(() => {
        if ( error && message && (error !== recentError) && (message !== recentError)) {
                toast.error(message, { autoClose: TOAST_TIMER });
                setRecentError(message);
        }
        else if (!error && message !== "" && message !== 500 && message !== 200 && message !== "200" && error !== "The payload is invalid.") {
            toast.success(message, { autoClose: TOAST_TIMER });
                setRecentError(message);   
        }
    }, [error, message])

    useEffect(() => {
        if (loginStatus === "resetPassword") {
            navigate("/auth/reset-password");
        }
    }, [loginStatus])

    const onHandleForgot = (args) => {
        try {
            const payload = {
                lang: lang || LANGUAGES.EN,
            };
            switch (tab) {
                case 0:
                    payload["contact_no"] = args["phone"];
                    payload["country_code"] = args["countryCode"];
                    payload["login_type"] = "contact";
                    delete args["email"];
                    break;
                case 1:
                    payload["email"] = args["email"];
                    payload["login_type"] = "email";
                    delete args["phone"];
                    delete args["countryCode"];
                    break;
                default:
                    throw new Error("Tab type undefined.");
            }
            dispatch(confirmation(payload)).then((d) => {
                const { payload, error } = d;
                if (typeof error === "object" && error.message && recentError!==error) {
                    toast.error(error, { autoClose: TOAST_TIMER });
                    setRecentError(error)
                    if(globaltimeout) clearTimeout(globaltimeout)
                    globaltimeout = setTimeout(()=>{
                        setRecentError(null)
                    },2700)
                    return;
                }
                if (payload.data.is_access === 'new_user') {
                    if(recentError!==payload.data){
                        toast.error(payload.data, { autoClose: TOAST_TIMER });
                        setRecentError(payload.data)
                        if(globaltimeout) clearTimeout(globaltimeout)
                        globaltimeout = setTimeout(()=>{
                            setRecentError(null)
                        },2700)
                    dispatch(resetLogin())
                    }
                    return;
                }

                const forgotpayload = {
                    client_id: payload?.data?.client_id,
                    login_type: TYPE[tab]
                }
                dispatch(forgotPassword(forgotpayload))
            })
        } catch (error) {
            if (typeof error === "object" && error.message) {
                error = error.message;
            }if(error  && recentError!==error){
                toast.error(error, { autoClose: TOAST_TIMER });
                setRecentError(error)
                if(globaltimeout) clearTimeout(globaltimeout)
                globaltimeout = setTimeout(()=>{
                    setRecentError(null)
                },2700)
            }
           
        }

    }

    const handleBackToLogin = () => {
        // dispatch(resetLogin())
        navigate("/auth/login")
        
    }
    return (
        <div>
            {/* <BoxContainer style={{ height: `${!isMobile ? "100vh" : "100%"}` }} className='mt-3 mt-sm-0'> */}
            <BoxContainer className='mt-3 mt-sm-0 mb-4'>
                <Box>
                    {loginStatus && ["login", "new_user", "existing_user", "pet_email_sent"].includes(loginStatus) && (
                        <Headings className='mb-3'>
                            <Heading className='mb-1'>
                            {frontLabels?.booking?.forgot_password || "Forgot Password"}
                            </Heading>
                            <SubHeadingStyle className='m-auto'>
                                <SubHeading tab={tab} action={"forget"} />
                            </SubHeadingStyle>
                        </Headings>
                    )}
                    <TabsContainer>
                        {loginStatus && ["login", "new_user", "existing_user", "pet_email_sent"].includes(loginStatus) && (
                            <Formik
                                initialValues={initForm}
                                enableReinitialize={true}
                                validationSchema={FORM_VALIDATION}
                                onSubmit={onHandleForgot}
                            >
                                {({ values, errors, setFieldValue, setFieldTouched, touched, resetForm }) => (
                                    <>
                                        <Form
                                            onKeyUp={e => {
                                                // if (e.key === "Enter") {
                                                //     submitForm();
                                                // }
                                            }}
                                        >
                                            <Tabs
                                                value={tab}
                                                onChange={(e, tab) => onHandleChangeTab(e, tab, resetForm)}
                                                // aria-label="disabled tabs example"
                                                indicatorColor="none"
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                allowScrollButtonsMobile
                                                sx={{
                                                    background: "#F3F4F6",
                                                    borderRadius: "4px",
                                                    width: "100%",
                                                    height: "44px",
                                                    border: "1px solid gray",
                                                    margin: "auto",
                                                    marginTop: `${!isMobile} ? "20px":"5px"`,
                                                    marginBottom: `${!isMobile} ? "20px":"5px"`,
                                                }}
                                            >
                                                <StyledTab
                                                    className='contact-tab-switch text-nowrap w-50'
                                                    // label="Contact Number"
                                                    label={frontLabels?.booking?.contact_number}
                                                    sx={tab === 0 ? {
                                                        background: btn_clinic_bg_color,
                                                        textTransform: "capitalize",
                                                        '&.Mui-selected': {
                                                            color: '#fff',
                                                        }
                                                    } : { textTransform: "capitalize" }}
                                                />
                                                <StyledTab
                                                    className='email-tab-switch text-nowrap w-50'
                                                    sx={tab === 1 ? {
                                                        background: btn_clinic_bg_color,
                                                        textTransform: "capitalize",
                                                        '&.Mui-selected': {
                                                            color: '#fff',
                                                        }
                                                    } : { textTransform: "capitalize" }}
                                                    // label="Email Address"
                                                    label={frontLabels?.booking?.email_address}
                                                />
                                            </Tabs>
                                            <div
                                                role="tabpanel"
                                                hidden={tab !== 0}
                                                index={0}
                                                value="0"
                                                className='w-100 mb-3'

                                            >
                                                <ContactNumber loginStatus={loginStatus} />
                                                <div className='mt-2'>
                                                    {timerStatus && 
                                                        <span className='float-end mb-3'>
                                                            <span >{bookingtLabels?.resend_otp} </span>
                                                                00:{timer < 10 ? `0${timer}` : timer}
                                                            </span>
                                                    }
                                                </div>  
                                            </div>
                                            <div
                                                role="tabpanel"
                                                hidden={tab !== 1}
                                                index={1}
                                                value="1"
                                                className='w-100 mb-3'

                                            >
                                                <EmailForm loginStatus={loginStatus} feildStatus={feildStatus} />
                                                <div className='mt-2'>
                                                    {timerStatus && 
                                                        <span className='float-end mb-3'>
                                                            <span >{frontLabels?.booking?.re_send_email} </span>
                                                                00:{timer < 10 ? `0${timer}` : timer}
                                                            </span>
                                                    }
                                                </div>  
                                            </div>
                                             
                                            
                                            <div>
                                                {!loading ?
                                                    <Button
                                                        variant="contained"
                                                        disabled={timerStatus}
                                                        className='w-100 py-3 mb-3'
                                                        type='submit'
                                                        size="large"
                                                        sx={{
                                                            borderRadius: "8px",
                                                            background: btn_clinic_bg_color,
                                                            width: "100%",
                                                            fontFamily: "Noto Sans",
                                                            fontSize: "18px",
                                                            fontStyle: "normal",
                                                            fontWeight: "500",
                                                            lineHeight: "normal",
                                                            '&:hover': {
                                                                // color: btn_clinic_bg_color,
                                                                backgroundColor: btn_clinic_bg_color,
                                                            }
                                                        }}
                                                    >
                                                            {bookingtLabels.send_request}
                                                    </Button>
                                                    :
                                                    <TailSpin
                                                        height="35"
                                                        width="35"
                                                        color={btn_clinic_bg_color}
                                                        ariaLabel="tail-spin-loading"
                                                        radius="2"
                                                        wrapperStyle={{marginBottom:"8px"}}
                                                        wrapperClass="d-flex justify-content-center"
                                                        visible={true}
                                                       
                                                    />
                                                }
                                            </div>
                                            <div>
                                                <Button
                                                    variant="outlined"
                                                    className='w-100'
                                                    onClick={handleBackToLogin}
                                                    sx={{
                                                        borderRadius: "4px",
                                                        border: "1px solid #D8DAE5",
                                                        background: "#FFF",
                                                        
                                                    }}

                                                >
                                                    <p style={{color:" #696F8C",
                                                        fontSize: "16px",
                                                        }}
                                                        className='m-auto py-2'
                                                        >{frontLabels?.booking?.back_to_login}</p>
                                                    
                                                </Button>
                                            </div>
                                        </Form>
                                    </>
                                )}
                            </Formik>
                        )}
                        {loginStatus && ["otpSent", "reset_password"].includes(loginStatus) && <OtpForm />}
                         {/* enter email */}
                        {loginStatus && ["enter_email", "pet_email"].includes(loginStatus) && <EnterEmail />}
                        {/* CHECK YOUR EMAIL */}
                        {loginStatus && ["emailSent","email_exist","pet_email_sent","email_view"].includes(loginStatus) && <CheckYourEmail />}
                        {/* {loginStatus && ["emailSent"].includes(loginStatus) && <CheckYourEmail />} */}
                    </TabsContainer>
                </Box>
            </BoxContainer>
            {/* enter otp */}


        </div>
    )
}

export default ForgotPasswordPage

const BoxContainer = styled.div`
    display: flex;
    align-items: start;
    justify-content: center;
    @media (max-width: 1200px) and (min-width: 579px) {
        height:58vh
      }
   
`;
const Box = styled.div`
    width:80%;
    display:flex;
    flex-direction:column;
    margin-top:10%;
    @media (max-width: 580px) {
        width:"100%";
        margin-top:5%;
    }
   
`;
const Headings = styled.div`
    text-align:center;
    
`;
const Heading = styled.div`
    color: #1F2124;
    font-family: Noto Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;
const SubHeadingStyle = styled.div`
    width:80%;
    color: #4B4B5A;
    text-align: center;
    font-family: Noto Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 580px) {
        width:"100%";
        font-size: 14px;
    }
    
`
const TabsContainer = styled.div`
    
`
const ResendEmail = styled.div`
    color: #36F;
    text-align: center;
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`