export const API_PATH = {
  CONFIG: "bookingportal",
  CLINIC_DETAILS: "bookingportal/get_clinic", // GET PREFERRED FAICILITY
  CLINIC_SERVICE: "bookingportal/get_clinic_service", // GET PREFERRED SERVICES
  DOCTORS_DETAILS: "bookingportal/service", // GET DOCTORS OR TIME SLOTS
  PROVIDER_DETAILS: "bookingportal/provider", 
  SLOTS_DETAILS: "bookingportal/slots",
  CHECK_DOCTORS: "bookingportal/check_doctor",
  // SPECIES_LIST: "bookingportal/pet/species",
  // BREED_LIST: "bookingportal/pet/breeds",
  CREATE_APPOINTMENT: "bookingportal/create",
  DASHBOARD_DATA: "dashboard",
  RESCHEDULE_APPOINTMENT:"bookingportal/update",
CANCEL_APPOINTMENT:"bookingportal/cancel",
  // NEW LOGIN FLOW
  VERIFY: "auth/verify",
  SEND_OTP: "auth/send-otp",
  VERIFY_OTP: "auth/verify-otp",
  VERIFY_EMAIL: "auth/verify-email",
  VERIFY_REGISTRATION_TOKEN: "auth/verify-registration-token",
  USER_REGISTER: "auth/create-profile",
  // CREATE PET
  CREATE_PET: "create-pet",
  GET_PETS: "/pets",
  GET_SPECIES: "bookingportal/pet/species",
  GET_BREEDS: "bookingportal/pet/breeds",
  LOGIN:"auth/login",
  RESET_PASSWORD: "auth/reset-password",
  VERIFY_RESET_PASSWORD_TOKEN: "auth/verify-reset-password-token",
  CREATE_PASSWORD: "auth/create-password"
};
