import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Alert, AlertTitle, Button, FormControl, FormLabel, IconButton, InputAdornment, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { ResetPasswordValidation } from "../../Constants/Schema";
import useCustomTheme from "../../hooks/useCustomTheme";
import { forgotPassword, resetPassword, resetPasswordTokenVerify } from "../../Redux/auth/thunk";
import useQuery from "../../hooks/useQuery";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { resetLogin, resetLoginError } from "../../Redux/auth/slice";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { Images } from "../../Constants/Images";
import { TOAST_TIMER } from "../../Constants/Others";


function ForgotPassword() {
    let globaltimeout = null; 
    const {
        background_color,
        btn_clinic_bg_color,
        btn_clinic_text_color,
        btn_telehealth_bg_color,
        btn_telehealth_text_color,
        lp_bg_color
    } = useCustomTheme();
    // const { t } = useTranslation();
    // const formLabels = t("data.frontLabels", { returnObjects: true });
    // const frontMessages = t("data.frontMessages", { returnObjects: true });
    const auth = useSelector(state => state.auth);
    const { loading, error, clientId, resetPasswordSuccess, resetPasswordError, token, isInvalidScreen } = auth;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState({
        password: false,
        confirmPassword: false
    });
    const [recentError, setRecentError] = useState(null);
    const { t } = useTranslation();
    const formLabels = t("data.frontLabels", { returnObjects: true });
    const frontMessages = t("data.frontMessages", { returnObjects: true });
    const { data } = useQuery(window.location.search);

    const onHandleSubmit = (payload, formikBag) => {
        if (payload.password.length < 6 && payload.password.length!==recentError) {
            toast.error(frontMessages?.MIN_PASSWORD || " Password must be 6 character",{ autoClose: TOAST_TIMER});
            setRecentError(frontMessages?.MIN_PASSWORD)
            if(globaltimeout) clearTimeout(globaltimeout)
            globaltimeout = setTimeout(()=>{
                setRecentError(null)
            },2700)
            return
        }
        payload["client_id"] = clientId;
        payload["password_confirmation"] = payload.confirmPassword;
        payload["token"]= data["t"];
        payload["password_type"]=  data?.type;
        delete payload.confirmPassword;
        dispatch(resetPassword(payload));
    }

    
    const handleClose = () => {
        dispatch(resetLoginError(false));
        dispatch(resetLogin())
        navigate("/auth/login")

    };

    useEffect(() => {
        localStorage.removeItem('auth_token');
        if (data["t"]) {
            const payload = {
                token: data["t"]
            }
            dispatch(resetPasswordTokenVerify(payload));
        }
            }, []);


    useEffect(() => {
        if (resetPasswordSuccess) {
            toast.success(resetPasswordSuccess)
            reloadPageAfterDelay();
        }
    }, [resetPasswordSuccess]);



    const reloadPageAfterDelay = () => {
        setTimeout(() => {
            window.location.reload();
        }, 1500);
    };

    const ResetPasswordValidation = () => Yup.object({
        password: Yup.string().required(`${frontMessages?.BOOK_CTL_PASSWORD_REQ}`),
        confirmPassword: Yup.string()
            .required(`${frontMessages?.CONFIRM_PASSWORD}`)
            .oneOf([Yup.ref('password'), null], `${frontMessages?.CONFIRM_PASSWORD}`),
    });

    return (
        <>
            <div className="d-flex align-items-center justify-content-center mt-5 h-100 mb">
                <div className="px-2 px-sm-0 w-75">
                    {!error && !loading &&
                        <div>
                            <div className="text-center mb-3">
                                <h1 className="mb-0">
                                    {/* {data?.type==="create_password" ? "Create Password" : "Reset Password"} */}
                                    {data?.type === "create_password" ? `${formLabels?.booking?.crt_pass}` : `${formLabels?.booking?.rst_pass}`}
                                </h1>
                                <p>{frontMessages?.CHOOSE_NEW_PSW}</p>
                            </div>
                            <Formik
                                initialValues={{}}
                                enableReinitialize={true}
                                validationSchema={ResetPasswordValidation()}
                                onSubmit={onHandleSubmit}
                                validateOnBlur={true}
                            >
                                {({ errors, values, touched, setFieldValue, handleBlur }) => (
                                    <>
                                        <Form>
                                            <div className="mt-4">
                                                <FormControl fullWidth>
                                                    <FormLabel className={`auth-label redAsterisk`}>{frontMessages?.NEW_PSW}</FormLabel>
                                                    <TextField
                                                        // placeholder="must be 6 characters"
                                                        //    <FormLabel required>New Password</FormLabel>
                                                        //    <TextField
                                                        id="password"
                                                        variant="outlined"
                                                        name="password"
                                                        type={showPassword.password ? "text" : "password"}
                                                        size="normal"
                                                        sx={{
                                                            width: "100%"
                                                        }}
                                                        onChange={(e) =>setFieldValue("password", e.target.value)}
                                                        onBlur={handleBlur}
                                                        InputProps={{
                                                            inputProps: { maxLength: 15 },
                                                            minLength:6,
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={() => {
                                                                            setShowPassword(prev => ({
                                                                                ...prev,
                                                                                password: !prev.password
                                                                            }))
                                                                        }}
                                                                        edge="end"
                                                                    >
                                                                        {showPassword.password ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),

                                                        }}
                                                    />
                                                    {errors.password && <small className="text-danger">{errors.password}</small>}
                                                </FormControl>
                                            </div>
                                            <div className="mt-4">
                                                <FormControl fullWidth>
                                                    <FormLabel className={`auth-label redAsterisk`}>{frontMessages?.CNF_NEW_PSW}</FormLabel>
                                                    <TextField
                                                        //    <FormLabel required>Confirm your new password</FormLabel>
                                                        //    <TextField
                                                        id="confirmPassword"
                                                        
                                                        variant="outlined"
                                                        name="confirmPassword"
                                                        type={showPassword.confirmPassword ? "text" : "password"}
                                                        size="normal"
                                                        sx={{
                                                            width: "100%"
                                                        }}
                                                        InputProps={{
                                                            inputProps: { maxLength: 15 },
                                                            minLength:6,
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={() => {
                                                                            setShowPassword(prev => ({
                                                                                ...prev,
                                                                                confirmPassword: !prev.confirmPassword
                                                                            }))
                                                                        }}
                                                                        edge="end"
                                                                    >
                                                                        {showPassword.confirmPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        onChange={(e) =>{
                                                            if(e.target.value.length<=15)
                                                                        {
                                                                            setFieldValue("confirmPassword", e.target.value)
                                                                        }
                                                        }}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.confirmPassword && <small className="text-danger">{errors.confirmPassword}</small>}
                                                </FormControl>
                                            </div>
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    className="mt-4"
                                                    size="large"
                                                    sx={{
                                                        background: btn_clinic_bg_color,
                                                        marginBottom: "16px",
                                                        width: "100%",
                                                        '&:hover': {
                                                            // color: btn_clinic_bg_color,
                                                            backgroundColor: btn_clinic_bg_color,
                                                        }
                                                    }}
                                                    type="submit"
                                                >
                                                    {formLabels?.booking?.confirm}
                                                    {/* Confirm */}
                                                </Button>
                                            </div>
                                        </Form>
                                    </>
                                )}
                            </Formik>
                        </div>
                    }
                    {/* loader */}
                    {
                        !error && loading &&
                        <TailSpin
                            height="35"
                            width="35"
                            color={btn_clinic_bg_color}
                            ariaLabel="tail-spin-loading"
                            radius="2"
                            wrapperStyle={{}}
                            wrapperClass="d-flex justify-content-center"
                            visible={true}
                        />
                    }
                    {/* alert message */}
                    {
                        isInvalidScreen && (
                            <>
                                <div className=" w-100 mt-2 m-auto">
                                    <Alert severity="error" >
                                        <AlertTitle className="">{formLabels?.booking?.token_expire_msg || "The Link You Followed Has Expired"}</AlertTitle>
                                    </Alert>
                                </div>
                                <div className="d-flex flex-column align-items-center justify-content-center text-center margin-top-4rem noto-font more-bold no-slot-color pb-5">
                                    <div>
                                        <img className="h-50 w-75" src={Images.no_slot} alt={error} />
                                    </div>
                                </div>
                                <div className="w-75 mb-4 m-auto cursor-pointer">
                                    <Button
                                        variant="outlined"
                                        className='w-100'
                                        onClick={handleClose}
                                        sx={{
                                            borderRadius: "4px",
                                            border: "1px solid #D8DAE5",
                                            background: "#FFF",

                                        }}

                                    >
                                        <p style={{
                                            color: " #696F8C",
                                            fontSize: "16px",
                                        }}
                                            className='m-auto mb-2 py-2'
                                        >{formLabels?.booking?.back_to_login}</p>

                                    </Button>
                                </div>
                            </>
                        )
                    }
                    {/* success message */}
                    {/* {
                        resetPasswordSuccess && !loading && (
                            <>
                                <div className="mt-2">
                                    <Alert severity="success">
                                        <AlertTitle>{formLabels?.booking?.success_msg}</AlertTitle>
                                       
                                        {resetPasswordSuccess}
                                    </Alert>
                                </div>
                            </>
                        )
                    } */}
                </div>

            </div>
        </>
    )
}


export default ForgotPassword;

