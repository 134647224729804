import axios from "axios";
import { LANGUAGES, baseUrl } from "../../Constants/Constant";

const auth = { username: "dev-server", password: "zrfuSD41er" };
const lang_code = localStorage.getItem("lang_type");

export const handleApiRequest = (url, method, data, params, header) => {
  const MANAGE_BOOKING_TOKEN = localStorage.getItem("MANAGE_BOOKING_TOKEN");
  const EXISTING_USER_TOKEN = localStorage.getItem("EXISTING_USER_TOKEN");

  return axios({
    url: url,
    method: method,
    data: data,
    baseURL: baseUrl,
    params: params,
    headers: {
      auth,
      Authorization: MANAGE_BOOKING_TOKEN ? `Bearer ${MANAGE_BOOKING_TOKEN}` : EXISTING_USER_TOKEN ? `Bearer ${EXISTING_USER_TOKEN}` : "",
      withCredentials: true,
      "Accept-Language": lang_code ? lang_code : LANGUAGES.JP,
    },
  });
};

const INSTANCE = axios.create({
  // url: url,
  // method: method,
  // data: data,
  baseURL: baseUrl,
  // params: params,
  // headers: {
  //   auth,
  //   Authorization: MANAGE_BOOKING_TOKEN ? `Bearer ${MANAGE_BOOKING_TOKEN}` : EXISTING_USER_TOKEN ? `Bearer ${EXISTING_USER_TOKEN}` : "",
  //   withCredentials: true,
  //   "Accept-Language": lang_code ? lang_code : "en",
  // },
})

INSTANCE.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  // if 400 or 500 then handle it.
  return response;
}, function (error) {
  if (error && error.response && error.response.status === 401) {
    localStorage.removeItem("auth_token");
    error.message = error.response.data.status || "Token expired"
    window.location.reload();
  }
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

export default INSTANCE;
