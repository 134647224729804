import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios  from "../../Services/Api/Index";
import { API_PATH } from '../../Constants/ApiPath';
import { API_TYPE } from "../../Constants/ApiType";
import { LANGUAGES } from "../../Constants/Constant";

export const getSpecies = createAsyncThunk(
  "pets/getSpecies",
  async(payload,{rejectWithValue})=>{
    try{
      const lang_code = localStorage.getItem("lang_type");
      const response = await Axios({
        method: API_TYPE.GET,
        url: API_PATH.GET_SPECIES,
        headers: {
          "Accept-Language": lang_code || LANGUAGES.JP,
        },
      });
      const data = await response.data;
      if (data.status === "success" && data.statuscode === 200 && data.data.species) {
        return {species: data.data.species}
      } else {
        throw "Something went wrong!";
      }
    }catch(err){
        console.log("err - ",err);
      return rejectWithValue("Opps there seems to be an error");
    }
  }
);

export const getBreeds = createAsyncThunk(
  "pets/getBreeds",
  async(payload,{rejectWithValue})=>{
    try{
      const lang_code = localStorage.getItem("lang_type");
      const response = await Axios({
        method: API_TYPE.GET,
        url: `${API_PATH.GET_BREEDS}?species=${payload}`,
        headers: {
          "Accept-Language": lang_code || LANGUAGES.JP,
        },
      });
      const data = await response.data;
      if (data.status === "success" && data.statuscode === 200 && data.data.breeds) {
        return {breeds: data.data.breeds}
      } else {
        throw "Something went wrong!";
      }
    }catch(err){
        console.log("err - ",err);
      return rejectWithValue("Opps there seems to be an error");
    }
  }
);

export const createPet = createAsyncThunk(
  "pets/createPet",
  async(payload,{rejectWithValue})=>{
    try{
      const TOKEN = localStorage.getItem("auth_token");
      const lang_code = localStorage.getItem("lang_type");
      const response = await Axios({
        method: API_TYPE.POST,
        url: API_PATH.CREATE_PET,
        data: payload,
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          withCredentials: true,
          "Accept-Language": lang_code || LANGUAGES.JP,
        }
      });
      const data = await response.data;
      if (data.status === "success" && data.statuscode === 200) {
        return {message: data.message};
      }
      throw data.message || "Something went wrong!";
    }catch(err){
      return rejectWithValue("Opps there seems to be an error");
    }
  }
);

export const getPets = createAsyncThunk(
  "pets/getPets",
  async(payload,{rejectWithValue})=>{
    try{
      const TOKEN = localStorage.getItem("auth_token");
      const lang_code = localStorage.getItem("lang_type");
      const response = await Axios({
        method: API_TYPE.GET,
        url: API_PATH.GET_PETS,
        data: payload,
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          withCredentials: true,
          "Accept-Language": lang_code || LANGUAGES.JP,
        }
      });
      const data = await response.data;
      if (data.status === "success" && data.statuscode === 200) {
        return {pets: data.data};
      }
      throw data.message || "Something went wrong!"
    }catch(err){
        console.log("err - ",err);
      return rejectWithValue("Opps there seems to be an error");
    }
  }
);