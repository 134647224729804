export const switchCases = {
  loginPage: 0,
  selectFacility: 1,
  selectDoctor: 2,
  enterInfo: 3,
  thankYou: 4,
  manageBookings:5,
};

export const switchFormikCases = {
  INPUT: "input",
  TEXTAREA: "textarea",
  SELECT: "select",
  RADIO: "radio",
  CHECKBOX: "checkbox",
  DATE: "date",
};

export const LANGUAGES = [
  { label: "日本語", code: "jp" },
  { label: "English", code: "en" },
];


export const PET_GENDER_OPTIONS = [
  // { key: "Select", value: "" },
  { key: "Male", value: "Male" },
  { key: "Female", value: "Female" },
  { key: "Unknown", value: "Unknown" },
];
export const PET_GENDER_OPTIONS_JP = [
  // { key: "Select", value: "" },
  { key: "オス", value: "Male" },
  { key: "メス", value: "Female" },
  { key: "不明", value: "Unknown" },
];

export const NEUTERED_OPTIONS = [
  // { key: "Select", value: "" },
  { key: "Yes", value: "Yes" },
  { key: "No", value: "No" },
  { key: "Unknown", value: "Unknown" },
];
export const NEUTERED_OPTIONS_JP = [
  // { key: "Select", value: "" },
  { key: "済", value: "Yes" },
  { key: "未済", value: "No" },
  { key: "不明", value: "Unknown" },
];

export const TOAST_TIMER = 2500;

export const TIMER = 30;
