import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios  from "../../Services/Api/Index";
import { API_PATH } from '../../Constants/ApiPath';
import { API_TYPE } from "../../Constants/ApiType";
import { setCalendarData } from "../calendar/slice";
import { LANGUAGES } from "../../Constants/Constant";

export const getDoctors = createAsyncThunk(
  "doctors/getDoctors",
  async(payload,{rejectWithValue, dispatch})=>{
    try{
      const lang_code = localStorage.getItem("lang_type");
      const response = await Axios({
        method: API_TYPE.POST,
        url: API_PATH.DOCTORS_DETAILS,
        data: payload,
        headers: {
          "Accept-Language": lang_code || LANGUAGES.JP,
        },
      });
      const data = await response.data;
      if (data.status === "success" && data.statuscode === 200) {
        if (payload.type === "provider") {
          return { ...data.data }
        } else if (payload.type === "clinic") {
          dispatch(setCalendarData({...data.data}))
          return {};
        }
      }
      throw data.message || "Something went wrong!";
    }catch(err){
        console.log("err - ",err);
      return rejectWithValue("Opps there seems to be an error");
    }
  }
);