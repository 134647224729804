import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { MegaApp } from "./MegaApp";
import { store } from "./Redux/Store";
import { handleApiRequest } from "./Services/Api/Index";
import "./i18n";
import "./index.css";
const root = ReactDOM.createRoot(document.getElementById("root"));

function getMainDomain(url) {
  // Create a new URL object
  const parsedUrl = new URL(url);

  // Split the hostname by dots
  const hostnameParts = parsedUrl.hostname.split('.');

  // Get the last two parts (domain and TLD)
  if (hostnameParts.length >= 2) {
      return hostnameParts.slice(-2).join('.');
  } else {
      // If the hostname is too short to have a domain and TLD, return null
      return null;
  }
}

const redirectonFail = () => {
  document.getElementsByTagName('body')[0].innerHTML = '<h1>Invalid Request</h1>'
}

// const  validateDomain = () => {

//   // Split the hostname by dots
//   const hostnameParts = window.location.hostname.split('.');

//   let mainDomain = ''
//   // Get the last two parts (domain and TLD)
//   if (hostnameParts.length >= 2) {
//       mainDomain = hostnameParts.slice(-2).join('.');
//   }
//   let env_domain = getMainDomain(process.env.REACT_APP_BASE_URL)

//   if(env_domain != mainDomain) {
//     // Redirect Code
//     redirectonFail()
//   }
  
// }

//validateDomain()

handleApiRequest('bookingportal/locale/en', 'GET' )
.then(res=>{
  root.render(
    <Provider store={store}>
      {/* <React.StrictMode> */}
        <MegaApp />
      {/* </React.StrictMode> */}
    </Provider>
  );
}).catch(err=>{
  redirectonFail()
})





// ReactDOM.render(<App/>, document.getElementById('root'));
