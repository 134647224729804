import React, { useState, useEffect } from "react";
import $ from "jquery";
import StepHeader from "../StepHeader/StepHeader";
import FacilityCard from "../FacilityCard/FacilityCard";
import ServiceCard from "../ServiceCard/ServiceCard";
import Button from "../Button/Button";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import "react-multi-carousel/lib/styles.css";
import {resetService, setService} from './../../Redux/service/slice'
// import { getFacilityApi } from "../../Services/FacilityApi";
// import { getServiceApi } from "../../Services/ServiceApi";
import {
  FacilityAction,
  FacilityActionData,
  getDoctors,
  ServiceAction,
  ServiceActionData,
  StartLoading,
  StopLoading,
} from "../../Redux/Actions/CommonAction";
import { Images } from "../../Constants/Images";
import NoDataAvailable from "../NoDataAvailable";
import { useTranslation } from "react-i18next";
import "./SelectFacility.css";
import { getFacilities } from "../../Redux/facility/thunk";
import { getServices } from "../../Redux/service/thunk";
import useCustomTheme from "../../hooks/useCustomTheme";
import { getPets } from "../../Redux/pet/thunk";
import SelectPet from "../SelectPet/SelectPet";
import useConfig from "../../hooks/useConfig";
import { resetFacility } from "../../Redux/facility/slice";

$(document).on("click", ".add_remove_style", function () {
  $(this).toggleClass("change-card-style");
});

const SelectFacility = (props) => {
  const { activeClass } = useCustomTheme();
  const { config } = useConfig();
  const { steps } = config;
  const { handleStepAction,navTab } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const frontLabels = t("data.frontLabels", { returnObjects: true });
  const facilityReducer = useSelector((state) => state.facility);
  const serviceReducer = useSelector((state) => state.service);
  const petReducer = useSelector((state) => state.pet);
  const { pet, addPetSuccess } = petReducer;
  const { services, service } = serviceReducer;
  const { facilities, facility } = facilityReducer;
  const serviceCardData = useSelector(
    (state) => state?.hospistalReducer?.serviceCardData
  );

  const [state, setState] = useState({
    isActiveId: facility?.id,
    isServiceId: service?.id,
    showMoreFacility: false,
    showMoreService: false,
  });


  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.removeItem("access_token_value");
    // if (!facilities) getPreferredFacilityApiCalls();
    if (!facilities) {
      dispatch(getFacilities());
    }
    // get pets
    if (!pet) {
      dispatch(getPets());
    }
  }, []);
  
  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo(0, 1);
    }, 0);
  };

  useEffect(()=>{
    switch (navTab) {
      case 2:
        document.getElementById('preferred-pet').scrollIntoView({ behavior: 'smooth' });
        break;
      case 3:
        document.getElementById('service').scrollIntoView({ behavior: 'smooth' });
        break;
      default:
        window.scrollTo(0, 0);
    }
  },[navTab,props.timeTabSelected])
  
  useEffect(() => {
    if (!pet || addPetSuccess) {
      dispatch(getPets());
    }
  }, [addPetSuccess]);

  useEffect(() => {
    if (facility && facility.id) {
        const payload = {
        clinicId: facility.id,
        type:"in_person",
        // type: frontLabels?.booking?.in_person,
      };
      dispatch(resetService())
      dispatch(getServices(payload));
    }
  }, [facility]);

  useEffect(() => {
    if (
      service &&
      facility &&
      facility.id &&
      service.service_for === "provider"
    ) {
      const payload = {
        service: service,
        clinicId: facility?.id,
        type: service?.service_for,
      };
      dispatch(getDoctors(payload));
    }
    
  }, [service]);


  const returnFacilityCardView = (item, index) => {
    return (
      <FacilityCard
        item={item}
        key={`index ${index}`}
        selectedFacility={facility}
        // handleFacilityCardBody={handleFacilityCardBody}
      />
    );
  };
  const handleFacilityItems = () => {
    setState((prevState) => ({
      ...prevState,
      showMoreFacility: !prevState.showMoreFacility,
    }));
  };
  const returnServiceCardView = (item, index) => {
    return (
      <ServiceCard
        item={item}
        key={`index ${index}`}
        selectedService={service}
        facility={facility}
      />
    );
  };
  
  const handleServiceItems = () => {
    setState((prevState) => ({
      ...prevState,
      showMoreService: !prevState.showMoreService,
    }));
  };
  const toggleElementDisplay = (elementId, displayValue) => {
    let element = document.getElementById(elementId);
    if (element) {
      element.style.display = displayValue;
    }
  };
  
  const switchMobileView = (tab) => {
    scrollToTop();
    toggleElementDisplay("preferred-service", "none");
    toggleElementDisplay("preferred-facility", "none");
    toggleElementDisplay("preferred-pet", "none");
    switch (tab) {
      case "facility":
        toggleElementDisplay("preferred-facility", "block");
        break;
      case "pet":
        toggleElementDisplay("preferred-pet", "block");
        break;
      case "service":
        toggleElementDisplay("preferred-service", "block");
        handleStepAction(1);
        break;
      default:
        toggleElementDisplay("preferred-facility", "block");
    }
  };
  const handleStepFourNextPage = ()=>{
    
  }
  
  return (
    <>
      <div className="first-step pointer position-relative">
        <section
          className="container first-section padding-130 padding-top-2-188rem padding-zero"
          id="preferred-facility"
        >
          <div className="padding-top-first-section">
            <StepHeader
              title={`01. ${frontLabels?.booking?.select_facility}`}
              step={`${frontLabels?.booking?.STEP} 01 ${frontLabels?.booking?.OF} 0${steps}`}
            />
          </div>
          <div className="facility-card preffered-service-card only-desktop margin-top-4-25rem">
            <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-1 m-0">
              {facilities &&
                facilities?.map((item, index) => {
                  if (!state.showMoreFacility && index < 3) {
                    return returnFacilityCardView(item, index);
                  } else if (state.showMoreFacility) {
                    return returnFacilityCardView(item, index);
                  }
                  // ensure that if none of the conditions in the if statement are met,
                  // the map function will still return a value, even if it's null.
                  return null;
                })}
            </div>
            {facilities && facilities?.length > 3 && (
              <div className="text-end pb-4 margin-top-negative-1-5rem pointer-icon">
                <button
                  className="show-info-details"
                  onClick={handleFacilityItems}
                >
                  {!state.showMoreFacility ? (
                    <span className="noto-font more-bold">
                      {frontLabels?.booking?.show_more_facility}{" "}
                      <img
                        src={Images.show_more}
                        alt={frontLabels?.booking?.show_more_facility}
                      />
                    </span>
                  ) : (
                    <span className="noto-font more-bold">
                      <img
                        src={Images.show_less}
                        alt={frontLabels?.booking?.show_less_facility}
                      />{" "}
                      {frontLabels?.booking?.show_less_facility}
                    </span>
                  )}
                </button>
              </div>
            )}
          </div>

            <div className="container facility-card only-mobile mt-3 overflow-auto">
              {facilities?.map((item, index) =>
                returnFacilityCardView(item, index)
              )}
            </div>

            <div className="show-next-page padding-zero">
              {facility ? (
                <Button
                  className="next-button switch-to-facility margin-right-0-880rem"
                  text={frontLabels?.booking?.ancr_next}
                  style={activeClass}
                  onClick={()=>switchMobileView("pet")}
                />
              ) : (
                <Button
                  className="next-button switch-to-facility margin-right-0-880rem disabled"
                  style={activeClass}
                  text={frontLabels?.booking?.ancr_next}
                />
              )}
            </div>
        </section>

        {/* SELECT PET */}
        <section className="container first-section padding-130 padding-zero py-4"
          id="preferred-pet"
          
        >
          <div className="padding-top-first-section">
            <StepHeader
              title={`02. ${frontLabels?.booking?.select_preferred_pet || ""} `}
              step={`${frontLabels?.booking?.STEP} 02 ${frontLabels?.booking?.OF} 0${steps}`}
              // subTitle={frontLabels?.booking?.paragraph_3}
              
            />
          </div>
          <div >
            <div className="p-4 select-pet-group overflow-auto">
              <SelectPet />
            </div>
            <div className="show-next-page padding-zero">
              <Button
                className="next-button previous-button switch-to-facility"
                // style={activeClass}
                isLeft
                text={frontLabels?.booking?.previous}
                onClick={() => switchMobileView("facility")}
              />
              {pet ? (
                <Button
                  className="next-button switch-to-facility"
                  style={activeClass}
                  text={frontLabels?.booking?.ancr_next}
                  onClick={() => switchMobileView("service")}
                />
              ) : (
                <Button
                  className="next-button switch-to-facility disabled"
                  style={activeClass}
                  text={frontLabels?.booking?.ancr_next}
                />
              )}
            </div>
          </div>
        </section>

        <section
          className="container second-section padding-130 padding-zero pt-4"
          id="preferred-service"
        >
          <div className="padding-top-first-section">
            <StepHeader
              title={`03. ${frontLabels?.booking?.select_service}`}
              step={`${frontLabels?.booking?.STEP} 03 ${frontLabels?.booking?.OF} 0${steps}`}
              
            />
          </div>
          <div id="service">
            <div className=" facility-card preffered-service-card only-desktop margin-top-2-125rem">
              {services && services?.length > 0 ? (
                <div className="row row-cols-lg-4 row-cols-md-2 row-cols-sm-1 m-0">
                  {services?.map((item, index) => {
                    if (!state.showMoreService && index < 4) {
                      return returnServiceCardView(item, index);
                    } else if (state.showMoreService) {
                      return returnServiceCardView(item, index);
                    }
                    return null;
                  })}
                </div>
              ) : (
                <NoDataAvailable
                  images={Images.no_slot}
                  headMessage={frontLabels?.booking?.no_service_available}
                  subHeadMessage={
                    frontLabels?.booking
                      ?.please_select_different_facility_having_services
                  }
                  message="no service"
                />
              )}
              {services?.length > 4 && (
                <div className="text-end pb-4 pointer-icon">
                  <button
                    className="show-info-details"
                    onClick={handleServiceItems}
                  >
                    {!state.showMoreService ? (
                      <span className="noto-font more-bold">
                        {frontLabels?.booking?.show_more_service}{" "}
                        <img
                          src={Images.show_more}
                          alt={frontLabels?.booking?.show_more_service}
                        />
                      </span>
                    ) : (
                      <span className="noto-font more-bold">
                        <img
                          src={Images.show_less}
                          alt={frontLabels?.booking?.show_less_service}
                        />{" "}
                        {frontLabels?.booking?.show_less_service}
                      </span>
                    )}
                  </button>
                </div>
              )}
            </div>

            {services && services?.length > 0 ? (
              <div className="container facility-card mt-3 preffered-service-card only-mobile">
                {services?.map((item, index) =>
                  returnServiceCardView(item, index)
                )}
              </div>
            ) : (
              <span className="no-display show-for-mobile">
                <NoDataAvailable
                  images={Images.no_slot}
                  headMessage={frontLabels?.booking?.no_service_available}
                  subHeadMessage={
                    frontLabels?.booking
                      ?.please_select_different_facility_having_services
                  }
                  message={frontLabels?.service?.no_service_found}
                />
              </span>
            )}

            <div className="show-next-page container padding-zero">
              <Button
                className="next-button previous-button switch-to-facility"
                isLeft
                text={frontLabels?.booking?.previous}
                onClick={() => switchMobileView("pet]")}
              />
              {service ? (
                <Button
                  className="next-button switch-to-facility"
                  style={activeClass}
                  text={frontLabels?.booking?.ancr_next}
                  onClick={() => {
                      handleStepAction(2)
                  }}
                />
              ) : (
                <Button
                  className="next-button switch-to-facility disabled"
                  style={activeClass}
                  text={frontLabels?.booking?.ancr_next}
                  
                />
              )}
            </div>
          </div>
        </section>

        <div className="padding-btn-bottom hide-for-mobile">
          <div className="switch-next-page d-flex justify-content-end">
            {facility && service && pet ? (
              <Button
                className="btn btn-lg go-next-page font-text"
                style={activeClass}
                text={frontLabels?.booking?.ancr_next}
                onClick={() => handleStepAction(2)}
              />
            ) : (
              <Button
                className="btn btn-lg go-next-page font-text disabled"
                style={activeClass}
                text={frontLabels?.booking?.ancr_next}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default React.memo(SelectFacility);
