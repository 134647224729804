import React, { Suspense, useEffect } from "react";
import "./App.css";
import "react-multi-carousel/lib/styles.css";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import CustomRouting from "./utils/CustomRouting";
import Loader from "./components/Loader";
import { ToastContainer } from "react-toastify";
import ReactGA from "react-ga4";
import Clarity from "./Clarity";




const App = () => {
  const clarity_id = process.env.REACT_APP_CLARITY_ID;
  const common_ga_id = process.env.REACT_APP_COMMON_GA_ID; 
  const ga_id = process.env.REACT_APP_GA_ID; 
  const common_clarity_id = process.env.REACT_APP_COMMON_CLARITY_ID; 
        
    useEffect(() => {
      // console.log("first",clarity_id)
      // console.log("second",common_clarity_id);

      ReactGA.initialize([
        {
          trackingId: common_ga_id,
          gaOptions: { name: "trackerName1", send_page_view: true },
        },
        {
          trackingId: ga_id,
          gaOptions: { name: "trackerName2", send_page_view: true },
        },
      ]);


      ReactGA.event({
        name: "trackerName1",
        category: "My Category",
        action: "My Action",
        label: process.env.REACT_APP_VERSION 
      });

      ReactGA.event({
        name: "trackerName2",
        category: "My Category",
        action: "My Action",
        label: process.env.REACT_APP_VERSION 
      });
    }, []);


   

  return (
    <>
      <Suspense fallback={<Loader />}>
        <ErrorBoundary>
          <CustomRouting />
          <Clarity projectId={clarity_id} commonProjectId={common_clarity_id} />
          <ToastContainer limit={4} />
        </ErrorBoundary>
      </Suspense>
    </>
  );
};

export default React.memo(App);
