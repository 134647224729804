import React from "react";
import errorPage from "../../images/ErrorPage.png";
import "./PageNotFound.css";

const PageNotFound = () => {
  return (
    <>
      <div className="image-position">
        <img src={errorPage} />
      </div>
    </>
  );
};

export default PageNotFound;
