import React, { useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setService } from "../../Redux/service/slice";
import useCustomTheme from "../../hooks/useCustomTheme";
import { setStep } from "../../Redux/config/slice";
import { resetDoctor } from "../../Redux/doctor/slice";
import { resetCalendar } from "../../Redux/calendar/slice";
import useCurrentLanguage from "../../hooks/useCurrentLanguage";
const ServiceForJP={
  clinic:"獣医師指名なし",
  provider:"獣医師指名あり",
}

const ServiceCard = (props) => {
  const {
    background_color,
    btn_clinic_bg_color,
    btn_clinic_text_color,
    btn_telehealth_bg_color,
    btn_telehealth_text_color,
    lp_bg_color,
    activeClass
  } = useCustomTheme();
  const { t } = useTranslation();
  const text = props?.item?.description;
  const frontLabels = t("data.frontLabels", { returnObjects: true });
  let maxLength = 70;
  if (1300 > window.innerWidth) {
    maxLength = 50;
  }
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const displayText = isExpanded ? text : text.slice(0, maxLength);
  const handleExpandable = () => setIsExpanded(!isExpanded);
  const handleServiceCardClick = (item) => {
    if (item.service_for === "provider") {
      dispatch(setStep(6))
    } else if (item.service_for === "clinic") {
      dispatch(setStep(5))
    }
    if (props?.selectedService?.id === item.id) return;
    const payload = {
      serviceId: item.id,
      clinicId: props.facility?.id,
    };
    dispatch(setService(item));
    dispatch(resetDoctor());
    dispatch(resetCalendar());
  }
  const lang = useCurrentLanguage();
  return (
    <>
      <div className="col-lg-3 col-md-6 col-12 mb-4">
        <div className=" card add_remove_style position-relative service-card-height card-1 service-card-min-height pointer-icon"
          onClick={() => handleServiceCardClick(props?.item)}
        >
          <div className="pointer-icon">
            {!(props?.selectedService?.id === props?.item?.id) ? (
              <p className="checked-mark">
                <DoneIcon
                  style={{ color: "rgba(9, 162, 62, 0.2)" }}
                />
              </p>
            ) : (
              <p className="tick-mark">
                <DoneIcon
                  style={{ color: btn_clinic_bg_color }}
                />
              </p>
            )}
          </div>
          <div className={`card-body  ${props?.selectedService?.id === props?.item?.id && "active-service-card"}`}
            style={props?.selectedService?.id === props?.item?.id ? activeClass : {}}
          >
            {/* <div className="service-card-header truncated-text-2-lines noto-font light-weight" style={{ marginLeft: '27px' }}>{lang==="jp"?ServiceForJP[props?.item?.service_for]:props?.item?.service_for}</div> */}
            <div className="service-card-header truncated-text-2-lines noto-font light-weight" style={{ marginLeft: '27px' }}></div>
            <div className="d-flex flex-md-column mt-3">
              <div className="service-card-subheader font-gottam-bold more-bold" 
                title={props?.item?.name}
              style={{
                "whiteSpace": "nowrap",
                "overflow": "hidden",
                "textOverflow": "ellipsis"
              }}>{props?.item?.name}</div>
              {/* <div className="font-gottam light-weight speciality flex-center">(Senior Veterinarian)</div> */}
            </div>
            <div className="service-card-para mt-2" style={{ Height: '66px' , overflowY:"auto" }}>
              {displayText} {text.length > maxLength && (
                <span className="message-list-color pointer-icon noto-font" onClick={handleExpandable}><span style={{ color: "#4D6E90" }}>...&nbsp;</span>{isExpanded ? "Show Less" : "Show More"}</span>)}
            </div>
            <div className="service-card-footer d-flex align-items-center mt-3" >
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.23973 1.83008C5.28385 1.83008 2.08045 5.04064 2.08045 8.99652C2.08045 12.9524 5.28385 16.163 9.23973 16.163C13.2028 16.163 16.4133 12.9524 16.4133 8.99652C16.4133 5.04064 13.2028 1.83008 9.23973 1.83008ZM9.2469 14.7297C6.07933 14.7297 3.51374 12.1641 3.51374 8.99652C3.51374 5.82895 6.07933 3.26337 9.2469 3.26337C12.4145 3.26337 14.9801 5.82895 14.9801 8.99652C14.9801 12.1641 12.4145 14.7297 9.2469 14.7297ZM9.60522 5.4133H8.53025V9.71317L12.2926 11.9706L12.8301 11.0891L9.60522 9.17568V5.4133Z" fill="currentColor" />
              </svg>

              <span className="font-size-0-815rem text-class noto-font light-weight margin-left-6px" >
                {props?.item?.duration} <span className="text-transform-char">{frontLabels?.service?.minutes}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ServiceCard);
