import { useDispatch, useSelector } from "react-redux";
import { Images } from "../../Constants/Images";
import NoDataAvailable from "../NoDataAvailable";
import { useTranslation } from "react-i18next";
import "./Calendar.css";
import { setSlot } from "../../Redux/calendar/slice";
import useCustomTheme from "../../hooks/useCustomTheme";
import useCurrentLanguage from "../../hooks/useCurrentLanguage";
import { LANGUAGES } from "../../Constants/Constant";

const MobileVersionTimeSlots = () => {
  const { t } = useTranslation();
  const frontLabels = t("data.frontLabels", { returnObjects: true });
  const dispatch = useDispatch();
  const calendarReducer = useSelector((state) => state.calendar);
  const { timeSlots, selectedSlotIndex, type } = calendarReducer;
  const { activeClass } = useCustomTheme();
  const serviceReducer = useSelector(state => state.service);
  const { service } = serviceReducer;
  const morningSlot = timeSlots !== null ? timeSlots?.filter((time) => time < "12:00") : [];
  const afternoonSlot = timeSlots !== null ? timeSlots?.filter((time) => time >= "12:00" && time < "17:00") : [];
  const afternoonSlotJP = timeSlots !== null ? timeSlots?.filter((time) => time >= "12:00" && time < "23:59") : [];
  const eveningSlot = timeSlots !== null ? timeSlots?.filter((time) => time >= "17:00" && time <= "23:59") : [];

  const handleSlot = (session, item, selected) => {
    const payload = {
      selectedSlotIndex: {
        morning: -1,
        noon: -1,
        evening: -1
      },
      selectedTime: item,
    };
    payload["selectedSlotIndex"][session.toLowerCase()] = selected;
    dispatch(setSlot(payload));
  };
  const lang = useCurrentLanguage();
  const renderTimeSlots = (session, slots) => {
    return (
      slots.length > 0 &&
      slots?.map((item, index) => (
        <div className="col-3 p-0" key={index} style={{ border: '0', background: 'transparent', }}>
          <div className="">
            <button
              className={`time-slots noto-font  text-nowrap ${selectedSlotIndex[`${session.toLowerCase()}`] === index &&
                "selected-slot"
                }`}
              style={{ ...{ ...(selectedSlotIndex[`${session?.toLowerCase()}`] === index) && activeClass } }}
              onClick={() => handleSlot(session, item, index)}
            >
              {item}
            </button>
          </div>
        </div>
      ))
    );
  };
  return (
    <>
      <div className="mt-5">
        {/* <span className="slots-section-mobile noto-font">{`${service?.service_for === "provider" ? "06." : "05."} ${frontLabels?.booking?.select_available}`}</span> */}
        {timeSlots?.length > 0 ? (
          <>
            {morningSlot.length > 0 && (
              <div className="times mt-3 display-time-section">
                <div className="left-part d-flex align-items-center pb-1">
                  <img src={Images.morning_icon} alt="morning slot" style={{ width: '18px', height: '18px' }} />
                  <div className="noto-font session-text-color ms-2 text-nowrap">{frontLabels?.booking?.morning}</div>
                </div>
                <div className="row row-cols-4 pt-3" style={{ marginLeft: "-4px" }}>
                  {renderTimeSlots(
                    frontLabels?.booking?.morning,
                    morningSlot?.map((slot) => slot)
                  )}
                </div>
              </div>
            )}

            {morningSlot.length > 0 && <div className="line-shade"></div>}
            {lang === LANGUAGES.JP ? <div>
              {afternoonSlotJP.length > 0 && (
                <div className="times mt-3 display-time-section">
                  <div className="left-part d-flex align-items-center pb-1">
                    <img src={Images.noon_icon} alt="noon slot" style={{ width: '18px', height: '18px' }} />
                    <div className="noto-font session-text-color ms-2 text-nowrap">{frontLabels?.booking?.noon}</div>
                  </div>
                  <div className="row row-cols-4 pt-3" style={{ marginLeft: "-4px" }}>
                    {renderTimeSlots(
                      frontLabels?.booking?.noon,
                      afternoonSlotJP?.map((slot) => slot)
                    )}
                  </div>
                </div>
              )}
            </div> : <div>
              {afternoonSlot.length > 0 && (
                <div className="times mt-3 display-time-section">
                  <div className="left-part d-flex align-items-center pb-1">
                    <img src={Images.noon_icon} alt="noon slot" style={{ width: '18px', height: '18px' }} />
                    <div className="noto-font session-text-color ms-2 text-nowrap">{frontLabels?.booking?.noon}</div>
                  </div>
                  <div className="row row-cols-4 pt-3" style={{ marginLeft: "-4px" }}>
                    {renderTimeSlots(
                      frontLabels?.booking?.noon,
                      afternoonSlot?.map((slot) => slot)
                    )}
                  </div>
                </div>
              )}

              {afternoonSlot.length > 0 && <div className="line-shade"></div>}

              {eveningSlot.length > 0 && (
                <div className="times mt-3 display-time-section">
                  <div className="left-part d-flex align-items-center pb-1">
                    <img src={Images.evening_icon} alt="evening slot" style={{ width: '18px', height: '18px' }} />
                    <div className="noto-font session-text-color ms-2 text-nowrap">{frontLabels?.booking?.evening}</div>
                  </div>
                  <div className="row row-cols-4 pt-3" style={{ marginLeft: "-4px" }}>
                    {renderTimeSlots(
                      frontLabels?.booking?.evening,
                      eveningSlot.map((slot) => slot)
                    )}
                  </div>
                </div>
              )}
            </div>
            }

          </>
        ) : (<NoDataAvailable images={Images.no_slot} headMessage={frontLabels?.booking?.no_slots} subHeadMessage={frontLabels?.booking?.please_change_the_date} message={frontLabels?.booking?.no_slots} />)}
      </div>
    </>
  );
}

export default MobileVersionTimeSlots