import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import "../SelectFacility/SelectFacility.css";
import { resetDoctor } from "../../Redux/doctor/slice";
import { resetCalendar } from "../../Redux/calendar/slice";
import moment from "moment";
import { getSlots } from "../../Redux/calendar/thunk";
import { getDoctors } from "../../Redux/Actions/CommonAction";

const FormStepHeader = (props) => {
  const { handleStepAction,setNavTab } = props;
  const { t } = useTranslation();
  const frontLabels = t("data.frontLabels", { returnObjects: true });
  const frontMessages = t("data.frontMessages", { returnObjects: true });
  const facilityReducer = useSelector((state) => state.facility);
  const { facility } = facilityReducer;
  const serviceReducer = useSelector((state) => state.service);
  const { service } = serviceReducer;
  const petReducer = useSelector((state) => state.pet);
  const { pet } = petReducer;
  const calendarReducer = useSelector(state => state.calendar);
  const { selectedTime, week, selectedDateIndex } = calendarReducer;
  const doctorReducer = useSelector(state => state.doctor);
  const {doctor, doctors} = doctorReducer;

  const dispatch = useDispatch();
  const combineData = {
    "Select Facility": facility?.clinic_name,
    "Select Service": service?.name,
    "Select Doctor": doctor && doctor?.kana_lastname,
    "Select Slot": selectedTime,
    "Select Pet": pet && pet?.name
  }

  // const firstDataOrder = [
  //   {
  //     id: 1,
  //     keyData: "Select Facility",
  //     head: frontLabels?.header?.select_facility,
  //     head2: frontMessages?.FACILITY || "Facility",
  //   },
  //   {
  //     id: 2,
  //     keyData: "Select Pet",
  //     head: frontLabels?.booking?.select_pet,
  //     head2: frontLabels?.booking?.pet || "Pet",

  //   },
  //   {
  //     id: 3,
  //     keyData: "Select Service",
  //     head: frontLabels?.header?.select_service,
  //     head2: frontMessages?.SERVICE || "Service",
  //   },
  //   {
  //     id: 4,
  //     keyData: "Select Doctor",
  //     head: frontLabels?.header?.select_doctor,
  //     head2: frontLabels?.thankyou?.doctor || "Doctor",
  //   },
  //   {
  //     id: 5,
  //     keyData: "Select Slot",
  //     head: frontLabels?.header?.select_slot,
  //     head2:  frontMessages?.SLOT || "Slot",
  //   },
  //   // {
  //   //   id: 5,
  //   //   keyData: "OTP Verification",
  //   //   head: frontLabels?.header?.otp_verification,
  //   // },
  // ]

  const secondDataOrder = [
    {
      id: 1,
      keyData: "OTP Verification",
      head: frontLabels?.header?.otp_verification,
    },
    {
      id: 2,
      keyData: "Select Facility",
      head: frontLabels?.header?.select_facility,
      head2: frontMessages?.FACILITY || "Facility",
    },
    {
      id: 3,
      keyData: "Select Service",
      head: frontLabels?.header?.select_service,
      head2: frontMessages?.SERVICE || "Service",
    },
    {
      id: 4,
      keyData: "Select Doctor",
      head: frontLabels?.header?.select_doctor,
      head2: frontLabels?.thankyou?.doctor || "Doctor",
    },
    {
      id: 5,
      keyData: "Select Slot",
      head: frontLabels?.header?.select_slot,
      head2:  frontMessages?.SLOT || "Slot",
    },
  ]

  const firstDataOrder = [
    {
      id: 1,
      keyData: "Select Facility",
      head: frontLabels?.header?.select_facility,
      head2: frontMessages?.FACILITY || "Facility",
    },
    {
      id: 2,
      keyData: "Select Pet",
      head: frontLabels?.booking?.select_pet,
      head2: frontLabels?.booking?.pet || "Pet",

    },
    {
      id: 3,
      keyData: "Select Service",
      head: frontLabels?.header?.select_service,
      head2: frontMessages?.SERVICE || "Service",
    },
    {
      id: 4,
      keyData: "Select Doctor",
      head: frontLabels?.header?.select_doctor,
      head2: frontLabels?.thankyou?.doctor || "Doctor",
    },
    {
      id: 5,
      keyData: "Select Slot",
      head: frontLabels?.header?.select_slot,
      head2:  frontMessages?.SLOT || "Slot",
    },
    // {
    //   id: 5,
    //   keyData: "OTP Verification",
    //   head: frontLabels?.header?.otp_verification,
    // },
  ]
  const filterCondition = (item) => {
    if (service?.service_for === "clinic" && item.keyData === "Select Doctor") {

    } else {
      return item;
    }
    // return item?.keyData !== "Select Doctor" && doctorsCardData?.type !== "clinic";
  };

  let filteredFirstOrder = (firstDataOrder).filter(filterCondition); // new user
  let filteredSecondOrder = (secondDataOrder).filter(filterCondition); // existing user

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getSelectedValue = (item, facility, service, doctor) => {
    const { head } = item;
    switch (head) {
      case frontLabels?.header?.select_facility:
        return facility?.clinic_name || "";
      case frontLabels?.header?.select_service:
        return service?.name || "";
      case frontLabels?.header?.select_doctor:
        return doctor?.lastname ? `${doctor?.lastname}` : "";
      case frontLabels?.header?.select_slot:
        const time = selectedTime;
        const date = week && week.length > 0 && selectedDateIndex > -1 ? week[selectedDateIndex]["date"] : "";
        return date && time ? `${date} | ${time}` : "";
      case frontLabels?.booking?.select_pet:
        return pet?.name || "";
      default:
        return "";
    }
  };

  const navItemClick = (item) => {
    setNavTab(item.id)
    if (item.id <= 3) {
      handleStepAction(1)
      dispatch(resetDoctor())
      dispatch(resetCalendar())
    } else  if (item.id >= 4) {
      handleStepAction(2) 
      dispatch(resetCalendar())
      if (doctors && doctors?.type === "provider" && doctor && doctor?.user_id) {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate());
        const payload = {
          clinicId: facility.id,
          providerId: doctor?.user_id === "Any" ? doctor?.user_id.toLowerCase() : doctor.user_id,
          scheduleType: "in_person",
          serviceId: service.id,
          selected_date_index: 0, //calendar?.selected_date_index || 0,
          select_day: "", //calendar?.select_day || "",
          select_date: moment(currentDate).format("YYYY-MM-DD") //calendar?.select_date || moment(currentDate).format("YYYY-MM-DD")
        }
        dispatch(getSlots(payload))
      } else if (doctors && doctors?.type === "clinic") {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate());
        const payload = {
          clinicId: facility.id,
          providerId: "",
          scheduleType: "in_person",
          serviceId: service.id,
          selected_date_index: 0, //calendar?.selected_date_index || 0,
          select_day: "", //calendar?.select_day || "",
          select_date: moment(currentDate).format("YYYY-MM-DD") // calendar?.select_date || moment(currentDate).format("YYYY-MM-DD")
        }
        // dispatch(getSlots(payload))
        dispatch(getDoctors(payload));

      }
      
    }
  }
  return (
    <div className="top-header top-container">
      <div className="breadcrumb-header padding-130 py-4 no-display-for-tablet">
        <div className="container d-flex justify-content-center margin-left-0-875-rem">
          <div className="row d-flex justify-content-start align-items-center gap-1">
            {(false ? filteredSecondOrder : filteredFirstOrder)?.map((item, index) => (
              <div className={`col `} key={item.id.toString()} style={{ marginLeft: "-10px" }}  >
                <div>
                  <div className="d-flex justify-content-around align-items-center  min-height-2-5rem " >

                    <div>
                      <div className={combineData[item.keyData] ? "head-title font-gottam-bold more-bold  d-none" : "head-not-selected mt-2 text-elipsis"}>{item?.head}</div>
                      <div className={combineData[item.keyData] ? "head-title font-gottam-bold more-bold  text-elipsis  " : "head-not-selected mt-2  d-none"}>{item?.head2}</div>
                      <div className="span1-select-service-general-consultation title-sub-head noto-font   cursor-pointer mt-1" onClick={() => navItemClick(item)}>{getSelectedValue(item, facility, service, doctor)}</div>
                    </div>

                    {item.id !== 6 && (
                      <div className="d-flex justify-content-center align-item-center">
                        <svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.11768 2.23535L13.8824 14.0001L2.11768 25.7648" stroke="#0C073A" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(FormStepHeader);
