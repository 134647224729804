import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import AuthLayout from "../layout/AuthLayout";

const PublicRoute = () => {
    const navigate = useNavigate();
    const {isLoggedIn, setUserLoggedIn, loginStatus} = useAuth();
    const location = useLocation();
    const checkUserToken = () => {
        const userToken = localStorage.getItem('auth_token');
        if (userToken) {
            if (!isLoggedIn && userToken) setUserLoggedIn(true)
            if (loginStatus === "register") {
                navigate("/add-pet");
            } else {
                const lstLocation = localStorage.getItem("lastLocation")
                return (lstLocation!==""?navigate(lstLocation):navigate("/manage"));
            }
        }
        // return navigate('/auth/login');
    }
    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);
    return (
        <React.Fragment>
            <AuthLayout>
                {
                    !isLoggedIn ? <Outlet /> : null
                }
            </AuthLayout>
        </React.Fragment>
    );
}
export default PublicRoute;