// REGISTER / CREATE USER
import { useEffect, useState } from "react";
import { Formik, useFormik } from "formik";
import { TextField, Button, FormControl, FormLabel, Alert, AlertTitle } from "@mui/material";
import useCustomTheme from "../../hooks/useCustomTheme";
import { RegisterFormValidation } from "../../Constants/Schema";
import useQuery from "../../hooks/useQuery";
import { useDispatch, useSelector } from "react-redux";
import { register, verifyRegistrationToken } from "../../Redux/auth/thunk";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { TOAST_TIMER } from "../../Constants/Others";
import { useTranslation } from "react-i18next";
import { Images } from "../../Constants/Images";
import { resetLogin, resetLoginError } from "../../Redux/auth/slice";
import Loader from "../../components/Loader";

function Register() {
    let globaltimeout = null;
    const {
        background_color,
        btn_clinic_bg_color,
        btn_clinic_text_color,
        btn_telehealth_bg_color,
        btn_telehealth_text_color,
        lp_bg_color
    } = useCustomTheme();
    const dispatch = useDispatch();
  const [recentError, setRecentError] = useState(null);
    const navigate = useNavigate();
    const {data} = useQuery(window.location.search);
    const auth = useSelector(state => state.auth);
    const {loading, error, login, token, message, isLoggedIn, isInvalidScreen} = auth;
    const { t } = useTranslation();
    const frontMessages = t("data.frontMessages", { returnObjects: true });
  const frontLabels = t("data.frontLabels", { returnObjects: true });

    const onHandleSubmit = (data) => {
        // const payload = {
        //     first_name: data.first_name,
        //     last_name: data.last_name,
        //     password: data.password,
        //     password_confirmation: data.password_confirmation
        // }
        data["email"] = login.email;
        data["contact_no"] = login.phone;
        data["country_code"] = login.countryCode;
        const payload={...login,...data}
        dispatch(register(payload));
    }
    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            password: "",
            password_confirmation: "",
        },
        enableReinitialize: true,
        validationSchema: RegisterFormValidation(),
        onSubmit: onHandleSubmit
    })
    const fieldClass = {
        // font: "inherit",
        // letterSpacing: "inherit",
        // color: currentColor,
        padding: "4px 0 5px",
        border: "0",
        boxSizing: "content-box",
        background: "none",
        height: "1.4375em",
        margin: 0,
        // -webkit-tap-highlight-color: transparent,
        display: "block",
        minWidth: 0,
        width: "100%",
        // -webkit-animation-name: mui-auto-fill-cancel,
        // animation-name: mui-auto-fill-cancel,
        // -webkit-animation-duration: 10ms,
        animationDuration: "10ms",
        padding: "16.5px 14px",
    }
    
    useEffect(() => {
        if (data["t"]) {
            dispatch(verifyRegistrationToken({token: data["t"]}));
        }
    }, []);

    useEffect(() => {
        if (!!token || isLoggedIn) {
            navigate("/add-pet");
        } else {
            localStorage.removeItem('auth_token');
        }
    }, [token, isLoggedIn]);

    useEffect(() => {
        if (error && message && !isInvalidScreen) {
            toast.error(message, {autoClose: TOAST_TIMER});
            setRecentError(message)
      if(globaltimeout) clearTimeout(globaltimeout)
                // eslint-disable-next-line react-hooks/exhaustive-deps
      globaltimeout = setTimeout(()=>{
          setRecentError(null)
      },2700)
        }
    }, [error, message]);

    const handleClose = () => {
        dispatch(resetLoginError(false));
        dispatch(resetLogin());
        navigate("/auth/login")

    };

    if (loading) {
        return (
            <Loader />
        )
    }

    if (isInvalidScreen) {
        return (
            <div style={{ padding: "8%" }}>
                <div className=" w-75 mt-2 m-auto">
                    <Alert severity="error" >
                        <AlertTitle className="">{frontLabels?.booking?.token_expire_msg || "The Link You Followed Has Expired"}</AlertTitle>
                    </Alert>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center text-center margin-top-4rem noto-font more-bold no-slot-color pb-5">
                    <div>
                        <img className="h-50 w-75" src={Images.no_slot} alt={error} />
                    </div>
                </div>
                <div className="w-75 m-auto cursor-pointer">
                    <Button
                        variant="outlined"
                        className='w-100'
                        onClick={handleClose}
                        sx={{
                            borderRadius: "4px",
                            border: "1px solid #D8DAE5",
                            background: "#FFF",

                        }}

                    >
                        <p style={{
                            color: " #696F8C",
                            fontSize: "16px",
                        }}
                            className='m-auto mb-2 py-2'
                        >{frontLabels?.booking?.back_to_login}</p>
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="d-flex justify-content-center mt-sm-2 mb-0 mt-0 " style={{overflowY:"auto", maxHeight:"86vh"}}>
                <div className="px-2 px-sm-0 w-75 create-profile-container mb-4">
                    {
                        <>
                            <div className="text-center mb-0">
                                <h1>{frontLabels?.header?.create_profile}</h1>
                                {/* <p>{frontLabels?.booking?.something_you_remember}</p> */}
                            </div>
                            <div>
                                <>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="mt-2">
                                            <FormControl fullWidth>
                                                <FormLabel className={`auth-label redAsterisk`}>{frontLabels?.booking?.password}</FormLabel>
                                                    <TextField
                                                        placeholder={frontMessages.MIN_PASSWORD}
                                                        id="password"
                                                        variant="outlined"
                                                        name="password"
                                                        type="password"
                                                        size="small"
                                                        sx={{
                                                            width: "100%",
                                                        }}
                                                        onChange={(e) => formik.setFieldValue("password", e.target.value)}
                                                        onBlur={()=> formik.setFieldTouched("password", true)}
                                                    />
                                                    {formik.errors.password && formik.touched.password && <small className="text-danger">{formik.errors.password}</small>}
                                            </FormControl>
                                        </div>
                                        <div className="mt-3">
                                            <FormControl fullWidth>
                                                <FormLabel className={`auth-label redAsterisk`}>{frontLabels?.booking?.confirm_your_password}</FormLabel>
                                                <TextField
                                                    placeholder={frontLabels?.booking?.rpt_password || "Repeat password" }
                                                    id="password_confirmation"
                                                    variant="outlined"
                                                    name="password_confirmation"
                                                    type="password"
                                                    size="small"
                                                    sx={{
                                                        width: "100%"
                                                    }}
                                                    onChange={(e) => formik.setFieldValue("password_confirmation", e.target.value)}
                                                    onBlur={()=> formik.setFieldTouched("password_confirmation", true)}
                                                />
                                                {formik.errors.password_confirmation && formik.touched.password_confirmation && <small className="text-danger">{formik.errors.password_confirmation}</small>}
                                            </FormControl>
                                        </div>
                                        <div className="mt-3">
                                            <FormControl fullWidth>
                                                <FormLabel className={`auth-label redAsterisk`}>{frontLabels?.booking?.enter_last_name}</FormLabel>
                                                <TextField
                                                    placeholder={frontLabels?.booking?.last_name}
                                                    id="last_name"
                                                    variant="outlined"
                                                    name="last_name"
                                                    size="small"
                                                    sx={{
                                                        width: "100%"
                                                    }}
                                                    onChange={(e) => formik.setFieldValue("last_name", e.target.value)}
                                                    onBlur={()=> formik.setFieldTouched("last_name", true)}
                                                />
                                                {formik.errors.last_name && formik.touched.last_name && <small className="text-danger">{formik.errors.last_name}</small>}
                                            </FormControl>
                                        </div>
                                        <div className="mt-3">
                                            <FormControl fullWidth>
                                                <FormLabel className={`auth-label redAsterisk`}>{frontLabels.booking.enter_first_name}</FormLabel>
                                                <TextField
                                                    placeholder={frontLabels?.booking?.first_name}
                                                    id="first_name"
                                                    variant="outlined"
                                                    name="first_name"
                                                    size="small"
                                                    sx={{
                                                        width: "100%"
                                                    }}
                                                    onChange={(e) => formik.setFieldValue("first_name", e.target.value)}
                                                    onBlur={()=> formik.setFieldTouched("first_name", true)}
                                                />
                                                {formik.errors.first_name && formik.touched.first_name && <small className="text-danger">{formik.errors.first_name}</small>}
                                            </FormControl>
                                        </div>
                                        {!loading ? <Button
                                            variant="contained"
                                            className="mt-3 mb-sm-3"
                                            size="large"
                                            sx={{
                                                background: btn_clinic_bg_color,
                                                width: "100%",
                                                '&:hover': {
                                                    // color: btn_clinic_bg_color,
                                                    backgroundColor: btn_clinic_bg_color,
                                                }
                                            }}
                                            type="submit"
                                        >
                                            {frontLabels?.contact?.ancr_submit_create_profile}
                                        </Button> : 
                                        <TailSpin
                                            height="35"
                                            width="35"
                                            color={btn_clinic_bg_color}
                                            ariaLabel="tail-spin-loading"
                                            radius="2"
                                            wrapperStyle={{marginBottom:"8px"}}
                                            wrapperClass="d-flex justify-content-center"
                                            visible={true}
                                        />
                                        }
                                    </form>
                                </>
                            </div>
                        </>
                    }
                    {/* {
                        error && (
                            <>
                                <Alert severity="error">
                                    <AlertTitle>Error</AlertTitle>
                                    {message || "Something went wrong!"}
                                </Alert>
                            </>
                        )
                    } */}
                </div>
            </div>
        </>
    )
}

export default Register;