import React, { useEffect, useState } from "react";
import moment from "moment";
import TimeSlots from "./TimeSlots";
import { useDispatch, useSelector } from "react-redux";
import { Images } from "../../Constants/Images";
import MobileVersionTimeSlots from "./MobileVersionTimeSlots";
import Calendar from "react-calendar";
import Modal from "react-modal";
import "react-calendar/dist/Calendar.css";
import "./Calendar.css";
import { useTranslation } from "react-i18next";
import { setCalendarWeek } from "../../Redux/calendar/slice";
import { getSlots } from "../../Redux/calendar/thunk";
import useCustomTheme from "../../hooks/useCustomTheme";
import "../../Constants/ja"
import useCurrentLanguage from "../../hooks/useCurrentLanguage";
import { LANGUAGES } from "../../Constants/Constant";


const MobileVersionCalendar = ({ dates }) => {
  
  const lang = useCurrentLanguage();
  moment.locale(lang);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const frontLabels = t("data.frontLabels", { returnObjects: true });
  const today = new Date();
  const doctorReducer = useSelector(state => state.doctor);
  const facilityReducer = useSelector(state => state.facility);
  const serviceReducer = useSelector(state => state.service);
  const calendarReducer = useSelector(state => state.calendar);
  const {doctor} = doctorReducer;
  const {facility} = facilityReducer;
  const {service} = serviceReducer;
  const {week, selectedDateIndex} = calendarReducer;
  const [currentDate] = useState(moment(dates));
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const handleChangeDate = (data, index) => {
    setWeekData(moment(data).format("YYYY-MM-DD"));
    setDisplayCalendar(!displayCalendar);
  }
  const handleMobileCalendar = () => setDisplayCalendar(!displayCalendar);
  const closeModal = () => setDisplayCalendar(!displayCalendar);
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => setWidth(window.innerWidth);
  const isMobile = width <= 568;

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [dispatch, currentDate]);

  const setWeekData = (selectedDate) => {
    if (!selectedDate) {
      selectedDate = new Date();
    }
    let momentSelectedDate = moment(selectedDate);
    const weekData = [];
    for (let index = 0; index < 7; index++) {
      const day = {};
      day["day"] = momentSelectedDate.format("dddd");
      day["monthDate"] = momentSelectedDate.format("MMM D");
      day["date"] = momentSelectedDate.format("YYYY-MM-DD");
      weekData.push(day);
      momentSelectedDate = moment(momentSelectedDate).add(1, "d");
    }
    const payload = {
      week: weekData,
      selectedDateIndex: 0 //calendar?.selected_date_index || 0
    }
    dispatch(setCalendarWeek(payload))

    const payloado = {
      clinicId: facility.id,
      serviceId: service.id,
      date: weekData[0]["date"],
      scheduleType: "in_person"
    }
    if (doctor) payloado["providerId"] = doctor?.user_id === "Any" ? doctor?.user_id?.toLowerCase() : doctor?.user_id || ""
    dispatch(getSlots(payloado));
  }
  return (
    <>
      <div className="container calendar m-0 p-0 time-slot-area height-100">
        <span className="no-display show-for-mobile slot-title-mobile title-size">
          {`${service?.service_for === "provider" ? "05." : "04."} ${frontLabels?.booking?.select_preferred_date_slot}`}
        </span>
        <div className=" slot-bg-color">
          <div className="d-flex justify-content-between text-center location noto-font">
            {/* <div className="d-inline-block ms-2 current-day">
              {frontLabels?.booking?.today}
            </div> */}
            <div className="mt-1 selected-date-mobile noto-font ">
                  {moment(selectedDateIndex === -1 ? new Date() : week[selectedDateIndex]["date"]).format(' LL')} {moment(selectedDateIndex === -1 ? new Date() : week[selectedDateIndex]["date"]).format('dddd').slice(0, 3)}
                </div>
            <div className="d-inline-block d-flex" onClick={handleMobileCalendar}>
              <div className="d-flex align-items-center calendar-icon">
              {frontLabels?.booking?.select_from_calendar || "Select date from calendar"}
              </div>
              <span className="d-flex align-items-center">
                &nbsp;
                <img src={Images.calendarIcon} alt="calendar" />
              </span>
                
            </div>

          </div>

          <Modal
            isOpen={displayCalendar}
            onRequestClose={closeModal}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                opacity: 1,
              },

              content: {
                position: "absolute",
                width: "500px",
                height: "428px",
                top: "50%",
                left: "50%",
                transform: "translate(-40%, -60%)",
                right: "490px",
                bottom: "200px",
                overflow: "hidden",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                outline: "none",
                padding: "25px",
                background: "transparent",
                border: "0",
              },
            }}
          >
            <Calendar value={selectedDateIndex === -1 ? new Date() : week[selectedDateIndex]["date"]} onChange={handleChangeDate} minDate={today} locale={`${lang === LANGUAGES.JP ? "ja" : lang}-GB`}/>
          </Modal>

         

          <div
            className="d-flex justify-content-around border-line mt-3 initial-justify overflow-mob day-slot"
            style={{ height: "69px" }}
          >
            <RenderMobileWeek setWeekData={setWeekData} />
          </div>
          {!isMobile ? <TimeSlots /> : <MobileVersionTimeSlots />}
        </div>
      </div>
    </>
  );
};

export default MobileVersionCalendar;

export const RenderMobileWeek = (props) => {
  const doctorReducer = useSelector(state => state.doctor);
  const facilityReducer = useSelector(state => state.facility);
  const serviceReducer = useSelector(state => state.service);
  const calendarReducer = useSelector(state => state.calendar);
  const {activeClass} = useCustomTheme();
  const {doctor} = doctorReducer;
  const {facility} = facilityReducer;
  const {service} = serviceReducer;
  const {week, selectedDateIndex} = calendarReducer;
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedDateIndex === -1) {
      props.setWeekData();
    }
  }, []);

  const handleChangeDate = (data, index) => {
    const payload = {
      // primary
      clinicId: facility.id,
      serviceId: service.id,
      date: data["date"],
      // secondary
      selectedDateIndex: index,
      selectDay: moment(data["date"]).format("dddd"),
      selectDate: data["date"],
      providerId: doctor?.user_id === "Any" ? doctor?.user_id?.toLowerCase() : doctor?.user_id || "",
      scheduleType: "in_person"
    }
    dispatch(getSlots(payload));
  }

  return (
    <>
      {week?.map((item, index) => {
        let day_date_format = "";
        day_date_format = item.monthDate.slice(3);
        return (
          <div
            key={`index=${index}`}
            className={`d-flex justify-content-center align-items-center flex-column p-2 pointer-icon`} style={{width:"53px", ...{...(selectedDateIndex === index) && activeClass}}}
            onClick={() => handleChangeDate(item, index)} >
            <div
              className={`noto-font session-text-color ${selectedDateIndex === index ? "select-days" : ""}`} >

              <div className="date-section">
              {
                day_date_format
              }
              </div>
              <div className="day-section">
                {item.day.slice(0, 2)}
              </div>
            </div>
          </div>
        );
      })}
    </>
  )
}