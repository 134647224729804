import { useRef } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState, useEffect } from 'react';
import useCustomTheme from '../../hooks/useCustomTheme';
import ContactNumber from './LoginForm/ContactNumber';
import EmailForm from './LoginForm/EmailForm';
import PetIdForm from './LoginForm/PetIdForm';
import OtpForm from './OtpForm';
import Button from '@mui/material/Button';
import { FormControl, FormLabel, TextField } from '@mui/material';
import EnterEmail from './LoginForm/EnterEmail';
import CheckYourEmail from './LoginForm/CheckYourEmail';
import { useNavigate } from 'react-router-dom';
import { Form, Formik, } from 'formik';
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from 'react-redux';
import { confirmation, login, sendOtp } from '../../Redux/auth/thunk';
import { resetLogin, updateCaptchToken } from '../../Redux/auth/slice';
import Alert from '@mui/material/Alert';
import { TailSpin } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import useTimer from '../../hooks/useTimer';
import styled from '@emotion/styled';
import SubHeading from './SubHeading';
import { TOAST_TIMER } from '../../Constants/Others';
import { useTranslation } from 'react-i18next';
import { LoginValidation, LoginPassword } from '../../Constants/Schema';
import useCurrentLanguage from '../../hooks/useCurrentLanguage';
import { LANGUAGES } from '../../Constants/Constant';
// import { BorderColor } from '@mui/icons-material';

const TYPE = {
    0: "contact",
    2: "email"
}

const StyledTab = styled(Tab)(({ theme, tab }) => ({

    // Media Query for screens with a maximum width of 600px
    '@media (max-width: 576px)': {
        //   backgroundColor: 'black',
        width: tab == 0 ? "8rem" : tab == 1 ? "4rem" : "7rem"
        // Add other responsive styles as needed
    },
}));
const INITIAL_VALUE = {
    phone: "",
    countryCode: "+81",
    email: "",
    petId: "",
    // password: ""
};

export default function Login() {
    let globaltimeout = null;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const lang = useCurrentLanguage();
    const [initForm, setInitForm] = useState(INITIAL_VALUE);
    const { timer, timerStatus, startTimer, endTimer } = useTimer();
    const auth = useSelector(state => state.auth);
    const { loginStatus, error, message, loading, token, clientId, forgotPasswordSuccess, forgotPasswordError } = auth;
    const [tab, setTab] = useState(0);
    const [captchaToken, setToken] = useState();
    const [feildStatus, setFeildStatus] = useState(false)
    const [recentError, setRecentError] = useState(null);
    const recaptchaRef = useRef();
    const formikRef = useRef();
    const {
        btn_clinic_bg_color,
    } = useCustomTheme();

    const { t } = useTranslation();
    const contactLabels = t("data.frontLabels.contact", { returnObjects: true });
    const frontLabels = t("data.frontLabels", { returnObjects: true });
    const frontMessages = t("data.frontMessages", { returnObjects: true });

    useEffect(() => {
        if (token) {
            const lastLocation = localStorage.getItem("lastLocation");
            navigate(!!lastLocation ? lastLocation : "/manage")
        }
    }, [token])

    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => setWidth(window.innerWidth);
    const isMobile = width <= 568;
    useEffect(() => {
        dispatch(resetLogin());
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const onHandleCaptchChange = (val) => {
        if (val) {
            setToken(val);
            dispatch(updateCaptchToken(val));
        }
    }
    const onHandleCaptchaExpiredError = () => {
        setToken();
        dispatch(updateCaptchToken());
    }
    const onHandleChangeTab = (e, tab, resetForm) => {
        setFeildStatus(false);
        setTab(tab)
        setInitForm(INITIAL_VALUE);
        resetForm();
        dispatch(resetLogin())
        setToken()
        recaptchaRef.current.reset();
    }

    const onHandleLogin = (args) => {
        try {
            if (!captchaToken) throw new Error(frontMessages?.CAPTCH_NOT_VERIFY || "Google captcha not verified.");
            const payload = {
                lang: lang || LANGUAGES.EN,
            };
            if (loginStatus === "existing_user") {
                if (auth.clientId) payload["client_id"] = auth.clientId;
                else throw "Client id not found";
                payload["password"] = args.password;
                dispatch(login(payload));
            } else {
                switch (tab) {
                    case 0:
                        payload["contact_no"] = args["phone"];
                        payload["country_code"] = args["countryCode"];
                        payload["login_type"] = "contact";
                        // delete args["phone"];
                        // delete args["countryCode"];
                        delete args["email"];
                        delete args["petId"];
                        break;
                    case 1:
                        payload["pet_id"] = args["petId"];
                        payload["login_type"] = "pet_id";
                        delete args["phone"];
                        delete args["countryCode"];
                        delete args["email"];
                        // delete args["petId"];
                        break;
                    case 2:
                        payload["email"] = args["email"];
                        payload["login_type"] = "email";
                        delete args["phone"];
                        delete args["countryCode"];
                        // delete args["email"];
                        delete args["petId"];
                        break;
                    default:
                    // throw new Error("Tab type undefined.");

                }
                payload["g-recaptcha-response"] = captchaToken;
                payload["lang"] = lang;
                dispatch(confirmation(payload));
            }
        } catch (error) {
            if (typeof error === "object" && error.message) {
                error = error.message;
            }
            if (error !== recentError) {
                toast.error(error, { autoClose: TOAST_TIMER });
                setRecentError(error)
                if (globaltimeout) clearTimeout(globaltimeout)
                globaltimeout = setTimeout(() => {
                    setRecentError(null)
                }, 2700)
            }
            return;
        }
    }

    const onClickSendOtp = () => {
        const payload = {
            contact_no: auth.login.phone,
            country_code: auth.login.countryCode
        }

        dispatch(sendOtp(payload));
    }

    const onHandleForgotPassword = () => {
        // const payload = {
        //     client_id: clientId,
        //     login_type: TYPE[tab]
        // }
        // dispatch(forgotPassword(payload));
        navigate("/auth/forgot-password")
    }

    useEffect(() => {
        if (loginStatus === "resetPassword") {
            navigate("/auth/reset-password");
        }
        if (tab === 0 && loginStatus === "new_user") {
            const payload = {
                contact_no: auth.login.phone,
                country_code: auth.login.countryCode
            }

            dispatch(sendOtp(payload));
        }
    }, [loginStatus])


    useEffect(() => {
        if (error && message && message !== frontMessages.CLIENT_NOT_FOUND_WITH_CONTACT) {
            if (error && error !== recentError) {
                toast.error(message, { autoClose: TOAST_TIMER });
                setRecentError(error);
                if (globaltimeout) clearTimeout(globaltimeout)
                // eslint-disable-next-line react-hooks/exhaustive-deps
                globaltimeout = setTimeout(() => {
                    setRecentError(null)
                }, 2700)
            }
        }
        else if (!error && message !== "" && message !== 500 && message !== 200) {
            toast.success(message, { autoClose: TOAST_TIMER });
        }
    }, [error, message])

    let LvObj = LoginValidation();
    let LpObj = LoginPassword();
    const FORM_VALIDATION = loginStatus && loginStatus === "existing_user" ? LvObj.concat(LpObj) : LvObj;

    return (
        <>
            <div className="d-flex align-items-center justify-content-center mt-3 mt-sm-4 mb-3 login-main-container">
                <div className='px-4 px-sm-0 login-container'>
                    <div className='text-center'>
                        {loginStatus && ["login", "new_user", "existing_user"].includes(loginStatus) && (
                            <>
                                <h1
                                    className='login-heading-style'
                                >
                                    {frontLabels?.header?.login_to_your_account}
                                </h1>
                                <p className="login-sub-heading mb-3">
                                    <SubHeading
                                        tab={tab}
                                    />
                                </p>
                            </>
                        )}
                    </div>
                    {loginStatus && ["login", "new_user", "existing_user"].includes(loginStatus) &&
                        (
                            <div className='w-100'>
                                <Formik
                                    innerRef={formikRef}
                                    initialValues={initForm}
                                    enableReinitialize={true}
                                    validationSchema={FORM_VALIDATION}
                                    onSubmit={onHandleLogin}
                                >
                                    {({ values, errors, setFieldValue, setFieldTouched, touched, resetForm }) => (
                                        <>
                                            <Form
                                                onKeyUp={e => {
                                                    // if (e.key === "Enter") {
                                                    //     submitForm();
                                                    // }
                                                }}
                                            >
                                                <Tabs
                                                    value={tab}
                                                    onChange={(e, tab) => onHandleChangeTab(e, tab, resetForm)}
                                                    // aria-label="disabled tabs example"
                                                    indicatorColor="none"
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                    allowScrollButtonsMobile
                                                    sx={{
                                                        background: "#F3F4F6",
                                                        borderRadius: "4px",
                                                        width: "100%",
                                                        height: "44px",
                                                        border: "1px solid gray",
                                                        margin: "auto",
                                                        marginTop: `${!isMobile} ? "20px":"5px"`,
                                                        marginBottom: `${!isMobile} ? "20px":"5px"`,
                                                    }}
                                                >
                                                    <StyledTab
                                                        className='contact-tab-switch text-nowrap '
                                                        label={frontLabels?.booking?.contact_number}
                                                        style={{ width: "33.33%" }}
                                                        sx={tab === 0 ? {
                                                            background: btn_clinic_bg_color,
                                                            textTransform: "capitalize",
                                                            '&.Mui-selected': {
                                                                color: '#fff',
                                                            }
                                                        } : { textTransform: "capitalize" }}
                                                    />
                                                    <StyledTab
                                                        className='pet-tab-switch text-nowrap'
                                                        style={{ width: "33.33%" }}
                                                        sx={tab === 1 ? {
                                                            background: btn_clinic_bg_color,
                                                            textTransform: "capitalize",
                                                            '&.Mui-selected': {
                                                                color: '#fff',
                                                            }
                                                        } : { textTransform: "capitalize" }}
                                                        label={frontLabels?.booking?.pet_ID}
                                                    />
                                                    <StyledTab
                                                        style={{ width: "33.33%" }}
                                                        className='email-tab-switch text-nowrap'
                                                        sx={tab === 2 ? {
                                                            background: btn_clinic_bg_color,
                                                            textTransform: "capitalize",
                                                            '&.Mui-selected': {
                                                                color: '#fff',
                                                            }
                                                        } : { textTransform: "capitalize" }}
                                                        label={contactLabels?.email_address}
                                                    />
                                                </Tabs>
                                                <div
                                                    role="tabpanel"
                                                    hidden={tab !== 0}
                                                    index={0}
                                                    value="0"

                                                >
                                                    <ContactNumber loginStatus={loginStatus} />
                                                </div>
                                                <div
                                                    role="tabpanel"
                                                    hidden={tab !== 1}
                                                    index={1}
                                                    value="1"
                                                    className='w-100'
                                                >
                                                    <PetIdForm loginStatus={loginStatus} />
                                                </div>
                                                <div
                                                    role="tabpanel"
                                                    hidden={tab !== 2}
                                                    index={2}
                                                    value="2"
                                                >
                                                    <EmailForm loginStatus={loginStatus} feildStatus={feildStatus} />
                                                </div>

                                                {/* PASSWORD */}
                                                {loginStatus === "existing_user" &&
                                                    <div className='mt-2'>
                                                        <FormControl fullWidth>
                                                            <FormLabel
                                                                className={`auth-label redAsterisk`}
                                                            >{frontLabels?.booking?.password}</FormLabel>
                                                            <TextField
                                                                minLength={6}
                                                                maxLength={16}
                                                                id="password"
                                                                name="password"
                                                                type="password"
                                                                className="login-inputs mb-0"
                                                                value={values.password || ""}
                                                                onChange={(e) => {
                                                                    if (e.target.value.length <= 15) {
                                                                        setFieldValue("password", e.target.value);
                                                                    }
                                                                }}
                                                                onBlur={() => setFieldTouched("password", true)}
                                                            />
                                                            <div>
                                                                {errors.password && loginStatus === "existing_user" && <small className="text-danger text-nowrap m-0 p-0">{errors.password}</small>}
                                                                {!loading &&
                                                                    <>
                                                                        {timerStatus && <span className='float-end'>
                                                                            00:{timer < 10 ? `0${timer}` : timer}
                                                                        </span>}
                                                                        {!timerStatus && <small
                                                                            className='text-primary float-end pointer-icon mt-1'
                                                                            onClick={onHandleForgotPassword}
                                                                        // onClick={Navigate("/forgot-passwod")}
                                                                        >
                                                                            {frontLabels?.booking?.forgot_password || "Forgot Password ?"}
                                                                        </small>}
                                                                    </>
                                                                }
                                                            </div>

                                                        </FormControl>
                                                    </div>}
                                                {/* error */}
                                                {error && (
                                                    <>
                                                        <div className="m-0 w-100 m-auto text-center p-0 ">
                                                            <Alert severity="error" icon={false}>
                                                                <small>{message}</small>
                                                            </Alert>
                                                        </div>
                                                    </>
                                                )}

                                                {/* captch */}
                                                {loginStatus === "login" &&
                                                    <div className="d-flex justify-content-center mt-3 text-center">
                                                        <ReCAPTCHA
                                                            ref={recaptchaRef}
                                                            sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
                                                            onChange={onHandleCaptchChange}
                                                            onExpired={onHandleCaptchaExpiredError}
                                                            onErrored={onHandleCaptchaExpiredError}
                                                        />
                                                    </div>
                                                }

                                                {/* submit btn */}
                                                <div>
                                                    {!loading ? <Button
                                                        variant="contained"
                                                        className=" auth-btn auth-submit-btn mt-2"
                                                        size="large"
                                                        sx={{
                                                            borderRadius: "8px",
                                                            background: btn_clinic_bg_color,
                                                            width: "100%",
                                                            '&:hover': {
                                                                backgroundColor: btn_clinic_bg_color,
                                                            }
                                                        }}
                                                        type="submit"
                                                    // onClick={submitForm}
                                                    >
                                                        {contactLabels?.ancr_submit}
                                                    </Button> :
                                                        <TailSpin
                                                            height="35"
                                                            width="35"
                                                            color={btn_clinic_bg_color}
                                                            ariaLabel="tail-spin-loading"
                                                            radius="2"
                                                            wrapperStyle={{ marginBottom: "8px" }}
                                                            wrapperClass="d-flex justify-content-center"
                                                            visible={true}

                                                        />
                                                    }
                                                </div>
                                            </Form>
                                        </>
                                    )}
                                </Formik>
                            </div>
                        )}
                    {/* {
                        !loading && loginStatus &&
                        loginStatus === "new_user" &&
                        (
                            <>
                                <Divider className="mt-2" />
                                <button
                                    // sx={{
                                    //     width: "100%",
                                    //     // color: btn_clinic_bg_color
                                    // }}
                                    className="mt-2 auth-secondary-btn w-100"
                                    onClick={onClickSendOtp}
                                >
                                    {frontLabels?.header?.create_new_account}
                                </button>
                            </>
                        )
                    } */}

                    {/* enter otp */}
                    {loginStatus && ["otpSent", "reset_password"].includes(loginStatus) && <OtpForm />}

                    {/* enter email */}
                    {loginStatus && ["enter_email", "pet_email"].includes(loginStatus) && <EnterEmail />}

                    {/* CHECK YOUR EMAIL */}
                    {loginStatus && ["emailSent", "email_exist", "pet_email_sent", "email_view"].includes(loginStatus) && <CheckYourEmail />}
                </div>
            </div>

        </>
    )
}