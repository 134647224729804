import React from "react";
import { Popover } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import { useTranslation } from "react-i18next";


const WeeklyTimeSchedule = ({ action, ...props }) => {
  const { t } = useTranslation();
  const frontLabels = t("data.frontLabels", { returnObjects: true });

  const daysArray = frontLabels?.booking?.daysArray ||  ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const businessHoursByDay = props?.businessHours?.reduce((acc, item) => {
    acc[item.day] = item;
    return acc;
  }, {});
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        {/* <table>
          <tbody>
            {
              props?.businessHours?.map((item, index) => (
                <tr key={`index=${index}`}>
                  <td>{`${daysArray[item?.day - 1]}`}</td> &nbsp;&nbsp;&nbsp;
                  <td>{`${item?.from_time?.substring(0, 5)}`}</td>&nbsp;
                  <td>-</td>
                  &nbsp;<td>{`${item?.to_time?.substring(0, 5)}`}</td>
                </tr>
              ))
            }
          </tbody>
        </table> */}
        <table>
          <tbody>
            {daysArray.map((day, dayIndex) => (
              <tr key={`day-${dayIndex}`}>
                <td>{day}</td> &nbsp;&nbsp;
                <td className="text-center">
                  {businessHoursByDay[dayIndex + 1]
                    ? `${businessHoursByDay[dayIndex + 1].from_time.substring(0, 5)}`
                    : <div className="text-danger">{frontLabels?.booking?.closed}</div>}
                </td>&nbsp;
                <td>{businessHoursByDay[dayIndex + 1]
                    ? "-"
                    : ""}</td>
                &nbsp;
                <td>
                  {businessHoursByDay[dayIndex + 1]
                    ? `${businessHoursByDay[dayIndex + 1].to_time.substring(0, 5)}`
                    : ""}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

      </Popover.Body>
    </Popover>
  );
  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      overlay={popover}
      rootClose={true}
    >
      {action}
    </OverlayTrigger>
  );
};

export default React.memo(WeeklyTimeSchedule);
