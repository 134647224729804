import logo from "../images/aalda_logo.svg";
import no_slot from "../images/no_slots_available.svg";
import clock_icon from "../images/clock_icon.svg";
import left_icon from "../images/left_arrow.svg";
import right_icon from "../images/right_arrow.svg";
import morning_icon from "../images/morning_icon.svg";
import noon_icon from "../images/noon_icon.svg";
import evening_icon from "../images/evening_icon.svg";
import right_btn_arrow from "../images/right_btn_arrow.svg";
import left_btn_arrow from "../images/left_btn_arrow.svg";
import show_more from "../images/show_more.svg";
import show_less from "../images/show_less.svg";
import head_title_arrow from "../images/head_tittle_arrow.svg";
import DefaultDoctorImage from "../images/newdefaultimage1.png";
import successOtpImage from "../images/otp_success.svg";
import refreshIcon from "../images/refresh_icon.svg";
import TestingImage1 from  "../images/TestingImage1.jpg";
import calendarIcon from "../images/calendar_icon.svg";
import dogImage from "../images/dogImage.jpg";
import home_page_arrow from "../images/Frame.svg";
import insta_logo from "../images/insta-logo.svg";

export const Images = {
  insta_logo,
  home_page_arrow,
  logo,
  no_slot,
  clock_icon,
  left_icon,
  right_icon,
  morning_icon,
  noon_icon,
  evening_icon,
  right_btn_arrow,
  left_btn_arrow,
  show_more,
  show_less,
  head_title_arrow,
  DefaultDoctorImage,
  successOtpImage,
  refreshIcon,
  TestingImage1,
  calendarIcon,
  dogImage,
  im_aalda_logo: "../images/aalda_logo.svg",
  im_placeholder_logo:"../images/booking_portal_logo.png",
  im_arrow: "../images/arrow.svg",
  im_dcc_circle: "../images/dcc-circle.png",
  im_doctor: "../images/DoctorImage.jpg",
  im_down_arrow: "../images/DownArrow.svg",
  im_error_page: "../images/ErrorPage.png",
  im_fade_arrow: "../images/fade_arrow.svg",
  im_first: "../images/first.svg",
  im_fourth: "../images/fourth.svg",
  im_image: "../images/image.png",
  im_second: "../images/second.svg",
  im_testing: "../images/TestingImage.jpg",
  im_third: "../images/third.svg",
  fevi_icon: "../images/favicon.icon",
 
  
};
