// import { legacy_createStore as createStore, applyMiddleware, compose } from "redux";
// import RootReducer from "./RootReducer";
// import createSagaMiddleware from 'redux-saga'
// import mySaga from "./sagas/saga";

// const sagaMiddleware = createSagaMiddleware()


// const composeWithDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const composedEnhancers = composeWithDevTools(applyMiddleware(sagaMiddleware))

// export const store = createStore(RootReducer, composedEnhancers);
// sagaMiddleware.run(mySaga);
// -- NEW
import facilityReducer from "./facility/slice";
import petReducer from "./pet/slice";
import serviceReducer from "./service/slice";
import configReducer from "./config/slice";
import authReducer from "./auth/slice";
import doctorReducer from "./doctor/slice";
import calendarReducer from "./calendar/slice";
import appointmentReducer from "./appointment/slice";
import { configureStore } from "@reduxjs/toolkit";

export const store = configureStore({
    reducer: {
        config: configReducer,
        facility: facilityReducer,
        pet: petReducer,
        service: serviceReducer,
        doctor: doctorReducer,
        auth: authReducer,
        calendar: calendarReducer,
        appointment: appointmentReducer
    },
});
