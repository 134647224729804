import { createSlice } from "@reduxjs/toolkit";
import { getFacilities } from "./thunk";

const InitialState={
    loading: false,
    error: false,
    facilities: null,
    facility: null
};

const facilitySlice= createSlice({
    name:"Facilities",
    initialState: InitialState,
    reducers: {
        setFacility(state, action) {
            state.facility = action.payload;
        },
        resetFacility(state, action) {
            return InitialState;
        }
    },
    extraReducers: (builder) => {
        // get facilities
        builder.addCase(getFacilities.pending, (state) => {
            state.loading = true;
        })
        .addCase(getFacilities.fulfilled,(state,{payload})=>{
            state.loading=false;
            state.facilities = payload.facilities;
            if (payload.facilities.length > 0) {
                state.facility = payload.facilities[0];
            }
        })
        .addCase(getFacilities.rejected,(state,payload)=>{
            state.loading = false;
            state.facilities = null;
            state.error = true;
        });
    },
});

export const { setFacility, resetFacility } = facilitySlice.actions;
export default facilitySlice.reducer;