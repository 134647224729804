import React, { useState, useEffect, useCallback } from "react";
import FormStepHeader from "../FormStepHeader/FormStepHeader";
import Header from "../Header";
import SelectFacility from "../SelectFacility/SelectFacility";
import SelectDoctor from "../SelectDoctor/SelectDoctor";
import ThankYou from "../ThankYou";
import { switchCases } from "../../Constants/Others";
import CircularProgressVariants from "../../Loader";
import { useSelector } from "react-redux";
import FooterSection from "../Footer/FooterSection";
import EnterInfoExistingAndManageUsers from "../EnterInfo/EnterInfoExistingAndManageUsers";
import ManageBookings from "../ManageBookings/ManageBookings";
import { useTranslation } from "react-i18next";

const Layout = () => {
  const { t } = useTranslation();
  const bookingLabels = t("data.frontLabels.booking", { returnObjects: true });
  const [stepCount, setStepCount] = useState(0);
  const [statusName, setStatusName] = useState('');
  const [navTab,setNavTab]=useState(0);
  const loader = useSelector((state) => state?.hospistalReducer?.loading);
  const handleStepAction = (stepNumber) =>  {
    setStepCount(stepNumber)
  };
  const getStatus = (selectedName) => setStatusName(selectedName);
  const clientData = useSelector((state) => state?.hospistalReducer?.clientData);
  const userAction = useSelector((state) => state?.hospistalReducer?.userAction);

  const [tabChangeTime, setTabChangeTime] = useState(null)
  const handleNavChange = (value) => {
    setNavTab(value)
    setTabChangeTime(new Date())
  }

  
  const renderCurrentSelection = () => {
    switch (stepCount) {
      case switchCases.loginPage:
        // return <Login handleStepAction={handleStepAction} getStatus={getStatus} stepCount={stepCount} />
        
      case switchCases.selectFacility:
        return <SelectFacility handleStepAction={handleStepAction} navTab={navTab} timeTabSelected={tabChangeTime}/>;
      case switchCases.selectDoctor:
        return <SelectDoctor handleStepAction={handleStepAction} navTab={navTab} timeTabSelected={tabChangeTime}/>;
      case switchCases.enterInfo:
        return <EnterInfoExistingAndManageUsers handleStepAction={handleStepAction} statusName={statusName} />;
        // return statusName === bookingLabels?.first_booking_button ? <EnterInfo handleStepAction={handleStepAction} statusName={statusName} /> : <EnterInfoExistingAndManageUsers handleStepAction={handleStepAction} statusName={statusName} />;
      case switchCases.thankYou:
        return <ThankYou handleStepAction={handleStepAction}/>;
      case switchCases.manageBookings:
        return <ManageBookings handleStepAction={handleStepAction} setStatusName={setStatusName} getStatus={getStatus} />
      default:
        return null;
    }
  };
  
  const unloadCallback = useCallback((event) => {
    event.preventDefault();
    event.returnValue = "";
    return "";
  }, [])
  useEffect(() => {
    window.addEventListener("beforeunload", unloadCallback);
    return () => {
      window.removeEventListener("beforeunload", unloadCallback);
    }
  }, []);
  useEffect(() => {
    if (clientData && clientData.id && userAction) {
      if (userAction === bookingLabels?.manage_booking) {
        handleStepAction(5);
      } else if (userAction === bookingLabels?.second_booking_button) {
        handleStepAction(1);
      }
    }
  }, [clientData, userAction]);
  useEffect(() => {
    if (stepCount === 4 || stepCount === 0) {
      window.removeEventListener("beforeunload", unloadCallback);
    } else {
      window.addEventListener("beforeunload", unloadCallback);
    }
  }, [stepCount])
  return (
    <>
        <div style={{minHeight:"95vh"}}>
          {loader && <CircularProgressVariants />}
          {(stepCount !== 4 && stepCount !== 5 && statusName !== bookingLabels?.manage_booking) && <Header />}
          {(stepCount !== 4 && stepCount !== 6 && statusName !== bookingLabels?.manage_booking) && <FormStepHeader stepCount={stepCount} statusName={statusName} handleStepAction={handleStepAction} setNavTab={handleNavChange} />}
          {renderCurrentSelection()}
        </div>
        {(stepCount !== 4) && <FooterSection />}
    
    </>
  );
};

export default React.memo(Layout);
