import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios  from "../../Services/Api/Index";
import { API_PATH } from '../../Constants/ApiPath';
import { API_TYPE } from "../../Constants/ApiType";
import { LANGUAGES } from "../../Constants/Constant";
// import { resetFacility } from "../facility/slice";
// import { resetPet } from "../pet/slice";
// import { resetService } from "../service/slice";
// import { resetDoctor } from "../doctor/slice";
// import { resetCalendar } from "../calendar/slice";

export const createAppointment = createAsyncThunk(
  "appointment/createAppointment",
  async(payload,{rejectWithValue, dispatch})=>{
    try{
      const AUTH_TOKEN = localStorage.getItem("auth_token");
      const lang_code = localStorage.getItem("lang_type");
      const response = await Axios({
        method: API_TYPE.POST,
        url: API_PATH.CREATE_APPOINTMENT,
        data: payload,
        headers: {
          Authorization: AUTH_TOKEN ? `Bearer ${AUTH_TOKEN}` : "",
          withCredentials: true,
          "Accept-Language": lang_code || LANGUAGES.JP,
        },
      });
      const data = await response.data;
      if (data.status === "success" && data.statuscode === 200) {
        return {message: data.message}
      } else {
        throw data.message || "Something went wrong!";
      }
    }catch(err){
      if (typeof err === "object" && err.message) {
        err = err.message;
      }
      return rejectWithValue(err);
    }
  }
);

export const getAppointments = createAsyncThunk(
  "appointment/getAppointments",
  async(payload,{rejectWithValue})=>{
    try{
      const AUTH_TOKEN = localStorage.getItem("auth_token");
      const lang_code = localStorage.getItem("lang_type");
      const response = await Axios({
        method: API_TYPE.GET,
        url: API_PATH.DASHBOARD_DATA,
        headers: {
          Authorization: AUTH_TOKEN ? `Bearer ${AUTH_TOKEN}` : "",
          withCredentials: true,
          "Accept-Language": lang_code || LANGUAGES.JP,
        },
      });
      const data = await response.data;
      if (data.status === "success" && data.statuscode === 200 && data.data.appointments) {
        return {appointments: data.data.appointments}
      } else {
        throw data.message || "Something went wrong!";
      }
    }catch(err){
      if (typeof err === "object" && err.message) {
        err = err.message;
      }
      return rejectWithValue(err);
    }
  }
);

export const rescheduleAppointment = createAsyncThunk(
  "appointment/rescheduleAppointment",
  async(payload,{rejectWithValue})=>{
    try{
      const AUTH_TOKEN = localStorage.getItem("auth_token");
      const lang_code = localStorage.getItem("lang_type");
      const response = await Axios({
        method: API_TYPE.PUT,
        url: API_PATH.RESCHEDULE_APPOINTMENT,
        data: payload,
        headers: {
          Authorization: AUTH_TOKEN ? `Bearer ${AUTH_TOKEN}` : "",
          withCredentials: true,
          "Accept-Language": lang_code || LANGUAGES.JP,
        },
      });
      const data = await response.data;
      if (data.status === "success" && data.statuscode === 200) {
        return {appointments: data.data.appointments}
      } else {
        throw data.message || "Something went wrong!";
      }
    }catch(err){
      if (typeof err === "object" && err.message) {
        err = err.message;
      }
      return rejectWithValue(err);
    }
  }
);

export const cancelAppointment = createAsyncThunk(
  "appointment/cancelAppointment",
  async(payload,{rejectWithValue})=>{
    try{
      const AUTH_TOKEN = localStorage.getItem("auth_token");
      const lang_code = localStorage.getItem("lang_type");
      const response = await Axios({
        method: API_TYPE.POST,
        url: API_PATH.CANCEL_APPOINTMENT,
        data: payload,
        headers: {
          Authorization: AUTH_TOKEN ? `Bearer ${AUTH_TOKEN}` : "",
          withCredentials: true,
          "Accept-Language": lang_code || LANGUAGES.JP,
        },
      });
      const data = await response.data;
      if (data.status === "success" && data.statuscode === 200) {
        return {appointments: data.data.appointments}
      } else {
        throw data.message || "Something went wrong!";
      }
    }catch(err){
      if (typeof err === "object" && err.message) {
        err = err.message;
      }
      return rejectWithValue(err);
    }
  }
);