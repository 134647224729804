import { LANGUAGES } from "./Constant";

const daysEn = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const monthsEn = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const daysJp = ['日', '月', '火', '水', '木', '金', '土'];
const monthsJp = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];

export const getLocale = (lang) => {
lang = localStorage.getItem("lang_type") || LANGUAGES.JP;
  if (lang === LANGUAGES.EN) {
    return {
      localize: {
        day: n => daysEn[n],
        month: n => monthsEn[n]
      },
      formatLong: {
        date: () => 'mm/dd/yyyy'
      }
    };
  } else if (lang === LANGUAGES.JP) {
    return {
      localize: {
        day: n => daysJp[n],
        month: n => monthsJp[n]
      },
      formatLong: {
        date: () => 'yyyy年mm月dd日'
      }
    };
  }

  // Default to English if language code is not recognized
  return {
    localize: {
      day: n => daysEn[n],
      month: n => monthsEn[n]
    },
    formatLong: {
      date: () => 'mm/dd/yyyy'
    }
  };
};



