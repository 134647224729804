import { createSlice } from "@reduxjs/toolkit";
import { getServices } from "./thunk";

const InitialState={
    loading: false,
    error: false,
    services: [],
    service: null
};

const serviceSlice= createSlice({
    name:"Services",
    initialState: InitialState,
    reducers: {
        setService(state, action) {
            state.service = action.payload;
        },
        resetService(state, action) {
            return InitialState;
        }
    },
    extraReducers: (builder) => {
        // get facilities
        builder.addCase(getServices.pending, (state) => {
            state.loading = true;
        })
        .addCase(getServices.fulfilled,(state,{payload})=>{
            const categories = payload.data;
            const SERVICES = [];
            if (categories && Object.keys(categories).length > 0) {
                for (const key in categories) {
                    if (categories[key].services && categories[key].services.length > 0) {
                        categories[key].services.forEach(element => {
                            element["category_name"] = categories[key]["service_category"];
                            element["category_id"] = categories[key]["category_id"];
                            SERVICES.push(element);
                        });
                    }
                }
            }
            state.loading=false;
            state.services = SERVICES;
        })
        .addCase(getServices.rejected,(state,payload)=>{
            state.loading = false;
            state.services = [];
            state.error = true;
        });
    },
});

export const { setService, resetService } = serviceSlice.actions;
export default serviceSlice.reducer;