import { TextField, FormControl, Button, ButtonGroup, FormLabel, Autocomplete, Select, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AddPetValidation } from "../../Constants/Schema";
import useCustomTheme from "../../hooks/useCustomTheme";
import { createPet, getBreeds, getSpecies } from "../../Redux/pet/thunk";
import { useEffect, useState } from "react";
import { PET_GENDER_OPTIONS, NEUTERED_OPTIONS, TOAST_TIMER, PET_GENDER_OPTIONS_JP, NEUTERED_OPTIONS_JP } from "../../Constants/Others";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import './AddPet.css'
import { TailSpin } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { getLocale } from "../../Constants/LocalForDatePicker";
import { LANGUAGES } from "../../Constants/Constant";

function AddPetPage({ modal, modalClose }) {
    let globaltimeout = null;
    const {
        btn_clinic_bg_color,
    } = useCustomTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const petState = useSelector(state => state.pet);
    const { species, breeds, addPetSuccess, addPetError, loading } = petState;
    const { t } = useTranslation();
    const formLabels = t("data.frontLabels", { returnObjects: true });
    const [startDate, setDate] = useState("")
    const [recentError, setRecentError] = useState(null);
    const config = useSelector((state) => state.config);
    const { lang } = config
    useEffect(() => {
        dispatch(getSpecies());
    }, []);



    useEffect(() => {
        if (modal && modalClose && addPetSuccess) {
            modalClose(false);
            toast.success(addPetSuccess);
            navigate("/booking");
        } else {
            if (addPetSuccess) {
                toast.success(addPetSuccess);
                navigate("/booking");
            } else if (addPetError && recentError !== addPetError) {
                toast.error(addPetError, { autoClose: TOAST_TIMER });
                setRecentError(addPetError)
                if (globaltimeout) clearTimeout(globaltimeout)
                // eslint-disable-next-line react-hooks/exhaustive-deps
                globaltimeout = setTimeout(() => {
                    setRecentError(null)
                }, 2700)
            }
        }
    }, [addPetSuccess, addPetError]);

    const onHandleSubmit = (payload) => {

        payload["dob_confirmed"] = payload["dobState"];
        delete payload["dobState"];
        dispatch(createPet(payload))
    }

    const formik = useFormik({
        initialValues: {
            name: "",
            dob: "",
            dobState: "confirm",
            species: "",
            breed: "",
            gender: "",
            neutered: "",
            breed_name:""
        },
        enableReinitialize: true,
        validationSchema: AddPetValidation(),
        onSubmit: onHandleSubmit
    });

    const activeBtnCss = {
        textTransform: 'capitalize',
        background: btn_clinic_bg_color,
        color: "white",
        '&:hover': {
            // color: btn_clinic_bg_color,
            backgroundColor: btn_clinic_bg_color,
        },
        borderColor: btn_clinic_bg_color + "!important",
        borderRadius: "8px",
        boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.12)",
        // padding:0
    };

    const inactiveBtnCss = {
        textTransform: 'capitalize',
        background: "#F3F4F6",
        // color: btn_clinic_bg_color,
        color: "#1F2124",
        '&:hover': {
            // color: btn_clinic_bg_color,
            backgroundColor: "white",
        },
        borderColor: "white !important"
    };

    useEffect(() => {
        if (formik.values?.species) {
            dispatch(getBreeds(formik.values.species));
        }
    }, [formik.values.species]);

    const currentLang = i18n.language;
    const lastLocation = localStorage.getItem("lastLocation") !== "" ? localStorage.getItem("lastLocation") : "/manage";
    return (
        <div className="add-pet--main-container ">
            <div className={`add-pet-container  d-flex justify-content-center ${!modal && "mt-0 mt-sm-4"}`} style={!modal ? { maxHeight: "87vh", overflowY: "auto" } : {}} >
                <div className="addpet-container">
                    {!modal &&
                        <div className="text-center m-0">
                            {/* <h1>Add Pet!</h1> */}
                            <h1 className=" m-0" >{formLabels?.header?.add_your_pet}</h1>
                        </div>
                    }
                    <form className="add-pet-form  addPetForm " onSubmit={formik.handleSubmit}>

                        <div className="row">
                            <div className="col-12 mb-0 mb-sm-2">
                                <FormControl fullWidth>
                                    <FormLabel className={`auth-label redAsterisk`}>{formLabels?.booking?.enter_pet_name}</FormLabel>
                                    <TextField
                                        placeholder={formLabels?.booking?.pet_name}
                                        name="name"
                                        id="name"
                                        value={formik.values.name}
                                        onChange={(e) => formik.setFieldValue("name", e.target.value)}
                                        size="small"
                                        onBlur={() => formik.setFieldTouched("name", true)}
                                    />
                                    {formik.errors.name && formik.touched.name && <small className="text-danger">{formik.errors.name}</small>}
                                </FormControl>
                                {/* <div className="form-group">
                                    <FormLabel required>Enter your pet name</FormLabel>
                                    <input
                                        className="form-control mb-0"
                                        name="name"
                                        value={formik.values.name}
                                        onChange={(e) => formik.setFieldValue("name", e.target.value)}
                                    />
                                    {formik.errors.name && formik.touched.name && <small className="text-danger">{formik.errors.name}</small>}
                                </div> */}
                            </div>
                        </div>
                        <div className="row mb-0 mb-sm-2">
                            <FormLabel className="text-nowrap dob-leble-style auth-label redAsterisk">{formLabels?.booking?.pet_dob}</FormLabel>
                            <div className="col-6 m-0 pet-dob-style input-dob-section">
                                <FormControl fullWidth>
                                    <DatePicker
                                        locale={getLocale()}
                                        autoComplete="off"
                                        name="dob"
                                        dateFormat="yyyy/MM/dd"
                                        dropdownMode="select"
                                        showYearDropdown
                                        showMonthDropdown
                                        placeholderText="yyyy-mm-dd"
                                        selected={startDate}
                                        onChange={(d) => {
                                            setDate(d)
                                            formik.setFieldValue("dob", d);
                                        }}
                                        className="date-picker-style"
                                        maxDate={new Date()}
                                    />
                                </FormControl>
                            </div>
                            <div className="col-6 m-0 d-flex align-items-end g-0  g-sm-4 ">
                                <div className="dob-state-style">
                                    <ButtonGroup
                                        disableElevation
                                        variant="contained"
                                        sx={{
                                            paddingBottom: formik.errors?.dob && formik.touched?.dob ? "21px" : "0px",
                                            display: "flex",
                                            // width: 229px,
                                            padding: "4px",
                                            alignItems: "center",
                                            borderRadius: "4px",
                                            border: "1px solid #ACB0B8",
                                            background: "var(--gray-300, #F3F4F6",
                                        }}
                                    >
                                        <Button
                                            sx={formik.values.dobState === "confirm" ? activeBtnCss : inactiveBtnCss}
                                            onClick={(e) => {
                                                formik.setFieldValue("dobState", "confirm")
                                            }}
                                        >
                                            {formLabels?.booking?.confirm}
                                        </Button>
                                        <Button
                                            sx={formik.values.dobState === "estimate" ? activeBtnCss : inactiveBtnCss}
                                            onClick={(e) => {
                                                formik.setFieldValue("dobState", "estimate")
                                            }}
                                        >
                                            {formLabels?.booking?.estimated}
                                        </Button>
                                    </ButtonGroup>
                                </div>

                            </div>
                            {formik.errors.dob && formik.touched.dob && <small className="text-danger d-block mt-0">{formik.errors.dob}</small>}
                        </div>
                        <div className="row mb-0 mt-0 mb-sm-2">
                            <div className=" col-12 col-sm-6 mb-2 mb-sm-1">
                                <FormControl fullWidth>
                                    <FormLabel className={`auth-label redAsterisk`}>{formLabels?.booking?.select_pet_species}</FormLabel>
                                    {/* <Select
                                        name="species"
                                        id="species"
                                        value={formik.values.species}
                                        onChange={(e) => {
                                            formik.setFieldValue("species", e.target.value);
                                            formik.setFieldValue("breed", "");
                                        }}
                                        size="small"
                                    >
                                        <MenuItem disabled value="" >Select Species</MenuItem>
                                        {
                                            species?.map((item, index) => (
                                                <MenuItem key={item.id} value={item.id}>{item.species_en}</MenuItem>
                                            ))
                                        }
                                        
                                    </Select> */}
                                    <Autocomplete
                                        disableClearable
                                        size="small"
                                        options={species != null ? species : []}
                                        getOptionLabel={(option) => (option ? (lang == LANGUAGES.EN ? option.species_en : option.species) : "")}
                                        name="species"
                                        value={
                                            species?.find((item) => item.species_en === formik.values.species)
                                        }
                                        onChange={(e, newValue) => {
                                            formik.setFieldValue("species", newValue ? newValue.id : "");
                                            // formik.setFieldValue("species", e.target.value);
                                            formik.setFieldValue("breed", "");
                                        }}
                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder={formLabels?.booking?.species_select}
                                                fullWidth
                                            />
                                        )}
                                    />
                                    {formik.errors.species && formik.touched.species && <small className="text-danger">{formik.errors.species}</small>}
                                </FormControl>

                            </div>
                            <div className="col-12 col-sm-6">
                                <FormControl fullWidth>
                                    <FormLabel className={`auth-label redAsterisk`}>{formLabels?.booking?.select_pet_breed}</FormLabel>
                                    <Autocomplete
                                        disableClearable
                                        size="small"
                                        options={breeds != null ? breeds : []}
                                        getOptionLabel={(option) => (option ? (lang == LANGUAGES.EN ? option.name_en : option.name) : "")}
                                        name="breed"
                                        value={breeds?.find((item) => item.name_en === formik.values.breed)}
                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                        onChange={(_, newValue) => {
                                            formik.setFieldValue("breed", newValue ? newValue.id : "");
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder={formLabels?.booking?.species_select}
                                                fullWidth
                                            />
                                        )}
                                    />
                                    {formik.errors.breed && formik.touched.breed && <small className="text-danger">{formik.errors.breed}</small>}
                                </FormControl>
                            </div>
                        </div>
                        <div className="row mb-0 mb-sm-2">
                            {formik.values.breed === 0 && (
                                <div className="col-12 col-sm-6 mb-2 mb-sm-1">
                                    <FormControl fullWidth>
                                        <FormLabel className={`auth-label redAsterisk`}>{formLabels?.booking?.pet_breed_name}</FormLabel>
                                        <TextField
                                            placeholder={formLabels?.booking?.enter_breed_name}
                                            name="breed_name"
                                            id="breed_name"
                                            value={formik.values.breed_name}
                                            onChange={(e) => formik.setFieldValue("breed_name", e.target.value)}
                                            size="small"
                                            onBlur={() => formik.setFieldTouched("breed_name", true)}
                                        />
                                        {formik.errors.breed_name && formik.touched.breed_name && <small className="text-danger">{formik.errors.breed_name}</small>}
                                    </FormControl>
                                </div>
                            )}
                            <div className=" col-12 col-sm-6 mb-2 mb-sm-1">
                                <FormControl fullWidth>
                                    <FormLabel className={`auth-label redAsterisk`}>{formLabels?.booking?.select_pet_gender}</FormLabel>
                                    {/* <Select
                                        name="gender"
                                        id="gender"
                                        value={formik.values.gender}
                                        onChange={(e) => {
                                            formik.setFieldValue("gender", e.target.value);
                                        }}
                                        onBlur={()=> formik.setFieldTouched("gender", true)}
                                        size="small"
                                    >
                                        <MenuItem disabled>Select Gender</MenuItem>
                                        {
                                            PET_GENDER_OPTIONS?.map((gender, index) => (
                                                <MenuItem key={gender.key} value={gender.key}>{gender.key}</MenuItem>
                                            ))
                                        }
                                    </Select> */}
                                    <Autocomplete
                                        disableClearable
                                        size="small"
                                        options={lang == LANGUAGES.EN ? PET_GENDER_OPTIONS : PET_GENDER_OPTIONS_JP}
                                        getOptionLabel={(option) => (option ? option.key : "")}
                                        name="gender"
                                        value={
                                            PET_GENDER_OPTIONS.find(
                                                (item) => item.value === formik.values.gender
                                            )
                                        }
                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                        onChange={(_, newValue) => {
                                            formik.setFieldValue(
                                                "gender",
                                                newValue ? newValue.value : ""
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder={formLabels?.booking?.species_select}
                                                fullWidth
                                            />
                                        )}
                                    />
                                    {formik.errors.gender && formik.touched.gender && <small className="text-danger">{formik.errors.gender}</small>}
                                </FormControl>

                            </div>
                            <div className=" col-12 col-sm-6 mb-0">
                                <FormControl fullWidth>
                                    <FormLabel className={`auth-label redAsterisk`}>{formLabels?.booking?.select_pet_neutered}</FormLabel>
                                    {/* <Select
                                        name="neutered"
                                        
                                        id="neutered"
                                        value={formik.values.neutered}
                                        onChange={(e) => {
                                            formik.setFieldValue("neutered", e.target.value);
                                        }}
                                        onBlur={()=> formik.setFieldTouched("neutered", true)}
                                        size="small"
                                    >
                                        <MenuItem disabled>Select Pet Neutered</MenuItem>
                                        {
                                            NEUTERED_OPTIONS?.map((item, index) => (
                                                <MenuItem key={item.key} value={item.key}>{item.key}</MenuItem>
                                            ))
                                        }
                                    </Select> */}
                                    <Autocomplete
                                        disableClearable
                                        size="small"
                                        options={lang == LANGUAGES.EN ? NEUTERED_OPTIONS : NEUTERED_OPTIONS_JP}
                                        getOptionLabel={(option) => (option ? option.key : "")}
                                        name="neutered"
                                        value={
                                            NEUTERED_OPTIONS.find(
                                                (item) => item.value === formik.values.neutered
                                            )
                                        }
                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                        onChange={(_, newValue) => {
                                            formik.setFieldValue(
                                                "neutered",
                                                newValue ? newValue.value : ""
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder={formLabels?.booking?.species_select}
                                                fullWidth
                                            />
                                        )}
                                    />
                                    {formik.errors.neutered && formik.touched.neutered && <small className="text-danger">{formik.errors.neutered}</small>}
                                </FormControl>
                            </div>
                        </div>
                        <div className="row mb-3">
                            {!loading && <div className="col-12">
                                <Button
                                    variant="contained"
                                    className="mt-3"
                                    size="medium"
                                    sx={{
                                        fontWeight: '600',
                                        background: btn_clinic_bg_color,
                                        width: "100%",
                                        '&:hover': {
                                            // color: btn_clinic_bg_color,
                                            backgroundColor: btn_clinic_bg_color,
                                        },
                                        textTransform: 'capitalize',
                                    }}
                                    type="submit"
                                >
                                    {formLabels?.booking?.add_pet}
                                </Button>
                                {!modal &&
                                    <Link
                                        to={"/booking"}
                                    >
                                        <Button
                                            size="medium"
                                            variant="outlined"
                                            className="mt-2 mb-0"
                                            sx={{
                                                fontWeight: '600',
                                                color: btn_clinic_bg_color,
                                                borderColor: btn_clinic_bg_color,
                                                width: "100%",
                                                '&:hover': {
                                                    borderColor: btn_clinic_bg_color,
                                                    // color: btn_clinic_bg_color,
                                                    // backgroundColor: btn_clinic_bg_color,
                                                },

                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {formLabels?.booking?.skip_for_now}
                                        </Button>
                                    </Link>
                                }
                            </div>}
                            {
                                loading &&
                                <div className="col-12">
                                    <TailSpin
                                        height="35"
                                        width="35"
                                        color={btn_clinic_bg_color}
                                        ariaLabel="tail-spin-loading"
                                        radius="2"
                                        wrapperStyle={{ marginBottom: "8px" }}
                                        wrapperClass="d-flex justify-content-center"
                                        visible={true}
                                    />
                                </div>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddPetPage;