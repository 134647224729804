import { createSlice } from "@reduxjs/toolkit";
import {
    confirmation,
    login,
    register,
    sendOtp,
    verifyEmail,
    verifyOtp,
    verifyRegistrationToken,
    forgotPassword,
    resetPasswordTokenVerify,
    resetPassword
} from "./thunk";

const InitialState = {
    
    isLoggedIn: false,
    loading: false,
    login: {
        phone: "",
        countryCode: "",
        petId: "",
        email: "",
        type: 0,
        token: ""
    },
    clientExistingEmail:[],
    loginStatus: "login",
    message: "",
    error: false,
    token: null,
    clientId: null,
    forgotPasswordSuccess: null,
    forgotPasswordError: null,
    resetPasswordSuccess: null,
    resetPasswordError: null,
    captchaToken: null,
    isInvalidScreen: false
};

const authSlice = createSlice({
    name: "Auth",
    initialState: InitialState,
    reducers: {
        setIsLoggedIn(state, action) {
            state.isLoggedIn = action.payload;
        },
        resetLogin(state, action) {
            return InitialState;
        },
        resetLoginMsg(state, action) {
            state.message = action.payload;
        },
        resetLoginError(state, action) {
            state.error = action.payload;
        },
        updateCaptchToken(state, action) {
            state.captchaToken = action.payload;
        },
        updateIsInvalidScreen(state, action) {
            state.isInvalidScreen = action.payload;
            state.isLoggedIn = false;
        }
    },
    extraReducers: (builder) => {
        // get config
        builder.addCase(confirmation.pending, (state, { meta }) => {
            const { arg } = meta;
            state.loading = true;
            state.login["phone"] = arg["contact_no"] || state.login["phone"];
            state.login["email"] = arg["email"] || state.login["email"];
            state.login["countryCode"] = arg["country_code"] || state.login["countryCode"];
            state.login["petId"] = arg["pet_id"] || state.login["petId"];
            state.login["loginType"] = arg["login_type"] || state.login["loginType"];
            state.message = null;
            state.error = null;
        })
            .addCase(confirmation.fulfilled, (state, { payload }) => {
                const { is_access, client_id } = payload.data;
                state.loading = false;
                state.message = payload?.message || state.message;
                state.loginStatus = is_access;
                state.clientId = is_access && ["existing_user", "pet_email", "reset_password"].includes(is_access) ? client_id : state.clientId;
                state.error = payload?.status
                state.login["email"] = payload?.email || state.login["email"]

            })
            .addCase(confirmation.rejected, (state, { payload }) => {
                state.loading = false;
                state.message = payload;
                state.error = true;
            })
            // send otp
            .addCase(sendOtp.pending, (state, payload) => {
                state.loading = true;
                state.message = null;
                state.error = null;
            })
            .addCase(sendOtp.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.loginStatus = payload.loginStatus;
                state.message = payload?.message || state.message;
            })
            .addCase(sendOtp.rejected, (state, { payload }) => {
                state.loading = false;
                state.message = payload;
                state.error = true;
            })
            // verify otp
            .addCase(verifyOtp.pending, (state, payload) => {
                state.loading = true;
                state.message = null;
                state.error = null;
            })
            .addCase(verifyOtp.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.loginStatus = payload.data.is_access;
                state.clientExistingEmail = payload.data.email;
                state.message = payload.message;

                // console.log("payload.status--",payload.data)
                // console.log("payload--",payload)
            })
            .addCase(verifyOtp.rejected, (state, { payload }) => {
                state.loading = false;
                state.message = payload;
                state.error = true;
            })
            // VERIFY EMAIL
            .addCase(verifyEmail.pending, (state, { meta }) => {
                const { arg } = meta;
                state.loading = true;
                state.error = false;
                state.message = null;
                state.login["email"] = arg["email"] || state.login["email"];
            })
            .addCase(verifyEmail.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.loginStatus = payload.status;
                state.message = payload.message;
            })
            .addCase(verifyEmail.rejected, (state, { payload }) => {
                state.loading = false;
                state.message = payload;
                state.error = true;
            })
            // VERIFY TOKEN
            .addCase(verifyRegistrationToken.pending, (state, payload) => {
                state.loading = true;
                state.loginStatus = "register";
                state.message = null;
                state.error = null;
            })
            .addCase(verifyRegistrationToken.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.login.phone = payload.data.contact_no;
                state.login.countryCode = payload.data.country_code;
                state.login.email = payload.data.email;
            })
            .addCase(verifyRegistrationToken.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.message = payload;
            })
            // REGISTER
            .addCase(register.pending, (state, payload) => {
                state.loading = true;
                state.message = null;
                state.error = null;
            })
            .addCase(register.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.token = payload.token;
                state.isLoggedIn = payload.token ? true : state.isLoggedIn;
            })
            .addCase(register.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.message = payload;
            })
            // LOGIN
            .addCase(login.pending, (state, payload) => {
                state.loading = true;
                state.forgotPasswordError = false;
                state.forgotPasswordSuccess = false;
                state.error = null;
                state.message = null;
            })
            .addCase(login.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.token = payload.token;
                // state.loginStatus = payload.status;
                // state.message = payload.message;
            })
            .addCase(login.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.message = payload;
            })
            // FORGOT PASSWORD
            .addCase(forgotPassword.pending, (state, payload) => {
                state.loading = true;
                state.forgotPasswordError = false;
                state.forgotPasswordSuccess = false;
                state.message = null;
                state.error = null;
            })
            .addCase(forgotPassword.fulfilled, (state, { payload }) => {

                state.loading = false;
                state.forgotPasswordSuccess = payload.message;
                state.loginStatus = payload.type === "contact" ? "otpSent" : payload.data.is_access;
                state.clientExistingEmail = payload.data.email;
                state.clientExistigCoutryCode = payload.data.country_code;
                state.clientExistingphone = payload.data.phone;
            })
            .addCase(forgotPassword.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.message = payload;
            })
            // resetPasswordTokenVerify
            .addCase(resetPasswordTokenVerify.pending, (state, payload) => {
                state.loading = true;
                // state.forgotPasswordError = false;
                // state.forgotPasswordSuccess = false;
                state.message = null;
                state.error = null;
            })
            .addCase(resetPasswordTokenVerify.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.clientId = payload.clientId;
                // state.forgotPasswordSuccess = true;
            })
            .addCase(resetPasswordTokenVerify.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload || false;
                state.message = payload;

            })
            // reset password
            .addCase(resetPassword.pending, (state, payload) => {
                state.loading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(resetPassword.fulfilled, (state, { payload }) => {

                state.loading = false;
                state.resetPasswordSuccess = payload.message;
                state.token = payload.token;

                // state.clientId = payload.clientId;
            })
            .addCase(resetPassword.rejected, (state, { payload }) => {
                state.loading = false;
                state.resetPasswordError = payload;
                state.error = true;
                state.message = payload;
            })
    },
});

export const { setIsLoggedIn, resetLogin, resetLoginMsg, resetLoginError, updateCaptchToken, updateIsInvalidScreen } = authSlice.actions;
export default authSlice.reducer;