import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function CircularIndeterminate() {
  return (
    <div
      style={{
        position: "fixed",
        zIndex: "999",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#5a5a5a78",
          height: "100%",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    </div>
  );
}
