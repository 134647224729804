import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLang } from "../Redux/config/slice";
import { getConfig } from "../Redux/config/thunk";
import { LANGUAGES } from "../Constants/Constant";

export default function useLang() {
    const config = useSelector((state) => state.config);
    const lang = (config && config.lang) || LANGUAGES.JP;
    const dispatch = useDispatch();

    const updateLang = (payload) => {
        dispatch(setLang(payload));
    }

    useEffect(() => {
        const langLocal = localStorage.getItem("lang_type") || LANGUAGES.JP;
        updateLang(langLocal)
    }, []);

    return {lang, updateLang};
}