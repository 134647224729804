export function getDayNumber(currentDate){
    let day = getDayName(currentDate);
    switch(day) {
        case 'Mon': 
            return 1;
        case 'Tue': 
            return 2;
        case 'Wed': 
            return 3;
        case 'Thu': 
            return 4;
        case 'Fri': 
            return 5;
        case 'Sat': 
            return 6;
        case 'Sun': 
            return 7;
        default: 
            return -1;
    }
}
export function getDayName(dateStr){
    const dayOfWeek = new Date(dateStr).toString().split(' ')[0];
    return dayOfWeek
}