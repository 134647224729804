import React, { useEffect, useState } from "react";
import moment from "moment";
import TimeSlots from "./TimeSlots";
import StepHeader from "../StepHeader/StepHeader";
import { useDispatch, useSelector } from "react-redux";
import { Images } from "../../Constants/Images";
import MobileVersionTimeSlots from "./MobileVersionTimeSlots";
import { useTranslation } from 'react-i18next';
import "./Calendar.css";
import { setCalendarWeek, setSelectDate } from "../../Redux/calendar/slice";
import { getSlots } from "../../Redux/calendar/thunk";
import useCustomTheme from "../../hooks/useCustomTheme";
import "../../Constants/ja"
import 'moment-timezone';
import useCurrentLanguage from "../../hooks/useCurrentLanguage";
import { LANGUAGES } from "../../Constants/Constant";

const CustomCalendar = ({ dates, selected, navTab, timeTabSelected }) => {

  const lang = useCurrentLanguage();
  // moment().localeData()
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const CalendarUpdate = useSelector((state) => state.calendar);
  const frontLabels = t("data.frontLabels", { returnObjects: true });
  const doctorReducer = useSelector(state => state.doctor);
  const facilityReducer = useSelector(state => state.facility);
  const serviceReducer = useSelector(state => state.service);
  const calendarReducer = useSelector(state => state.calendar);
  const config = useSelector(state => state.config);
  const { steps } = config || { steps: 6 };
  const { doctor } = doctorReducer;
  const { facility } = facilityReducer;
  const { service } = serviceReducer;
  const { week } = calendarReducer;


  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => setWidth(window.innerWidth);
  const isMobile = width <= 568;

  useEffect(() => {
    renderWeek();
  }, [navTab, timeTabSelected])

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };

  }, []);



  // const renderWeek = (selectedDate) => {
  //   if (!selectedDate) {
  //     selectedDate = new Date();
  //   }
  //   let momentSelectedDate = moment(selectedDate);
  //   const weekData = [];
  //   for (let index = 0; index < 7; index++) {
  //     const day = {};
  //     day["day"] = momentSelectedDate.format("dddd");
  //     day["monthDate"] = momentSelectedDate.format("MMM D");
  //     day["date"] = momentSelectedDate.format("YYYY-MM-DD");
  //     weekData.push(day);
  //     momentSelectedDate = moment(momentSelectedDate).add(1, "d");
  //   }
  // }
  const renderWeek = (selectedDate, lang = LANGUAGES.JP) => {
    if (!selectedDate) {
      selectedDate = new Date();
    }
    moment.locale(lang);

    let momentSelectedDate = moment(selectedDate);
    const weekData = [];

    for (let index = 0; index < 7; index++) {
      const day = {};
      day["day"] = momentSelectedDate.format("dddd");
      day["monthDate"] = momentSelectedDate.format("MMM D");
      day["date"] = momentSelectedDate.format("YYYY-MM-DD");
      weekData.push(day);
      momentSelectedDate = moment(momentSelectedDate).add(1, "d");
    }
  };

  const selectedDate = CalendarUpdate.selectDate;
  renderWeek(selectedDate, lang);

  const setWeekData = (selectedDate) => {
    if (!selectedDate) {
      selectedDate = new Date();
    }
    let momentSelectedDate = moment(selectedDate);
    const weekData = [];
    for (let index = 0; index < 7; index++) {
      const day = {};
      day["day"] = momentSelectedDate.format("dddd");
      day["monthDate"] = momentSelectedDate.format("MMM D");
      day["monthDate_jp"] = momentSelectedDate.format("MMM Do");
      day["date"] = momentSelectedDate.format("YYYY-MM-DD");
      weekData.push(day);
      momentSelectedDate = moment(momentSelectedDate).add(1, "d");
    }
    const payload = {
      week: weekData,
      selectedDateIndex: 0 //calendar?.selected_date_index || 0
    }
    dispatch(setCalendarWeek(payload))

    const payloado = {
      clinicId: facility.id,
      serviceId: service.id,
      date: weekData[0]["date"],

      selectDate: weekData[0]["date"],
      scheduleType: "in_person"
    }
    if (doctor) payloado["providerId"] = doctor?.user_id === "Any" ? doctor?.user_id?.toLowerCase() : doctor?.user_id || ""
    dispatch(getSlots(payloado));
  }

  const nextWeek = () => {
    const weekLastDate = moment(week[6]["date"]);
    const selectdedDate = weekLastDate.add(1, "d");
    setWeekData(selectdedDate);
  };
  const previousWeek = () => {
    const currentDate = CalendarUpdate.selectDate;
    const currentDateMoment = moment(currentDate).format("YYYY-MM-DD");
    const weekLastDate = moment(week[0]["date"]);
    if (moment(weekLastDate).isSame(currentDateMoment)) {
      return; // BLOCK PREVIOUS DATE BOOKING
    }
    const selectdedDate = weekLastDate.subtract(7, "d");
    setWeekData(selectdedDate);
  };
  return (
    <>
      <div className="calendar m-0 p-0 time-slot-area height-100 " id="calender">
        <span className="hide-for-mobile">
          <StepHeader
            title={`${service && service?.service_for === "provider" ? "05" : "04"}. ${frontLabels?.booking?.select_preferred_date_slot}`}
            step={`${frontLabels?.booking?.STEP} ${service?.service_for === "provider" ? "05" : "04"} ${frontLabels?.booking?.OF} 0${steps}`}
          />
        </span>
        <div className="slot-bg-color">
          <div className="d-flex justify-content-between hide-for-mobile margin-top-1-875rem padding-top-3-rem margin-zero padding-0-5rem padding-zero-tab">
            <div className="prev-button margin-zero">
              <button className={`action-button font-size-0-75 noto-font d-flex align-items-center ${moment().format("YYYY-MM-DD") === week?.[0].date && `disabled`}`} onClick={previousWeek}>
                <img src={Images.left_icon} alt="left" /> <span className="margin-left-10px"> {lang === LANGUAGES.EN ? frontLabels?.booking?.prev_7 : frontLabels?.booking?.previous}</span>
              </button>
            </div>

            <div className="d-flex flex-column align-items-center noto-font">
              <div className="d-flex justify-content-between">

                <div className="action-button text-area font-size-0-75">
                  {week ? moment(week[0]["date"]).format("MMMM") : ""}
                </div>
              </div>
            </div>

            <div className="next-buttuon margin-zero noto-font">
              <button className="action-button font-size-0-75 d-flex align-items-center" onClick={nextWeek}>
                <span className="margin-right-10px">{lang === LANGUAGES.EN ? frontLabels?.booking?.next_7 : frontLabels?.booking?.ancr_next}</span><img src={Images.right_icon} alt="right" />
              </button>
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-cl border-line hide-for-mobile margin-top-0-675rem initial-justify overflow-mob day-slot overflow-x-scroll-tab">
            <RenderWeek setWeekData={setWeekData} timeTabSelected={timeTabSelected} />
          </div>
          {!isMobile ? <TimeSlots navTab={navTab} timeTabSelected={timeTabSelected} /> : <MobileVersionTimeSlots />}
        </div>
      </div>
    </>
  );
};

export default React.memo(CustomCalendar);

export const RenderWeek = (props) => {
  const { t } = useTranslation();
  const lang = useCurrentLanguage();
  const frontLabels = t("data.frontLabels", { returnObjects: true });
  const {
    activeClass
  } = useCustomTheme();
  const calendarReducer = useSelector(state => state.calendar);
  const { timeSlots, week, selectedDateIndex, disabledDates } = calendarReducer;
  const doctorReducer = useSelector(state => state.doctor);
  const { doctor, loading } = doctorReducer;
  const facilityReducer = useSelector(state => state.facility);
  const { facility } = facilityReducer;
  const serviceReducer = useSelector(state => state.service);
  const { service } = serviceReducer;

  const dispatch = useDispatch();
  const [dateSelection, setDateSelection] = useState(0);

  useEffect(() => {
    if (selectedDateIndex === -1) {
      props.setWeekData();
    } 

  }, [props.timeTabSelected])

  const handleChangeDate = (data, index) => {
    console.log("data", data);
    moment.tz.setDefault('Asia/Tokyo');
    const currentTimeInJapan = moment().format();
    console.log(currentTimeInJapan);
    const payload = {
        clinicId: facility.id,
        serviceId: service.id,
        date: data["date"],
        selectedDateIndex: index,
        selectDay: moment(data["date"]).format("dddd"),
        selectDate: data["date"],
        providerId: doctor?.user_id === "Any" ? doctor?.user_id?.toLowerCase() : doctor?.user_id || "",
        scheduleType: "in_person"
    }
    const tokyoDateTime = moment.tz(payload.date, 'Asia/Tokyo');
    payload.date = tokyoDateTime.format("YYYY-MM-DD");
    dispatch(setSelectDate(payload.date))
    dispatch(getSlots(payload));
}

  return (
    <>
      {week?.map((item, index) => {

        const currentDate = moment(new Date()).format("YYYY-MM-DD");
        let day_date_format = "";
        let day_date_format_jp = "";
        if (moment(item.date).isSame(moment(currentDate))) {
          // today
          day_date_format = frontLabels?.booking?.today || "Today";
          day_date_format_jp = frontLabels?.booking?.today || "Today";

        } else if (moment(item.date).isSame(moment(currentDate).add(1, "d"))) {
          // tomorrow
          day_date_format = frontLabels?.booking?.tomorrow || "Tomorrow";
          day_date_format_jp = frontLabels?.booking?.tomorrow || "Tomorrow";
        } else {
          // handle date
          day_date_format = item.day.slice(0, 3) + ", " + item.monthDate;
          day_date_format_jp = item.monthDate_jp + " " + item.day
        }
        // handle yesterday
        const disabledDateClass = disabledDates && disabledDates.length > 0 && disabledDates.includes(item.date) ? "disabled" : "";
        return (
          <div
            key={`index=${index}`}
            className={`d-flex flex-column align-items-center justify-content-center px-3 days-slot pointer-icon ${disabledDateClass} text-nowrap`}
            style={selectedDateIndex === index ? activeClass : {}}
            onClick={() => {
              handleChangeDate(item, index)
              setDateSelection(index)
            }}
          >
            <div className={`day-date-section noto-font session-text-color ${selectedDateIndex === index ? "select-days" : ""}`}  >
              {lang === LANGUAGES.JP ? day_date_format_jp : day_date_format
              }
            </div>
            {(selectedDateIndex === index && selectedDateIndex !== -1) &&
              <div
                className={`slots-section noto-font mt-1 ${(selectedDateIndex === index) ? "select-days" : ""
                  }`}
              >
                {frontLabels?.booking?.available_slots} {selectedDateIndex === index && timeSlots?.length}
              </div>
            }
          </div>
        )
      }
      )}
    </>
  )
}
