import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { verifyRegistrationToken } from "../Redux/auth/thunk";

function useQuery(str) {
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth);
    const {loginStatus} = auth;

    useEffect(() => {
        if (!str && loginStatus !== "resetPassword") {
            navigate("/auth/login");
        }
    }, [str, loginStatus])
    
    const data = str.slice(1).split("&").reduce((acc, curr) => {
        const [key, value] = curr.split("=")
        acc[key] = value;
        return acc;
    }, {});

    return {
        data,
        error: false
    }
}

export default useQuery;