
import { useTranslation } from "react-i18next";
import * as Yup from "yup";


export const ValidationSchema = () => {
  const { t } = useTranslation();
  return Yup.object({
    lastName: Yup.string().min(2).max(25).required(t("data.frontMessages.LAST_NAME_REQ")),
    firstName: Yup.string()
      .min(2)
      .max(25)
      .required(t("data.frontMessages.BOOK_CTL_FIRSTNAME_REQ")),
    email: Yup.string().email().required(t("data.frontMessages.BOOK_CTL_EMAIL_REQ")),
    description: Yup.string().required(t("data.frontMessages.NOTE_ID_REQ")),
    selectPet: Yup.string().required(t("data.frontMessages.BOOK_CTL_PET_REQ")),
    petName: Yup.string().required(t("data.frontMessages.BOOK_CTL_PET_NAME_SOME")),
    petBirthDate: Yup.date(t("data.frontMessages.PET_DOB_REQ"))
      .typeError(t("data.frontMessages.PET_DOB_REQ"))
      .required(t("data.frontMessages.PET_DOB_REQ")),
    selectPetSpecies: Yup.string().required(t("data.frontMessages.BOOK_CTL_PET_SPECIES_SOME")),
    selectPetBreed: Yup.string().required(t("data.frontMessages.BOOK_CTL_PET_BREED_SOME")),
    selectPetGender: Yup.string().required(t("data.frontMessages.PET_GENDER_REQ")),
    selectPetNeutered: Yup.string().required(t("data.frontMessages.PET_NEUTERED_REQ")),
  });
}

export const EmailFormValidation = () => {
  const { t } = useTranslation();
  return Yup.object({
    // email: Yup.string().email(t("data.frontMessages.BOOK_CTL_EMAIL_ADDR_VALID")).required(t("data.frontMessages.BOOK_CTL_EMAIL_REQR"))
    email: Yup.string().email(t("data.frontMessages.BOOK_CTL_EMAIL_ADDR_VALID")).required(t("data.frontMessages.BOOK_CTL_EMAIL_REQ"))
  })
}

export const RegisterFormValidation = () => {
  const { t } = useTranslation();
  return Yup.object({
    password: Yup.string().required(t("data.frontMessages.PASS_REQ")).min(6, t("data.frontMessages.MIN_PASSWORD")),
    password_confirmation: Yup.string().required(t("data.frontMessages.CONF_PASS_REQ")).oneOf([Yup.ref('password'), null], t("data.frontMessages.PASS_MUST_MATCH")).min(6, t("data.frontMessages.CNF_MIN_PASSWORD")),
    first_name: Yup.string().required(t("data.frontMessages.BOOK_CTL_FIRSTNAME_REQ")),
    last_name: Yup.string().required(t("data.frontMessages.BOOK_CTL_LASTNAME_REQ"))
  })
}

export const AddPetValidation = () => {
  const { t } = useTranslation();
  return Yup.object({
    name: Yup.string().required(t("data.frontMessages.BOOK_CTL_PET_NAME_SOME")),
    breed_name: Yup.string().test({
      name: 'conditionalRequired',
      exclusive: true,
      message: t("data.frontMessages.BOOK_CTL_PET_BREED_SOME"),
      test: function (value) {
        const breedValue = this.parent.breed;
        return breedValue === "0" ? !!value : true;
      },
    }),
    dob: Yup.string().required(t("data.frontMessages.BOOK_CTL_PET_DOB_SOME")),
    dobState: Yup.string().required(),
    species: Yup.string().required(t("data.frontMessages.BOOK_CTL_PET_SPECIES_SOME")),
    breed: Yup.string().required(t("data.frontMessages.BOOK_CTL_PET_BREED_SOME")),
    gender: Yup.string().required(t("data.frontMessages.BOOK_CTL_PET_GENDER_SOME")),
    neutered: Yup.string().required(t("data.frontMessages.BOOK_CTL_PET_NEUTERED_REQ")),
  })
}



export const LoginPassword = () => {
  const { t } = useTranslation();
  return Yup.object({
    password: Yup.string().required(`${t("data.frontMessages.PASS_REQ")}`),
  })
}

export const LoginValidation = () => {

  const { t } = useTranslation();
  return Yup.object().shape({
    phone: Yup.string().when(["petId", "email"], {
      is: (a, b) => {
        return !a && !b
      },
      then: () => Yup.string().required(`${t("data.frontMessages.BOOK_CTL_PHONE_REQ")}`),
    }),
    petId: Yup.string().when(["phone", "email"], {
      is: (a, b) => {
        return !a && !b
      },
      then: () => Yup.string().required(`${t("data.frontMessages.BOOK_CTL_PET_ID_REQ")}`),
    }),
    email: Yup.string().when(["phone", "petId"], {
      is: (a, b) => {
        return !a && !b
      },
      then: () => Yup.string().required(`${t("data.frontMessages.BOOK_CTL_EMAIL_ADDR_REQ")}`),
    })
  }, [["phone", "petId"], ["petId", "email"], ["phone", "email"]]);
}


// export const contactNumberFormValidation = Yup.object({
//   phone: Yup.string().required("Contact number is required.")
// })

// export const petIdFormValidation = Yup.object({
//   petId: Yup.string().required("Pet Id is required.")
// })
// export const ResetPasswordValidation=() =>{ 
//   const {t} = useTranslation();
//   const data = t("data", { returnObjects: true });
//  return Yup.object({
//   password: Yup.string().required(data?.frontMessages?.BOOK_CTL_PASSWORD_REQ),
//   confirmPassword: Yup.string().required(data?.frontMessages?.CONFIRM_PASSWORD).oneOf([Yup.ref("password"), null], (data?.frontMessages?.CONFIRM_PASSWORD)),
// })}