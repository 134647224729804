import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConfig } from "../Redux/config/thunk";
import useCurrentLanguage from "./useCurrentLanguage";

export default function useConfig() {
    const config = useSelector((state) => state.config);
    const dispatch = useDispatch();

    useEffect(() => {
        if (config && config.footer && config.lang && config.footer[`lp_heading_text_${config.lang}`]) {
            document.title = config.footer[`lp_heading_text_${config.lang}`];
        }
    }, [config])
    
    const langType = useCurrentLanguage();
    const _getConfig = () => {
        dispatch(getConfig(langType));
    };
    return {_getConfig, config};
}