import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import useCustomTheme from "../../hooks/useCustomTheme";
import ReplayIcon from '@mui/icons-material/Replay';
import useTimer from "../../hooks/useTimer";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword, sendOtp, verifyEmail, verifyOtp } from "../../Redux/auth/thunk";
import { TailSpin } from "react-loader-spinner";
import { resetLogin } from "../../Redux/auth/slice";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from 'react-router-dom';
import { TYPE } from "./ForgotPasswordPage";
import useCurrentLanguage from "../../hooks/useCurrentLanguage";
import { LANGUAGES } from "../../Constants/Constant";

const OtpField = styled(TextField)(({ theme }) => ({
    // backgroundColor:"red",

}))

let currentOtpIndex;
function OtpForm() {

    const {
        btn_clinic_bg_color,
    } = useCustomTheme();
    const dispatch = useDispatch();
    const location = useLocation();
    const auth = useSelector(state => state.auth);
    const { loading, forgotPasswordSuccess, clientId, loginStatus, error, message, clientExistingEmail } = auth;
    const [otpError, setOtpError] = useState(false);
    const otpInitialState = new Array(6).fill("")
    const [otp, setOtp] = useState(otpInitialState);
    const [activeIndex, setActiveIndex] = useState(0);
    const { timer, timerStatus, startTimer, endTimer } = useTimer();
    const { t } = useTranslation();
    const headerLabels = t("data.frontLabels.header", { returnObjects: true });
    const contactLabels = t("data.frontLabels.contact", { returnObjects: true });
    const frontMessages = t("data.frontMessages", { returnObjects: true });
    const bookingtLabels = t("data.frontLabels.booking", { returnObjects: true });
    const isForgotPage = location.pathname === '/auth/forgot-password';



    const onHandleEnterOtp = (e) => {
        const { value } = e.target;
        var reg = new RegExp(/^\d+$/);
        if (!reg.test(value)) return;
        const newOtp = [...otp];
        newOtp[currentOtpIndex] = value.substring(value.length - 1);

        if (!value) setActiveIndex(currentOtpIndex - 1);
        else setActiveIndex(currentOtpIndex + 1)

        setOtp(newOtp);
    }

    const onHandleKeyDown = (e, index) => {
        currentOtpIndex = index;
        if (e.key === "Backspace") {
            const newOtp = [...otp];
            newOtp[currentOtpIndex] = "";

            setOtp(newOtp);
            setActiveIndex(currentOtpIndex - 1);
        }
    }

    const onHandleSubmitOTP = (e) => {
        e.preventDefault();
        const status = otp.reduce((acc, curr) => {
            if (curr && acc) return true;
            else return false
        }, true);
        if (!status) {
            setOtpError(true);
            return;
        }
        const payload = {
            contact_no: auth.login.phone,
            country_code: auth.login.countryCode,
            otp: otp.join(""),
            type: forgotPasswordSuccess ? "resetPassword" : ""
        }
        dispatch(verifyOtp(payload)).then((res) => {
            if ((res?.payload?.status === "email_exist" || res?.payload?.status === "success") && res?.payload?.statuscode === 200) {
                const verifyEmailPayload = {
                    contact_no: auth.login.phone,
                    country_code: auth.login.countryCode,
                    email: res?.payload?.data?.email,
                }
                if (!isForgotPage) {
                    dispatch(verifyEmail(verifyEmailPayload));
                } else {
                    const forgotpayload = {
                        client_id: clientId,
                        login_type: TYPE[1]
                    }
                    // Assuming forgotpayload is defined somewhere
                    dispatch(forgotPassword(forgotpayload));
                }
            }
        });
    }

    const onHandleResendOtp = () => {
        if (!timerStatus) {
            startTimer();
            const payload = {
                contact_no: auth.login.phone,
                country_code: auth.login.countryCode,
            }
            setOtp(otpInitialState)
            dispatch(sendOtp(payload));
        }
    }

    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => setWidth(window.innerWidth);
    const isMobile = width <= 568;

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };

    }, []);

    useEffect(() => {
        startTimer();

        return () => {
            endTimer();
        }
    }, []);
    const lang = useCurrentLanguage();
    return (
        <>
            <div className="text-center otp-main-container">
                <h1>{headerLabels?.otp_verification}</h1>
                {
                    lang === LANGUAGES.JP ? 
                        <div className="otp-mobile-hint">
                            {`${frontMessages?.SMS_code_send_jp} ${auth?.login?.countryCode} ${auth?.login?.phone.slice(0, 3)}** ${auth?.login?.phone.slice(5, 7)}*** ${frontMessages?.SMS_code_send_jp_last_part}`}
                        </div>
                        :
                        <p className="otp-mobile-hint">
                        {`${frontMessages?.SMS_code_send} ${auth?.login?.countryCode} ${auth?.login?.phone.slice(0, 3)}** ${auth?.login?.phone.slice(5, 7)}***`}
                    </p>
                }



                <div className="m-auto">
                    <form
                        onKeyDown={e => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                                onHandleSubmitOTP();
                            }
                        }}
                    >
                        <div className="otp-containter d-flex  mx-4 ">
                            {
                                otp.map((_, index) => (
                                    <OtpField
                                        key={`one-${index}`}
                                        inputRef={input => {
                                            if (index === activeIndex && input) {
                                                input.focus()
                                            }
                                        }}
                                        type="number"
                                        autocomplete="off"
                                        variant="outlined"
                                        value={otp[index]}
                                        onChange={(e) => onHandleEnterOtp(e, index)}
                                        onKeyDown={(e) => onHandleKeyDown(e, index)}
                                        inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                    />
                                ))
                            }
                        </div>
                        <div className="text-end mx-4 mt-2 d-flex align-items-center justify-content-between">
                            <span>
                                {otpError && <small className="text-danger">
                                    {frontMessages?.BOOK_CTL_OTP_REQ}
                                </small>}
                            </span>
                            <span>


                                <small
                                    className={`text-primary ${timerStatus && `disabled`}`}
                                    style={{ cursor: "pointer" }}
                                    onClick={onHandleResendOtp}
                                >

                                    {bookingtLabels?.re_send}
                                </small>
                                &nbsp;
                                <small>00:{timer < 10 ? `0${timer}` : timer}</small>
                            </span>
                        </div>
                        <div className="mx-4">
                            {!loading ?
                                <button
                                    className="mt-4 auth-submit-btn"
                                    style={{
                                        background: btn_clinic_bg_color,
                                        color: "#fff",
                                        width: "100%",
                                    }}
                                    onClick={onHandleSubmitOTP}
                                >
                                    {contactLabels?.ancr_submit_otp}
                                </button> :
                                <TailSpin
                                    height="35"
                                    width="35"
                                    color={btn_clinic_bg_color}
                                    ariaLabel="tail-spin-loading"
                                    radius="2"
                                    wrapperStyle={{}}
                                    wrapperClass="d-flex justify-content-center"
                                    visible={true}
                                />
                            }
                            {!loading &&
                                <button
                                    className="mt-2 auth-secondary-btn"
                                    style={{
                                        width: "100%"
                                    }}
                                    onClick={() => {
                                        dispatch(resetLogin())
                                    }}
                                >
                                    {bookingtLabels?.go_back}
                                </button>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default OtpForm;

