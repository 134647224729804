import {
  PREFERRED_FACILITY,
  PREFERRED_SERVICE,
  PREFERRED_DOCTOR,
  PREFERRED_DAY_DATE,
  PREFERRED_TIMING,
  COMBINE_REDUCER,
  PREFERRED_TIMING_CARD,
  START_LOADING,
  STOP_LOADING,
  PREFERRED_CARD_DOCTOR,
  PREFERRED_CARD_FACILITY,
  PREFERRED_CARD_SERVICE,
  SPECIES_DATA,
  BREEDS_DATA,
  CLIENT_CONTACT_INFO,
  OTP_VERIFICATION,
  CLIENT_INFO,
  PET_INFO,
  SHOW_TOASTER,
  PREFERRED_DOCTOR_RESPONSE,

  SET_FACILITY,
  GET_FACILITIES,
  GET_FACILITIES_SUCCESS,
  GET_FACILITIES_FAILED,

  GET_SERVICES,
  GET_SERVICES_SUCCESS,
  GET_SERVICES_FAILED,

  GET_DOCTORS,
  GET_DOCTORS_SUCCESS,
  GET_DOCTORS_FAILED,

  GET_SLOTS,
  GET_SLOTS_FAILED,
  GET_SLOTS_SUCCESS,
  SET_SLOT,
  SET_CALENDAR,
  
  SEND_OTP,
  SEND_OTP_FAILED,
  SEND_OTP_SUCCESS,

  CONFIRM_OTP,
  CONFIRM_OTP_FAILED,
  CONFIRM_OTP_SUCCESS,

  GET_PETS,
  GET_PETS_FAILED,
  GET_PETS_SUCCESS,

  SUBMIT_APPOINTMENT,
  SUBMIT_APPOINTMENT_FAILED,
  SUBMIT_APPOINTMENT_SUCCESS,

  SET_CLIENT_PHONE,
  SET_USER_ACTION,

  RESCHEDULE_APPOINTMENT,
  RESCHEDULE_APPOINTMENT_FAILED,
  RESCHEDULE_APPOINTMENT_SUCCESS
} from "../ActionType/Constants";

// const anyDoctorData = {
//   user_id: "any",
//   kana_firstname: "Doctor",
//   kana_lastname: "Any",
//   lastname: "Any",
// };
const speciesItem = { species_en: "Select", id: "" };
const breedsItem = { name_en: "Select", id: "" };
const petItem = { kana_name: "My pet", name: "" }
export const FacilityAction = (facilityData) => (dispatch) => {
  dispatch({
    type: PREFERRED_FACILITY,
    payload: facilityData,
  });

  dispatch(
    ServiceAction({
      select_service: null,
      selected_item: { "Select Service": "" },
    })
  );
};

export const FacilityActionData = (facilityData) => {
  return {
    type: PREFERRED_CARD_FACILITY,
    payload: facilityData,
  };
};

export const ServiceAction = (serviceData) => (dispatch) => {
  dispatch({
    type: PREFERRED_SERVICE,
    payload: serviceData,
  });
};

export const ServiceActionData = (serviceData) => {
  return {
    type: PREFERRED_CARD_SERVICE,
    payload: serviceData,
  };
};

export const DoctorAction = (doctorsData) => (dispatch) => {
  dispatch({
    type: PREFERRED_DOCTOR,
    payload: doctorsData?.select_doctor,
  });

  dispatch({
    type: COMBINE_REDUCER,
    payload: {
      "Select Doctor": doctorsData?.selected_item?.["Select Doctor"],
    },
  });
};

export const DoctorActionData = (doctorData) => {
  // doctorData?.splice(0,0,anyDoctorData);
  return {
    type: PREFERRED_CARD_DOCTOR,
    payload: doctorData,
  };
};
export const DoctorActionResponseType = (doctorResponse) => {
  return {
    type: PREFERRED_DOCTOR_RESPONSE,
    payload: doctorResponse,
  };
};

export const CalendarAction = (dayDateData) => (dispatch) => {
  dispatch({
    type: PREFERRED_DAY_DATE,
    payload: dayDateData,
  });
  dispatch({
    type: COMBINE_REDUCER,
    payload: {
      "Select Slot": dayDateData?.select_date,
    },
  });
  dispatch({
    type: PREFERRED_TIMING,
    payload: {
      ...dayDateData,
      morning_index: -1,
      noon_index: -1,
      evening_index: -1,
      full_date: dayDateData?.full_date,
    },
  });
};

export const TimingAction = (timingData) => (dispatch) => {
  dispatch({
    type: PREFERRED_TIMING,
    payload: timingData,
  });
};

export const TimeSlotData = (timeSlotsData) => (dispatch) => {
  dispatch({
    type: PREFERRED_TIMING_CARD,
    payload: timeSlotsData,
  });
};

export const OtpVerification = (verificationStatus) => (dispatch) => {
  dispatch({
    type: OTP_VERIFICATION,
    payload: verificationStatus,
  });
  dispatch({
    type: COMBINE_REDUCER,
    payload: {
      "OTP Verification": verificationStatus,
    },
  });
};

export const StartLoading = () => {
  return {
    type: START_LOADING,
  };
};

export const StopLoading = () => {
  return {
    type: STOP_LOADING,
  };
};

export const SpeciesList = (speciesData) => {
  // console.log("LINE 130", speciesData);
  return {
    type: SPECIES_DATA,
    payload: [speciesItem, ...speciesData],
  };
};

export const BreedList = (breedsData) => {
  // console.log("LINE 138", breedsData);
  return {
    type: BREEDS_DATA,
    payload: [breedsItem, ...breedsData],
  };
};

export const clietInfoData = (clietData) => {
  // console.log('LINE 165', clietData);
  return {
    type: CLIENT_INFO,
    payload: clietData,
  }
}

export const petInfoData = (petData) => {
  // console.log('LINE 174', petData);
  return {
    type: PET_INFO,
    payload: [petItem, ...petData],
  }
}

export const SetContactList = (contactData) => ({
  type: CLIENT_CONTACT_INFO,
  payload: contactData,
});

export const showToaster = (toasterData) => {
  return {
    type: SHOW_TOASTER,
    payload: toasterData,
  }
};

// export const FacilityAction = (facilityData) => {
//   return async (dispatch) => {
//     dispatch({
//       type: PREFERRED_FACILITY,
//       payload: facilityData,
//     });
//     dispatch(
//       ServiceAction({
//         select_service: null,
//         selected_item: { "Select Service": "" },
//       })
//     );
//   };
// };
//  -- N E W   I M P L E M E N T A T I O N
export const setFacility = (payload) => {
  return {
    type: SET_FACILITY,
    payload
  }
}

export const getFacilities = (payload) => {
  return {
    type: GET_FACILITIES,
    payload
  }
}

export const getFacilitiesSuccess = (payload) => {
  return {
    type: GET_FACILITIES_SUCCESS,
    payload
  }
}

export const getFacilitiesFailed = (payload) => {
  return {
    type: GET_FACILITIES_FAILED,
    payload
  }
}

export const getServices = (payload) => {
  return {
    type: GET_SERVICES,
    payload
  }
}

export const getServicesSuccess = (payload) => {
  return {
    type: GET_SERVICES_SUCCESS,
    payload
  }
}

export const getServicesFailed = (payload) => {
  return {
    type: GET_SERVICES_FAILED,
    payload
  }
}

export const getDoctors = (payload) => {
  return {
    type: GET_DOCTORS,
    payload
  }
}

export const getDoctorsSuccess = (payload) => {
  return {
    type: GET_DOCTORS_SUCCESS,
    payload
  }
}

export const getDoctorsFailed = (payload) => {
  return {
    type: GET_DOCTORS_FAILED,
    payload
  }
}

export const getSlots = (payload) => {
  return {
    type: GET_SLOTS,
    payload
  }
}

export const getSlotsSuccess = (payload) => {
  return {
    type: GET_SLOTS_SUCCESS,
    payload
  }
}

export const getSlotsFailed = (payload) => {
  return {
    type: GET_SLOTS_FAILED,
    payload
  }
}

export const setSlot = (payload) => {
  return {
    type: SET_SLOT,
    payload
  }
}

export const setCalendar = (payload) => {
  return {
    type: SET_CALENDAR,
    payload
  }
}

export const sendOtp = (payload) => {
  return {
    type: SEND_OTP,
    payload
  }
}

export const sendOtpSuccess = (payload) => {
  return {
    type: SEND_OTP_SUCCESS,
    payload
  }
}

export const sendOtpFailed = (payload) => {
  return {
    type: SEND_OTP_FAILED,
    payload
  }
}

export const confirmOtp = (payload) => {
  return {
    type: CONFIRM_OTP,
    payload
  }
}

export const confirmOtpSuccess = (payload) => {
  return {
    type: CONFIRM_OTP_SUCCESS,
    payload
  }
}

export const confirmOtpFailed = (payload) => {
  return {
    type: CONFIRM_OTP_FAILED,
    payload
  }
}

export const getPets = (payload) => {
  return {
    type: GET_PETS,
    payload
  }
}

export const getPetsSuccess = (payload) => {
  return {
    type: GET_PETS_SUCCESS,
    payload
  }
}

export const getPetsFailed = (payload) => {
  return {
    type: GET_PETS_FAILED,
    payload
  }
}

export const submitAppointment = (payload) => {
  return {
    type: SUBMIT_APPOINTMENT,
    payload
  }
}

export const submitAppointmentSuccess = (payload) => {
  return {
    type: SUBMIT_APPOINTMENT_SUCCESS,
    payload
  }
}

export const submitAppointmentFailed = (payload) => {
  return {
    type: SUBMIT_APPOINTMENT_FAILED,
    payload
  }
}

export const setClientPhone = (payload) => {
  return {
    type: SET_CLIENT_PHONE,
    payload
  }
}

export const setUserAction = (payload) => {
  return {
    type: SET_USER_ACTION,
    payload
  }
}

// export const rescheduleAppointment = (payload) => {
//   return {
//     type: RESCHEDULE_APPOINTMENT,
//     payload
//   }
// }

export const rescheduleAppointmentSuccess = (payload) => {
  return {
    type: RESCHEDULE_APPOINTMENT_SUCCESS,
    payload
  }
}

export const rescheduleAppointmentFailed = (payload) => {
  return {
    type: RESCHEDULE_APPOINTMENT_FAILED,
    payload
  }
}
// export const SET_FACILITY = () => {}

// export const GET_SERVICES = () => {}

// export const GET_SERVICES_SUCCESS = () => {}

// export const SET_SERVICE = () => {}

// export const GET_DOCTORS = () => {}

// export const GET_DOCTORS_SUCCESS = () => {}

// export const SET_DOCTOR = () => {}

// export const 