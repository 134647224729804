import moment from 'moment';
import { LANGUAGES } from './Constant';
 
moment.defineLocale(LANGUAGES.JP, {
    months: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
    monthsShort: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
    monthsParseExact: true,
    weekdays: '日曜日_月曜日_火曜日_水曜日_木曜日_金曜日_土曜日'.split('_'),
    weekdaysShort: '日_月_火_水_木_金_土'.split('_'),
    weekdaysMin: '日_月_火_水_木_金_土'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'YYYY/MM/DD',
        LL: 'YYYY年M月D日',
        LLL: 'YYYY年M月D日 HH:mm',
        LLLL: 'YYYY年M月D日 dddd HH:mm'
    },
    calendar: {
        sameDay: '[今日] LT',
        nextDay: '[明日] LT',
        nextWeek: 'dddd [に] LT',
        lastDay: '[昨日] LT',
        lastWeek: 'dddd [に] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: '%s後',
        past: '%s前',
        s: '数秒',
        m: '1分',
        mm: '%d分',
        h: '1時間',
        hh: '%d時間',
        d: '1日',
        dd: '%d日',
        M: '1ヶ月',
        MM: '%dヶ月',
        y: '1年',
        yy: '%d年'
    },
    dayOfMonthOrdinalParse: /\d{1,2}(日|月|日)/,
    ordinal: function (number) {
        console.log(" enettede---" , number + (number === 1 ? '日' : '日'));
        return number + (number === 1 ? '日' : '日');
    },
    meridiemParse: /午前|午後/,
    isPM: function (input) {
        return input.charAt(0) === '午後';
    },
    meridiem: function (hours, minutes, isLower) {
        return hours < 12 ? '午前' : '午後';
    },
    week: {
        dow: 0, // Sunday is the first day of the week.
        doy: 6  // Used to determine the first week of the year.
    }
});



moment.defineLocale(LANGUAGES.EN, {
    months: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
    monthsShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
    monthsParseExact: true,
    weekdays: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_'),
    weekdaysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
    weekdaysMin: 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'YYYY/MM/DD',
        LL: 'YYYY MMM DD', // Year, month, day
        LLL: 'YYYY/MM/DD HH:mm',
        LLLL: 'YYYY/MM/DD dddd HH:mm',
    },
    dayOfMonthOrdinalParse: /\d{1,2}(st|nd|rd|th)/,
    ordinal: function (number) {
        var b = number % 10,
            output = (~~(number % 100 / 10) === 1) ? 'th' :
            (b === 1) ? 'st' :
            (b === 2) ? 'nd' :
            (b === 3) ? 'rd' : 'th';
        return number + output;
    },
    // Other configurations...
});