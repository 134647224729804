import { useEffect } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import Loader from "../components/Loader";
import PageNotFound from "../components/PageNotFound/PageNotFound";
import useConfig from "../hooks/useConfig";
import AddPetPage from "../pages/addPet/AddPetPage";
import AppointmentForm from "../pages/appointmentBooking/AppointmentForm";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import Home from "../pages/home/Home";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import AuthLayout from "../layout/AuthLayout";
import ResetPassword from "../pages/auth/ResetPassword";
import ManageBookings from "../components/ManageBookings/ManageBookings";
import ForgotPasswordPage from "../pages/auth/ForgotPasswordPage";
import { Images } from "../Constants/Images";


export default function CustomRouting() {
    const {_getConfig, config} = useConfig();
    // const {logoUrl} = config;
    // const FeviConIcon=Images.fevi_icon;

    // if (logoUrl) {
    //     let link = document.querySelector("link[rel~='icon']");
    //     if (!link) {
    //     link = document.createElement('link');
    //     link.rel = 'icon';
    //     document.getElementsByTagName('head')[0].appendChild(link);
    //     }
    //     link.href = FeviConIcon;
    // }

    useEffect(() => {
        _getConfig()
    }, [])
    
    if (config?.loading) return <Loader />;

    return (
        <>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="auth" element={<PublicRoute />}>
                        <Route path="login" element={<Login />} />
                        <Route path="forgot-password" element={<ForgotPasswordPage/>} />
                        <Route path="register" element={<Register />} />
                        <Route path="reset-password" element={<ResetPassword />} />
                    </Route>
                    <Route element={<PrivateRoute />}>
                        <Route path="add-pet"
                            element={
                                (<>
                                    <AuthLayout>
                                        <AddPetPage />
                                    </AuthLayout>
                                </>)
                            } 
                        />
                        <Route path="booking" element={<AppointmentForm />} />
                        <Route path="manage" element={<ManageBookings />} />
                    </Route>
                    <Route path="/" exact={true} element={<PageNotFound />} />
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </Router>
        </>
    )
}