export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const SHOW_TOASTER = "SHOW_TOASTER";
export const PREFERRED_FACILITY = "PREFERRED_FACILITY";
export const PREFERRED_CARD_FACILITY = "PREFERRED_CARD_FACILITY";
export const PREFERRED_SERVICE = "PREFERRED_SERVICE";
export const PREFERRED_CARD_SERVICE = "PREFERRED_CARD_SERVICE";
export const PREFERRED_DOCTOR = "PREFERRED_DOCTOR";
export const PREFERRED_CARD_DOCTOR = "PREFERRED_CARD_DOCTOR";
export const PREFERRED_DAY_DATE = "PREFERRED_DAY_DATE";
export const PREFERRED_TIMING = "PREFERRED_TIMING";
export const PREFERRED_TIMING_CARD = "PREFERRED_TIMING_CARD";
export const COMBINE_REDUCER = "COMBINE_REDUCER";
export const CLIENT_CONTACT_INFO = "CLIENT_CONTACT_INFO";
export const OTP_VERIFICATION = "OTP_VERIFICATION";
export const SPECIES_DATA = "SPECIES_DATA";
export const BREEDS_DATA = "BREEDS_DATA";
export const CLIENT_INFO = "CLIENT_INFO";
export const PET_INFO = "PET_INFO";
export const PREFERRED_DOCTOR_RESPONSE = "PREFERRED_DOCTOR_RESPONSE";
// F A C I L I T I E S
export const SET_FACILITY = "SET_FACILITY";
export const GET_FACILITIES = "GET_FACILITIES";
export const GET_FACILITIES_SUCCESS = "GET_FACILITIES_SUCCESS";
export const GET_FACILITIES_FAILED = "GET_FACILITIES_FAILED";
// S E R V I C E S
export const GET_SERVICES = "GET_SERVICES";
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export const GET_SERVICES_FAILED = "GET_SERVICES_FAILED";
//  D O C T O R S
export const GET_DOCTORS = "GET_DOCTORS";
export const GET_DOCTORS_SUCCESS = "GET_DOCTORS_SUCCESS";
export const GET_DOCTORS_FAILED = "GET_DOCTORS_FAILED";
//  S L O T S
export const GET_SLOTS = "GET_SLOTS";
export const GET_SLOTS_SUCCESS = "GET_SLOTS_SUCCESS";
export const GET_SLOTS_FAILED = "GET_SLOTS_FAILED";
export const SET_SLOT = "SET_SLOT";
// CALENDAR
export const SET_CALENDAR = "SET_CALENDAR";
// SEND OTP
export const SEND_OTP = "SEND_OTP";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAILED = "SEND_OTP_FAILED";
// CONFIRM OTP
export const CONFIRM_OTP = "CONFIRM_OTP";
export const CONFIRM_OTP_SUCCESS = "CONFIRM_OTP_SUCCESS";
export const CONFIRM_OTP_FAILED = "CONFIRM_OTP_FAILED";
// GET PET DATA
export const GET_PETS = "GET_PETS";
export const GET_PETS_SUCCESS = "GET_PETS_SUCCESS";
export const GET_PETS_FAILED = "GET_PETS_FAILED";
// SUBMIT APPOINTMENT
export const SUBMIT_APPOINTMENT = "SUBMIT_APPOINTMENT";
export const SUBMIT_APPOINTMENT_SUCCESS = "SUBMIT_APPOINTMENT_SUCCESS";
export const SUBMIT_APPOINTMENT_FAILED = "SUBMIT_APPOINTMENT_FAILED";
// SET CLIENT
export const SET_CLIENT_PHONE = "SET_CLIENT_PHONE";
// USER ACTION
export const SET_USER_ACTION = "SET_USER_ACTION";
//RESCHEDULE APPOINTMENT
export const RESCHEDULE_APPOINTMENT = "RESCHEDULE_APPOINTMENT";
export const RESCHEDULE_APPOINTMENT_SUCCESS = "RESCHEDULE_APPOINTMENT_SUCCESS";
export const RESCHEDULE_APPOINTMENT_FAILED = "RESCHEDULE_APPOINTMENT_FAILED";