import { useFormikContext } from "formik";
// import useCustomTheme from "../../../hooks/useCustomTheme";
import { FormControl, FormLabel } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { resetLogin } from "../../../Redux/auth/slice";

function EmailForm({loginStatus,feildStatus}) {
    // const {
    //     background_color,
    //     btn_clinic_bg_color,
    //     btn_clinic_text_color,
    //     btn_telehealth_bg_color,
    //     btn_telehealth_text_color,
    //     lp_bg_color
    // } = useCustomTheme();
    const { t } = useTranslation();
    const bookingtLabels = t("data.frontLabels.booking", { returnObjects: true });

    const {setFieldValue, errors, touched, values} = useFormikContext();
    const dispatch = useDispatch();
    return (
        <>
            <div className="row m-0 mt-2 w-100">
                <div className="col-12 p-0">
                    <FormControl fullWidth>
                        <FormLabel
                            className={`auth-label redAsterisk`}
                        >{bookingtLabels?.enter_email_address}</FormLabel>
                        
                        <input
                            minLength={3}
                            maxLength={100}
                            disabled={feildStatus}
                            placeholder="example@email.com"
                            name="email"
                            className="login-inputs w-100"
                            value={values.email}
                            onChange={(e) => {setFieldValue("email", e.target.value) }}
                            onFocus={()=>dispatch(resetLogin())}
                        />
                        {errors.email && touched.email && <small className="text-danger">{errors.email}</small>}
                    </FormControl>
                </div>
            </div>
        </>
    )
}

export default EmailForm;