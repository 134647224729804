import { createSlice } from "@reduxjs/toolkit";
import { createPet, getSpecies, getBreeds, getPets } from "./thunk";

const InitialState={
    loading: false,
    error: false,
    pets: [],
    pet: null,
    species: null,
    breeds: null,
    addPetSuccess: null,
    addPetError: null
};

const petSlice= createSlice({
    name:"Pets",
    initialState: InitialState,
    reducers: {
        setPet(state, action) {
            state.pet = action.payload;
        },
        setPets(state, action) {
            state.pets = action.payload;
        },
        resetPet(state, action) {
            return InitialState;
        }
    },
    extraReducers: (builder) => {
        // get species
        builder.addCase(getSpecies.pending, (state, payload) => {
            state.loading = true;
        })
        .addCase(getSpecies.fulfilled,(state,{payload})=>{
            state.loading=false;
            state.species = payload.species;
        })
        .addCase(getSpecies.rejected,(state,payload)=>{
            state.loading = false;
            state.species = [];
            state.error = true;
        })
        // get breeds
        .addCase(getBreeds.pending, (state, payload) => {
            state.loading = true;
        })
        .addCase(getBreeds.fulfilled,(state,{payload})=>{
            state.loading=false;
            state.breeds = payload.breeds;
        })
        .addCase(getBreeds.rejected,(state,payload)=>{
            state.loading = false;
            state.species = [];
            state.error = true;
        })
        // CREATE PET
        .addCase(createPet.pending, (state, payload) => {
            state.loading = true;
            state.addPetSuccess = null;
            state.addPetError = null;
        })
        .addCase(createPet.fulfilled, (state,{payload})=>{
            state.loading=false;
            state.addPetSuccess=payload.message;
        })
        .addCase(createPet.rejected, (state,payload)=>{
            state.loading = false;
            state.addPetError = payload;
        })
        // get pet
        .addCase(getPets.pending, (state, payload) => {
            state.loading = true;
            state.pet = null;
            state.addPetSuccess = null;
            state.addPetError = null;
        })
        .addCase(getPets.fulfilled, (state,{payload})=>{
            state.loading=false;
            state.pets=payload.pets;
        })
        .addCase(getPets.rejected, (state,payload)=>{
            state.loading = false;
            state.addPetError = "Something went wrong!";
        })
    },
});

export const { setPet, setPets, resetPet } = petSlice.actions;
export default petSlice.reducer;