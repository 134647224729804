import { useDispatch, useSelector } from "react-redux";
import {setIsLoggedIn} from "../Redux/auth/slice";

export default function useAuth() {
    const auth = useSelector((state) => state.auth)
    const dispatch = useDispatch();
    const {isLoggedIn, loginStatus} = auth;

    const setUserLoggedIn = (payload) => {
        dispatch(setIsLoggedIn(payload));
    }


    return {isLoggedIn, setUserLoggedIn, loginStatus};
}