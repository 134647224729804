import { EmailFormValidation } from "../../../Constants/Schema";
import useCustomTheme from "../../../hooks/useCustomTheme";
import { Formik } from "formik";
import { TextField, FormControl, FormLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { verifyEmail, forgotPassword } from "../../../Redux/auth/thunk";
import { TailSpin } from "react-loader-spinner";
import { resetLogin } from "../../../Redux/auth/slice";
import { useTranslation } from "react-i18next";

const TYPE = {
    0: "contact",
    1: "pet_id",
    2: "email"
}

function EnterEmail() {
    const {
        btn_clinic_bg_color,
    } = useCustomTheme();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const { t } = useTranslation();
    const contactLabels = t("data.frontLabels.contact", { returnObjects: true });
    const frontLabels = t("data.frontLabels", { returnObjects: true });
    const { loading, clientId, loginStatus } = auth;

    // const reloadPageAfterDelay = () => {
    //     setTimeout(() => {
    //       window.location.reload(); 
    //     }, 3000);
    //   };

    const onHandleSubmit = (payload) => {
        if (loginStatus && ["reset_password"].includes(loginStatus)) {
            const data = {
                client_id: clientId,
                email: payload.email,
                login_type: TYPE[2]
            }
            dispatch(forgotPassword(data));
            // reloadPageAfterDelay()
        } else {
            payload["contact_no"] = auth.login.phone;
            payload["country_code"] = auth.login.countryCode;
            dispatch(verifyEmail(payload));
        }

    }
    return (
        <>
            <div className="m-auto">
                <h1 className="login-heading text-center">{frontLabels?.contact?.ph_email_address}</h1>
                <p className="login-sub-heading mb-3 text-center">{frontLabels?.header?.email_verification}</p>
                <Formik
                    initialValues={{ email: "" }}
                    enableReinitialize={true}
                    validationSchema={EmailFormValidation()}
                    onSubmit={onHandleSubmit}
                >
                    {({ values, setFieldValue, errors, touched, handleSubmit }) => (
                        <>
                            <form onSubmit={handleSubmit}>
                                <FormControl fullWidth>
                                    <FormLabel
                                        className={`auth-label redAsterisk`}
                                    >
                                        {contactLabels?.email_address}
                                    </FormLabel>
                                    <TextField
                                        name="email"
                                        className="mt-0 login-inputs"
                                        sx={{
                                            width: "100%"
                                        }}
                                        onChange={(e) => setFieldValue("email", e.target.value)}
                                    />
                                    {errors.email && touched.email && <p className="text-start m-0 p-0"><small className="text-danger">{errors.email}</small></p>}
                                </FormControl>
                                {!loading ?
                                    <button
                                        className="mt-2 auth-btn auth-submit-btn"
                                        style={{
                                            background: btn_clinic_bg_color,
                                            width: "100%",
                                            color: "#fff"
                                        }}
                                        type="submit"
                                    >
                                        {contactLabels?.ancr_submit_email}
                                    </button> :
                                    <TailSpin
                                        height="35"
                                        width="35"
                                        color={btn_clinic_bg_color}
                                        ariaLabel="tail-spin-loading"
                                        radius="2"
                                        wrapperStyle={{ marginBottom: "8px" }}
                                        wrapperClass="mt-2 d-flex justify-content-center"
                                        visible={true}
                                    />
                                }
                            </form>
                            {!loading &&
                                <button
                                    className="mt-2 auth-secondary-btn"
                                    style={{
                                        width: "100%"
                                    }}
                                    onClick={() => {
                                        dispatch(resetLogin())
                                    }}
                                >
                                    {frontLabels?.booking?.go_back}
                                </button>
                            }
                        </>
                    )}
                </Formik>
            </div>
        </>
    )
};

export default EnterEmail;