import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./SessionSummary.css";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../../Constants/Constant";
import useCurrentLanguage from "../../hooks/useCurrentLanguage";

const SessionSummary = () => {
  const facilityReducer = useSelector((state) => state?.facility);
  const serviceReducer = useSelector((state) => state?.service);
  const doctorReducer = useSelector((state) => state?.doctor);
  const calendarReducer = useSelector((state) => state?.calendar);
  const [dateMonth, setDateMonth] = useState(null);
  const { t } = useTranslation();
  const frontLabels = t("data.frontLabels", { returnObjects: true });
  const frontMessages = t("data.frontMessages", { returnObjects: true });
  const { facility } = facilityReducer;
  const { service } = serviceReducer;
  const { doctor } = doctorReducer;
  const { selectedSlotIndex, selectedTime, week, selectedDateIndex } = calendarReducer;
  if (week && (selectedDateIndex !== undefined || selectedDateIndex !== null) && !dateMonth) {
    const date = new Date(week?.[selectedDateIndex]["date"]).getDate();
    const month = new Date(week?.[selectedDateIndex]["date"]).getMonth() + 1;
    const monthname = moment(month, "MM").format("MMMM");
    setDateMonth({
      date,
      monthname
    })
  }


  useEffect(() => {
    window.scrollTo(0, 0);
    // getPreferedSpeciesApiCall();
  }, []);
  const lang = useCurrentLanguage();
  return (
    <>
      <div className="text-left content-margin noto-font mb-4 px-md-0">
        <div className="row lg-mb-0 sm-mb-2">
          <div className="col-md-6 col-12 mb-2 mb-md-1">
            <div className="summary-category-header">{frontLabels?.thankyou?.location || 'Location'}</div>
            <div className="pt-sm-2 content-value-subhead">
              <h4
                className="summary-hospital-name-doctor"
              >{facility && facility?.clinic_name}</h4>
              {lang === LANGUAGES.EN ? <p className="summary-hospital-address">{facility?.address && facility?.address}, {facility?.city && facility?.city}, {facility?.state && facility?.state}</p> :
                <p className="summary-hospital-address">{facility?.state && facility?.state}, {facility?.city && facility?.city}, {facility?.address && facility?.address}</p>}
            </div>
          </div>
          <div className="col-md-6 col-12 text-margin mb-2 mb-md-1">
            <div className="summary-category-header">{frontLabels?.thankyou?.doctor}</div>
            <div className="pt-md-2 pt-sm-2 content-value-subhead">
              <h4 className="summary-hospital-name-doctor ">
                {doctor && doctor?.lastname !== frontLabels?.booking?.any ? (doctor?.firstname && doctor?.lastname && `${doctor?.lastname} ${doctor?.firstname}`) : frontLabels?.booking?.any_assignment}
              </h4>
              <div>
                {/* {doctor?.specialization}Head Veterinarian */}
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-lg-0 ">
          <div className="col-md-6 col-12 mb-2 mb-md-1">
            <div className="summary-category-header">
              {frontLabels?.thankyou?.date_time}
            </div>

            {lang === LANGUAGES.JP ? <div className="pt-md-3 pt-sm-2 ">
              <div className="content-value-subhead ">

                <span className="summary-date-time-bold ">
                  {dateMonth && dateMonth.monthname}{dateMonth && dateMonth.date}日
                </span>
                <span className="summary-date-time-bold margin-left-6px">
                  {week && week?.[selectedDateIndex]["day"]}
                </span>
              </div>
              <div className="content-value-subhead">
                {/* {timing?.session && timing?.session} */}

                {/* <span className="summary-date-time">
                  {selectedSlotIndex["morning"] > -1 ? `${frontLabels?.booking?.morning}` :
                    selectedSlotIndex["noon"] > -1 ? `${frontLabels?.booking?.noon}` : `${frontLabels?.booking?.noon}`
                  }
                </span> */}
                <span className="summary-date-time-bold margin-left-6px">
                  {moment(selectedTime, ["HH:mm"]).locale(LANGUAGES.EN).format("h:mm A")}
                </span>
              </div>
            </div>
              :
              <div className="pt-md-3 pt-sm-2">
                <div className="content-value-subhead ">
                  <span className="summary-date-time-bold ">
                    {week && week?.[selectedDateIndex]["day"]}
                  </span>
                  <span className="summary-date-time-bold margin-left-6px">
                    {dateMonth && dateMonth.date}nd {dateMonth && dateMonth.monthname}
                  </span>
                </div>
                <div className="content-value-subhead">
                  {/* {timing?.session && timing?.session} */}

                  {/* <span className="summary-date-time">
                    {selectedSlotIndex["morning"] > -1 ? `${frontLabels?.booking?.morning}` :
                      selectedSlotIndex["noon"] > -1 ? `${frontLabels?.booking?.noon}` : `${frontLabels?.booking?.evening}`
                    }
                  </span> */}
                  <span className="summary-date-time-bold margin-left-6px">{moment(selectedTime, ["HH:mm"]).locale(LANGUAGES.EN).format("hh:mm A")}</span>
                </div>
              </div>}
          </div>
          <div className="col-md-6 col-12 text-margin mb-2 mb-md-1">
            <div className="summary-category-header">{frontMessages?.SERVICE}</div>
            <div className="pt-md-3 pt-sm-2 content-value-subhead ">
              <div className="service-name">{service?.name}</div>
              <div className="service duration">{service?.select_service === '' ? '' : service?.duration} {service?.select_service === '' ? '' : `${frontLabels?.service?.minutes}`}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(SessionSummary);
