import { Formik, useFormikContext,  } from "formik";
// import useCustomTheme from "../../../hooks/useCustomTheme";
import { TextField, Button, FormControl, FormLabel } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { resetLogin } from "../../../Redux/auth/slice";
// import {petIdFormValidation} from "../../../Constants/Schema";

function PetIdForm({loginStatus}) {

    const {setFieldValue, errors, touched, values} = useFormikContext();
    const { t } = useTranslation();
    const bookingtLabels = t("data.frontLabels.booking", { returnObjects: true });
    const dispatch = useDispatch();
    return (
        <>
            <div className="row w-100 m-0 mt-2">
                <div className="col-12 p-0">
                    <FormControl fullWidth>
                        <FormLabel
                            className={`auth-label redAsterisk`}
                        >{bookingtLabels?.enter_pet_id || 'Enter your pet ID'}</FormLabel>
                        <div
                            className="input-wrapper"
                        >
                            <input
                             minLength={4}
                             maxLength={20}
                                name="petId"
                                className="login-inputs w-100"
                                placeholder={`${bookingtLabels?.ex_text || "Ex:"} CID-0000-00`}
                                value={values.petId}
                                onChange={(e) => setFieldValue("petId", e.target.value)}
                                onFocus={()=>dispatch(resetLogin())}
                            />
                        </div>
                        {errors.petId && touched.petId && <small className="text-danger">{errors.petId}</small>}
                    </FormControl>
                </div>
            </div>
        </>
    )
}

export default PetIdForm;