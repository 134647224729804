import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios  from "../../Services/Api/Index";
import { API_PATH } from '../../Constants/ApiPath';
import { API_TYPE } from "../../Constants/ApiType";
import { LANGUAGES } from "../../Constants/Constant";

export const getFacilities = createAsyncThunk(
  "facilities/getFacilities",
  async(payload,{rejectWithValue})=>{
    try{
      const lang_code = localStorage.getItem("lang_type");
      const response = await Axios({
        method: API_TYPE.POST,
        url: API_PATH.CLINIC_DETAILS,
        headers: {
          "Accept-Language": lang_code || LANGUAGES.JP,
        },
      });
      const data = await response.data;
      if (data.status === "success" && data.statuscode === 200) {
        return {facilities: data.data.clinics}
      }
      throw "Something went wrong!"
    }catch(err){
        console.log("err - ",err);
      return rejectWithValue("Opps there seems to be an error");
    }
  }
);