// import React from 'react'
// import { LANGUAGES } from '../../Constants/Others'
// import useLang from '../../hooks/useLang';
// import i18n from '../../i18n';
// import { useDispatch } from 'react-redux';
// import { getConfig } from '../../Redux/config/thunk';


// const LanguageChange = ({display}) => {
//     const { updateLang } = useLang();
//     const dispatch = useDispatch();

//     const handleLanguageChange = (lang) => {
//       updateLang(lang);
//       localStorage.setItem("lang_type", lang);
//       dispatch(getConfig(lang))
//       i18n.changeLanguage(lang);
// 	};
//   return (
//     <>
//         <div className={`${display} justify-content-end align-items-center`}>
//             {LANGUAGES?.map(({ code, label }, index) => (
//                 <div key={code} value={code} className={`language-label ${index !== LANGUAGES.length - 1 && "mx-3"}`} onClick={() => handleLanguageChange(code)}>
//                     <p className='text-nowrap mb-0'>{label} </p>
//                 </div>
//             ))}
//         </div>
//     </>
//   )
// }

// export default LanguageChange
import React from 'react';
import { LANGUAGES } from '../../Constants/Others';
import useLang from '../../hooks/useLang';
import i18n from '../../i18n';
import { useDispatch } from 'react-redux';
import { getConfig } from '../../Redux/config/thunk';
import useCustomTheme from '../../hooks/useCustomTheme';
import { resetDoctor } from '../../Redux/doctor/slice';
import { resetCalendar } from '../../Redux/calendar/slice';

const LanguageChange = ({ display }) => {
  const { lang, updateLang } = useLang();
  const dispatch = useDispatch();
  const {
    btn_clinic_bg_color,
  } = useCustomTheme();

  const handleLanguageChange = (selectedLang) => {
    if (selectedLang !== lang) {
      updateLang(selectedLang);
      localStorage.setItem('lang_type', selectedLang);
      dispatch(getConfig(selectedLang));
      i18n.changeLanguage(selectedLang);
      dispatch(resetDoctor())
      dispatch(resetCalendar())
      window.location.reload();
    }
  };

  return (
    <>
      <div className={`${display} justify-content-end align-items-center`}>
        {LANGUAGES?.map(({ code, label }, index) => (
          <div
            key={code}
            value={code}
            className={`language-label ${code === lang ? 'selected-language' : ''} ${
              index !== LANGUAGES.length - 1 ? 'mx-3' : ''
            }`}
            // style={{color:`${code === lang ? btn_clinic_bg_color : ''}`}}
            style={{color:`${code === lang ? "#3366FF" : ''}`}}
            onClick={() => handleLanguageChange(code)}
          >
            <p className='text-nowrap mb-0'>{label}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default LanguageChange;

