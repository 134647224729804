// import React from "react";
// import i18n from "../../i18n";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import InstagramIcon from "@mui/icons-material/Instagram";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import { LANGUAGES } from "../../Constants/Others";
// import useConfig from "../../hooks/useConfig";
// import useLang from "../../hooks/useLang";

// const FooterSection = () => {
// 	const { config } = useConfig();
// 	const { updateLang } = useLang();
// 	const {footerLogo} = config;
	// const { facebook_url, instagram_url, linkedin_url } = config?.footer;
// 	const handleLanguageChange = (lang) => {
// 		updateLang(lang)
// 		localStorage.setItem("lang_type", lang);
// 		i18n.changeLanguage(lang);
// 	};
// const placeholderUrl="https://aalda.com/wp-content/themes/aalda/_assets/images/common/logo_text_color.svg"

// 	return (
// 		<>
// 			<div className="footer-with-next-option-pages " style={{
// 				background: config?.footer?.background_color
// 			}}>
// 				<div className="container d-flex align-items-center py-3 justify-content-between">
// 					<div>
// 						<div className="">
// 							<img
// 								className="dcc-icon-image"
// 								src={footerLogo !== '' ? footerLogo : placeholderUrl}
// 								alt={config.footer[`lp_heading_text_${config.lang}`]}
// 							/>
// 						</div>
						
// 					</div>
// 					<div className="">
// 						{/* {LANGUAGES?.map(({ code, label }, index) => (
// 							<div key={code} value={code} className="language-label" onClick={() => handleLanguageChange(code)}>
// 								{label} {index !== LANGUAGES.length - 1 && <span className="separator">|</span>}
// 							</div>
// 						))} */}
// 						{/* <select
// 							defaultValue={"en"}
// 							onChange={(e) => {
// 								handleLanguageChange(e);
// 							}}
// 						>
// 							{LANGUAGES?.map(({ code, label }) => (
// 								<option key={code} value={code}>
// 									{label}
// 								</option>
// 							))}
// 						</select> */}
						// <div>
						// 	<a href={facebook_url || "https://www.facebook.com/"} target="_blank">
						// 		<FacebookIcon sx={{ "& :hover": { color: "#fff", backgroundColor: "#fff" } }} />
						// 	</a>
						// 	<a className="ms-0 ms-md-2" href={instagram_url || "https://www.instagram.com/"} target="_blank">
						// 		<InstagramIcon sx={{ "& :hover": { color: "#fff", backgroundColor: "#fff" } }} />
						// 	</a>
						// 	<a className="ms-0 ms-md-2" href={linkedin_url || "https://www.linkedin.com/"} target="_blank">
						// 		<LinkedInIcon sx={{ "& :hover": { color: "#fff", backgroundColor: "#fff" } }} />
						// 	</a>
						// </div>
// 					</div>
// 				</div>
// 			</div>
// 		</>
// 	);
// };

// export default React.memo(FooterSection);



import * as React from "react";
import styled from "styled-components";
import LanguageChange from "./LanguageChange";
import SocialMediaIcons from "./SocialMediaIcons";
import useConfig from "../../hooks/useConfig";
import imageLogo from "../../images/logo.png"
import packageJson from './../../../package.json';
global.appVersion = packageJson.version;

const FooterSection = (props) => {
	const { config } = useConfig();
	const {footerLogo} = config;
	const placeholderUrl="https://aalda.com/wp-content/themes/aalda/_assets/images/common/logo_text_color.svg"

  return (
    <Div style={{paddingBottom:`${props.padding}`}} >
      <Div2>
        <Img
          loading="lazy"
          className="dcc-icon-image"
          src={footerLogo}
          alt={config.footer[`lp_heading_text_${config.lang}`]}
          onError={(e) => e.target.src = imageLogo}
        />
        <Div3>
          {config?.footer?.footer_text}
          <div>
            v {global.appVersion}
          </div>
        </Div3>
        <Div4 className="d-flex flex-row-reverse flex-sm-column justify-content-between">
			<LanguageChange display={"d-flex"}/>
			<div>
				<SocialMediaIcons />
			</div>
        </Div4>
      </Div2>
    </Div>
  );
}
export default React.memo(FooterSection);

const Div = styled.div`
  box-shadow: 0 -7px 5px -6px rgba(0, 0, 0, 0.12);
  background-color: #F9F9F9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 42px;
  @media (max-width: 991px) {
    padding: 16px 20px 58px;
  }
`;

const Div2 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const Img = styled.img`
  aspect-ratio: 4.86;
  object-fit: contain;
  object-position: center;
  width: 50px;
  height: 50px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  @media (max-width: 567px) {
    margin-bottom: -18px;
  }
`;

const Div3 = styled.div`
  color: #1f2124;
  font-feature-settings: "clig" off, "liga" off;
  align-self: center;
  text-align:center;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
  font: 14px Noto Sans, sans-serif;
  @media (max-width: 991px) {
	  font: 12px Noto Sans, sans-serif;
    line-height: 22px;
    marign-top:0px;
    padding-top:0px;
    max-width: 100%;
  }
`;
const Div4 = styled.div`
  @media (max-width: 567px) {
    width:100%
  }
`;

