import { useTranslation } from "react-i18next";

/* eslint-disable default-case */
function SubHeading (props) {
    const { t } = useTranslation();
    const frontLabels = t("data.frontLabels", { returnObjects: true });
    const {tab,action} = props
    if(action==="forget"){
        let type="contact no"
        switch (tab) {
            case 0:
              type = "contact no";
              return frontLabels?.booking?.enter_contact_and_pass ?? "Enter contact number and password"; 
              break;
            case 1:
              type = "email";
              return frontLabels?.booking?.enter_contact_and_pass ?? "Enter email and password"; 
              break;
            default: 
              type = "";
              return frontLabels?.booking?.enter_contact_and_pass ?? "Enter contact number and password"; 
          }
       
    }else
    {
        let type="contact no"
        switch(tab) {
            case 1:
                type = "pet ID";
                return frontLabels?.booking?.enter_pet_ID_and_pass ?? "Enter pet ID and password"; 
                break;
            case 2:
                type = "email";
                return frontLabels?.booking?.enter_pet_ID_and_pass ?? "Enter email and password"; 
                break;
            default: 
                type = "";
                return frontLabels?.booking?.enter_pet_ID_and_pass ?? "Enter contact number and password"; 
        }
    }
}

export default SubHeading;



