import { useDispatch, useSelector } from "react-redux";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useCustomTheme from "../../hooks/useCustomTheme";
import "./SelectPet.css";
import { setPet } from "../../Redux/pet/slice";
import AddPetPage from "../../pages/addPet/AddPetPage";
import { useState } from "react";
import { getPets } from "../../Redux/pet/thunk";
import useConfig from "../../hooks/useConfig";
import PetImagePlaceholder from "../../images/pet-placeholder.jpeg";
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
import useCurrentLanguage from "../../hooks/useCurrentLanguage";
import { LANGUAGES } from "../../Constants/Constant";

const pet_gender_jp = {
     Male:"オス",
     Female:"メス",
     Unknown:"不明",
}


function SelectPet () {
    const lang = useCurrentLanguage();
    const {
        btn_clinic_bg_color,
        activeClass
    } = useCustomTheme();
    const {config} = useConfig();
    const dispatch = useDispatch();
    const petReducer = useSelector(state => state.pet);
    const {pet, pets, addPetSuccess} = petReducer;
    const [addPetModal, setAddPetModal] = useState(false);
    const { t } = useTranslation();
    const bookingLabels = t("data.frontLabels.booking", { returnObjects: true });

    const onHandleSelectPet = (payload) => {
        dispatch(setPet(payload));
    }

    const onHandleAddPetModal = () => {
        if (addPetModal && addPetSuccess) {
            dispatch(getPets());
        }
        setAddPetModal(!addPetModal)
    }
    return (
        <>
            <Grid container spacing={1}>
                {
                    pets?.map((elem) => (
                        <Grid key={elem.id} item xs={6} sm={4} md={2} onClick={() => onHandleSelectPet(elem)}>
                            <Card sx={{
                                maxWidth: 345,
                                position: "relative",
                                boxShadow: "none",
                                borderRadius: "10px",
                                height: "100%"
                            }}
                                className="pointer-icon"
                            >
                                <CardMedia
                                    sx={{ height: 140 }}
                                    image={elem.pet_image ? `${config?.imageBaseUrl}${elem.pet_image}` : PetImagePlaceholder}
                                    component="img"
                                    onError={(e) => {
                                        e.target.src = PetImagePlaceholder;
                                    }}
                                    title="green iguana"
                                    className="pet-image"
                                />
                                <div 
                                    className={`selected-pet ${elem.id === pet?.id && "selected-pet-active"}`}
                                    style={{
                                        background: btn_clinic_bg_color,
                                        height: "100%",
                                        width: "100%"
                                    }}
                                >
                                    
                                </div>
                                {
                                    elem.id === pet?.id &&
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            zIndex: "999"
                                        }}
                                    >
                                    <CheckCircleIcon
                                        style={{
                                            color: "#fff",
                                            fontSize: "50px",
                                            opacity: "1"
                                        }}
                                    />
                                </div>}
                                <span className="pet-name" style={{
                                    ...activeClass,
                                    fontSize: "12px",
                                    maxWidth: "50%",
                                    height: "27px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                                    title={elem.name}
                                >{elem.name}</span>
                                <CardContent
                                    style={{
                                        fontSize: "small",
                                        padding: "10px"
                                    }}
                                >
                                    <div>
                                        <span>
                                            {lang === LANGUAGES.EN ? elem.gender : pet_gender_jp[elem.gender]}
                                        </span>
                                        <span> | </span>
                                        <span>
                                        {/* {lang==="en"? elem.gender : pet_gender_jp[elem.gender]}  */}
                                        {elem.pet_age}
                                        </span>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                }
                <Grid item xs={6} sm={4} md={2} onClick={() => onHandleAddPetModal()}>
                    <Card sx={
                        {
                            maxWidth: 345,
                            minHeight: "179.5px",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            boxShadow: "none",
                            borderRadius: "10px",
                            border: `1px dashed ${btn_clinic_bg_color}`
                        }}
                        className="pointer-icon"
                    >
                        <CardMedia
                            sx={{ height: 140 }}
                            // image="/static/images/cards/contemplative-reptile.jpg"
                            // image={`${config?.imageBaseUrl}/upload/${pet.pet_image}`}
                            title="green iguana"
                        />
                        <CardContent className="text-center">
                            <AddBoxIcon />
                            <h4>{bookingLabels?.add_pet}</h4>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <AddPetModal open={addPetModal} handleClose={setAddPetModal} />
        </>
    );
}

export default SelectPet;

const AddPetModal = ({open, handleClose}) => {
    const { t } = useTranslation();
    const frontLabels = t("data.frontLabels", { returnObjects: true });
    return (
        <>
                <Modal scrollable={true} show={open} onHide={handleClose} size="lg" centered style={{overflowY: 'hidden' }}>
                    <Modal.Header className="pb-0" style={{fontSize:"12px"}} closeButton>
                        <Modal.Title className="d-flex justify-content-center align-items-center" style={{width:"100%"}}>
                            <h1 className="">{frontLabels?.header?.add_your_pet}</h1>
                            </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AddPetPage modal={true} modalClose={handleClose}/>
                    </Modal.Body>
                </Modal>
        </>
    )
}