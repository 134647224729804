import { useEffect, useState } from "react";
import { Alert } from "@mui/material";
import useCustomTheme from "../../../hooks/useCustomTheme";
import useTimer from "../../../hooks/useTimer";
import { useDispatch, useSelector } from "react-redux";
import { resetLogin } from "../../../Redux/auth/slice";
import { confirmation, forgotPassword, verifyEmail } from "../../../Redux/auth/thunk";
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from "react-i18next";
import { TYPE } from "../ForgotPasswordPage";
import { useNavigate, useLocation } from 'react-router-dom';
import useCurrentLanguage from "../../../hooks/useCurrentLanguage";
import { LANGUAGES } from "../../../Constants/Constant";



function CheckYourEmail() {

    const dispatch = useDispatch();
    const location = useLocation();
    const lang = useCurrentLanguage();
    const {
        btn_clinic_bg_color,
    } = useCustomTheme();
    const { timer, startTimer, timerStatus, endTimer } = useTimer();
    const auth = useSelector(state => state.auth);
    const { message, loginStatus, clientExistingEmail, clientId, clientExistigCoutryCode, clientExistingphone, captchaToken } = auth;
    const [loading, setLoading] = useState(true);

    const { t } = useTranslation();
    const frontLabels = t("data.frontLabels", { returnObjects: true });
    const isLoginPage = location.pathname === '/auth/login';

    const onHandleResendEmail = () => {
        if (!timerStatus) startTimer();
        if (loginStatus === "emailSent" && isLoginPage) {
            const payload = {
                contact_no: clientExistingphone ? clientExistingphone : auth.login.phone,
                country_code: clientExistigCoutryCode ? clientExistigCoutryCode : auth.login.countryCode,
                email: clientExistingEmail ? clientExistingEmail : auth.login.email,
            }
            dispatch(verifyEmail(payload));
        } else if (isLoginPage) {
            const login_type = auth.login.loginType;
            const payload = {
                // contact_no: clientExistingphone ? clientExistingphone : auth.login.phone,
                // country_code: clientExistigCoutryCode ? clientExistigCoutryCode : auth.login.countryCode,
                "g-recaptcha-response": captchaToken,
                lang: lang || LANGUAGES.EN,
                email: !!clientExistingEmail?.length ? clientExistingEmail : auth.login.email,
                login_type: auth.login.loginType || "email"
            }
            if (login_type === "pet_id") {
                delete payload.email;
                payload.pet_id = auth.login.petId;
            }
            if (login_type === "contact") {
                // delete payload.login_type;
                delete payload.lang;
                delete payload["g-recaptcha-response"];
                payload.contact_no = auth.login.phone;
                payload.country_code = auth.login.countryCode;
            }
            dispatch(confirmation(payload));
        } else {
            const forgotpayload = {
                client_id: clientId,
                login_type: TYPE[1]
            }
            dispatch(forgotPassword(forgotpayload))
        }
    }

    // const onHandleResendEmail = () => {
    //     if (!timerStatus) startTimer();
    //     const payload = {
    //         contact_no: clientExistingphone? clientExistingphone : auth.login.phone,
    //         country_code: clientExistigCoutryCode? clientExistigCoutryCode : auth.login.countryCode,
    //         email: clientExistingEmail? clientExistingEmail : auth.login.email,
    //     }
    //     dispatch(verifyEmail(payload));
    // }

    const navigate = useNavigate();

    const backToLogin = () => {
        if (isLoginPage) {
            dispatch(resetLogin());
        } else {
            navigate('/auth/login');
        }
        // dispatch(resetLogin());
        // Use navigate function to navigate to the desired route
    };

    useEffect(() => {
        startTimer();
        setLoading(false);
        return () => {
            endTimer();
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (!timerStatus) {
            setLoading(false);
        }
    }, [timerStatus]);

    // useEffect(()=>{
    //     if ( error && message ) {

    //         toast.error(message, { autoClose: TOAST_TIMER });
    //     }

    // else if (!error && message !== "" && message !== 500 && message !== 200) {
    //     toast.success(message, { autoClose: TOAST_TIMER });
    // }
    // },[error, message])
    const encryptEmail = (email) => {
        return `${email.slice(0, 2)}**** ${email.slice(email.length - 4, email.length - 1)}**`;
    };

    return (
        <>
            <div className="w-100 m-auto">
                <h1 className="login-heading text-center ">{frontLabels?.booking?.check_email || "Check Your Email"}</h1>
                <div>
                    {/* {loginStatus === "emailSent" && message &&
                        <Alert
                            sx={{ justifyContent: 'center' }}
                            severity="success">
                            <AlertTitle>Success</AlertTitle>
                            {message}
                        </Alert>
                    } */}
                </div>
                {/* <p><span onClick={onHandleResendEmail}>Send code again</span> <span>00:{timer > 9 ? timer : `0${timer}`}</span></p> */}
                <div className="m-auto text-center mt-3">
                    <span>
                        {frontLabels?.booking?.VARIFY_EMAIL_SENT?.replace("{email}", encryptEmail(clientExistingEmail?.length ? clientExistingEmail : auth.login.email))}
                    </span>
                    {/* <span>
                        {clientExistingEmail ? (
                            <span>{encryptEmail(clientExistingEmail)}</span>
                        ) : (
                            <span>{encryptEmail(auth.login.email)}</span>
                        )}
                    </span> */}
                    <div> {frontLabels?.booking?.sent_verification_to_email_dont_rcv || frontLabels?.booking?.EMAIL_NOT_RECIEVED}</div>
                </div>
                {!loading && !timerStatus && <button
                    className="mt-4 auth-btn auth-submit-btn"
                    style={{
                        background: btn_clinic_bg_color,
                        width: "100%",
                        color: "#fff",
                        '&:hover': {
                            // color: btn_clinic_bg_color,
                            // backgroundColor: btn_clinic_bg_color,
                        }
                    }}
                    onClick={onHandleResendEmail}
                >
                    {frontLabels?.booking?.re_send_email || "Resend Email"}
                </button>}
                {
                    !loading && timerStatus && <button
                        className="mt-4 auth-btn auth-submit-btn"
                        style={{
                            background: btn_clinic_bg_color,
                            width: "100%",
                            color: "#fff",
                            '&:hover': {
                                // color: btn_clinic_bg_color,
                                // backgroundColor: btn_clinic_bg_color,
                            }
                        }}
                    >
                        {`00:${timer > 9 ? `${timer}` : `0${timer}`}`}
                    </button>
                }
                {
                    loading && <button
                        className="mt-4 auth-btn auth-submit-btn"
                        style={{
                            background: btn_clinic_bg_color,
                            width: "100%",
                            color: "#fff",
                            '&:hover': {
                                // color: btn_clinic_bg_color,
                                // backgroundColor: btn_clinic_bg_color,
                            }
                        }}
                    >
                        <CircularProgress
                            sx={{
                                color: "#fff"
                            }}
                        />
                    </button>

                }
                <button
                    style={{
                        width: "100%",
                    }}
                    className="mt-2 auth-secondary-btn"
                    onClick={() => backToLogin()}
                >
                    {frontLabels?.booking?.back_to_login || "Back to Login"}
                </button>
            </div>
        </>
    )
}

export default CheckYourEmail;