import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { cancelAppointment } from '../Redux/appointment/thunk';
import { toast } from 'react-toastify';
import { resetAppointment } from '../Redux/appointment/slice';
import { TOAST_TIMER } from '../Constants/Others';

const CancelModal = (props) => {
	const { setCancel, appointmentData, selected, setAppointmentData } = props;
	const { t } = useTranslation();
  	const formLabels = t("data.frontLabels", { returnObjects: true });
	const frontMessages = t("data.frontMessages", { returnObjects: true });
	const dispatch = useDispatch();
	const [reason, setReason] = useState('');
	const [showModal, setShowModal] = useState(true);
	const appointmentReducer = useSelector(state => state.appointment);
	const {cancelSuccess, cancelError} = appointmentReducer;
	const appointmentId = selected.id;
	const handleCancelAppointment = () => {
		getCancelAppointment();
	}

	const getCancelAppointment = async () => {
		const payload = {
			appointment_id: appointmentId,
			reason: reason
		};
		dispatch(cancelAppointment(payload));
	};

	useEffect(() => {
		if (cancelSuccess) {
			toast.success( frontMessages?.apt_cancl_success ||"Appointment cancelled.")
			setShowModal(false);
			setCancel(false);
			dispatch(resetAppointment());
		}
	}, [cancelSuccess]);

	useEffect(() => {
		if (cancelError) {
			toast.error( frontMessages?.apt_cancl_fail || "Appointment cancellation failed.", {autoClose: TOAST_TIMER})
			dispatch(resetAppointment());
		}
	}, [cancelError]);

	return (
		<>
			<Modal show={showModal} onHide={() => setCancel(false)} centered>
				<Modal.Header closeButton>
					<Modal.Title>{frontMessages?.CANCEL_REASON_POPUP}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="mb-3">
						<label htmlFor="exampleFormControlTextarea1" className="form-label">{formLabels?.booking?.reason_cancel}</label>
						<textarea value={reason} onChange={(e) => setReason(e.target.value)} className="form-control" rows="3"></textarea>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button className="close-btn" variant="secondary" onClick={() => setCancel(false)}>{formLabels?.booking?.close}</Button>
					<Button className="cancel-btn" onClick={handleCancelAppointment} variant="primary">{formLabels?.booking?.cancel}</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default React.memo(CancelModal);