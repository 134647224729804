import React from 'react'
// import FacebookIcon from "@mui/icons-material/Facebook";
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Images } from "../../Constants/Images";
import useConfig from "../../hooks/useConfig";
import { SvgIcon } from '@mui/material';
import instaSvg from '../../images/insta-logo.svg'

const SocialMediaIcons = (props) => {
    const { config } = useConfig();
	const { facebook_url, instagram_url, linkedin_url } = config?.footer;
  return (
    <div className='m-auto p-0 d-flex justify-content-around justify-content-sm-center'>
        <a href={facebook_url || "https://www.facebook.com/"} target="_blank" >
            <FacebookOutlinedIcon sx={{ "& :hover": { color: "#000fff", backgroundColor: "#fff" },color:"#000", backgroundColor:"#fff" }} />
        </a>
        <a className="ms-2 ms-md-2" href={instagram_url || "https://www.instagram.com/"} target="_blank">
            <SvgIcon sx={{ "& :hover": { filter: 'brightness(0) saturate(100%) invert(8%) sepia(100%) saturate(6809%) hue-rotate(244deg) brightness(109%) contrast(136%);' },color:"#000", backgroundColor:"#fff" }} >
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="ph:instagram-logo-fill">
                <path id="Vector" d="M15 12.3365C15 12.9299 14.8241 13.5099 14.4944 14.0033C14.1648 14.4966 13.6962 14.8811 13.1481 15.1082C12.5999 15.3352 11.9967 15.3947 11.4147 15.2789C10.8328 15.1631 10.2982 
                    14.8774 9.87868 14.4579C9.45912 14.0383 9.1734 13.5038 9.05764 12.9218C8.94189 12.3399 9.0013 11.7367 9.22836 11.1885C9.45542 10.6403 9.83994 10.1718 10.3333 9.84214C10.8266 9.5125 11.4067 9.33655 
                    12 9.33655C12.7949 9.33902 13.5565 9.65588 14.1186 10.218C14.6807 10.78 14.9975 11.5417 15 12.3365ZM21.375 8.21155V16.4615C21.375 17.8539 20.8219 19.1893 19.8373 20.1739C18.8527 21.1584 17.5174 21.7115 
                    16.125 21.7115H7.875C6.48261 21.7115 5.14726 21.1584 4.16269 20.1739C3.17812 19.1893 2.625 17.8539 2.625 16.4615V8.21155C2.625 6.81916 3.17812 5.4838 4.16269 4.49924C5.14726 3.51467 6.48261 2.96155 7.875
                    2.96155H16.125C17.5174 2.96155 18.8527 3.51467 19.8373 4.49924C20.8219 5.4838 21.375 6.81916 21.375 8.21155ZM16.5 12.3365C16.5 11.4465 16.2361 10.5765 15.7416 9.83648C15.2471 9.09646 14.5443 8.51968 13.7221 
                    8.17909C12.8998 7.8385 11.995 7.74938 11.1221 7.92301C10.2492 8.09665 9.44736 8.52523 8.81802 9.15457C8.18868 9.7839 7.7601 10.5857 7.58647 11.4586C7.41283 12.3316 7.50195 13.2364 7.84254 14.0586C8.18314 14.8809
                    8.75991 15.5837 9.49993 16.0782C10.24 16.5726 11.11 16.8365 12 16.8365C13.1935 16.8365 14.3381 16.3624 15.182 15.5185C16.0259 14.6746 16.5 13.53 16.5 12.3365ZM18 7.46155C18 7.23904 17.934 7.02154 17.8104 6.83653C17.6868 
                    6.65153 17.5111 6.50733 17.3055 6.42218C17.1 6.33703 16.8738 6.31476 16.6555 6.35816C16.4373 6.40157 16.2368 6.50872 16.0795 6.66605C15.9222 6.82339 15.815 7.02384 15.7716 7.24207C15.7282 7.4603 15.7505 7.6865 15.8356 7.89207C15.9208
                    8.09763 16.065 8.27333 16.25 8.39695C16.435 8.52057 16.6525 8.58655 16.875 8.58655C17.1734 8.58655 17.4595 8.46802 17.6705 8.25704C17.8815 8.04606 18 7.75992 18 7.46155Z" fill="#1E1E1E"/>
                    </g>
                </svg>
            </SvgIcon>
        </a>
        <a className="ms-2 ms-md-2" href={linkedin_url || "https://www.linkedin.com/"} target="_blank">
            <LinkedInIcon sx={{ "& :hover": { color: "#000fff", backgroundColor: "#fff" },color:"#000", backgroundColor:"#fff" }} />
        </a>
    </div>
  )
}
export default SocialMediaIcons
