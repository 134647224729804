import React, { useEffect, useState } from "react";
import { Images } from "../../Constants/Images";
import "./home.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setUserAction } from "../../Redux/Actions/CommonAction";
import FooterSection from "../../components/Footer/FooterSection";
import useConfig from "../../hooks/useConfig";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import LanguageChange from "../../components/Footer/LanguageChange";
import { Button } from "@mui/material";
import useCustomTheme from '../../hooks/useCustomTheme';
import { resetLogin } from "../../Redux/auth/slice";
import { resetPet } from "../../Redux/pet/slice";
import { resetCalendar } from "../../Redux/calendar/slice";
import { resetDoctor } from "../../Redux/doctor/slice";
import { resetService } from "../../Redux/service/slice";
import logo from "../../images/logo.png";
import imageBG from "../../images/bgImg.jpg";

const Home = (props) => {
  const {
    btn_clinic_bg_color,
  } = useCustomTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const bookingLabels = t("data.frontLabels.booking", { returnObjects: true });
  const { handleStepAction, getStatus } = props;
  const [width, setWidth] = useState(window.innerWidth);
  const { config } = useConfig();
  const { homeBgUrl, logoUrl } = config;
  const dispatch = useDispatch();
  localStorage.removeItem("CLIENT_ID");
  localStorage.removeItem("MANAGE_BOOKING_TOKEN");
  localStorage.removeItem("EXISTING_USER_TOKEN");
  const isMobile = width < 820;
  const handleWindowSizeChange = () => setWidth(window.innerWidth);


  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [isMobile]);
  useEffect(() => {
    dispatch(resetDoctor());
    dispatch(resetCalendar());
    dispatch(resetPet());
    dispatch(resetService());
  }, [])

  const bgImg = homeBgUrl;

  const renderButton = () => {
    return (
      <div className="d-flex gap-sm-5 gap-3 w-100" style={{ width: isMobile ? "98% !important" : "100%", justifyContent: isMobile && "space-between", paddingLeft: isMobile && "12px", paddingRight: isMobile && "12px" }}>
        <Link to={`/booking`}>
          {/* <div>
                  <Link to={`/auth/login`} className="btn btn-warning button-min-width">
                    <p className="book-btn-text">Book Clinic</p>
                  </Link>
                </div> */}
          <Button
            variant="contained"
            className=" auth-btn auth-submit-btn-home auth-submit-btn"
            size="small"
            sx={{
              borderRadius: "8px",
              background: btn_clinic_bg_color,
              width: isMobile ? "48vw !important" : "100% !important",
              '&:hover': {
                // color: btn_clinic_bg_color,
                backgroundColor: btn_clinic_bg_color,
              }
            }}
          >
            <p className="book-btn-text-book text-nowrap">
              {bookingLabels?.book_appointment}
            </p>
          </Button>
        </Link>

        {/* <Link to={`/manage`} className="btn btn-warning button-min-width">
                  <p className="book-btn-text">Manage Booking</p>
                </Link> */}
        <Link to={`/manage`}>
          <Button
            variant="contained"
            className=" auth-btn auth-submit-btn-home auth-submit-btn"
            size="small"
            sx={{
              borderRadius: "8px",
              background: btn_clinic_bg_color,
              width: isMobile ? "42vw !important" : "100% !important",
              '&:hover': {
                // color: btn_clinic_bg_color,
                backgroundColor: btn_clinic_bg_color,
              }
            }}
          >
            <p className="book-btn-text-manage text-nowrap">{bookingLabels?.manage_booking}</p>
          </Button>
        </Link>

      </div>
    );
  }

  return (
    <>
      <div className="d-flex login-head-section ">

        {/* Left section */}
        <div className="h-100 padding-left-60px margin-for-mobile col-12 col-lg-6">
          <div className="d-flex justify-content-between align-items-center home-logo-contianer" style={{ marginTop: "18px" }}>
            <div className="">
              <img
                className="a_alda_logo_horizontal-2-2 home-logo-style cursor-pointer"
                src={logoUrl}
                alt="aalda logo"
                onError={(e) => e.target.src = logo}
                onClick={() => {
                  navigate('/');
                }}
              />
            </div>
          </div>
          {isMobile &&
            <div className="show-next-page container padding-zero" style={{ marginLeft: "-18px", maxWidth: "100%", height: "75px" }}>
              {renderButton()}
            </div>
          }

          {/* title-section */}
          <div className="d-flex mb-4 flex-column align-items-start text-custom-css-align" style={{ gridGap: "24px", marginTop: isMobile ? "20px" : "15px" }}>
            <div className="noto-font more-bold font-size-30px text-start welcome-text">
              {config?.footer?.heading_text || "Welcome To Our Booking Portal"}
            </div>
            <div className="noto-font light-weight font-size-20px text-justify welcome-text" style={{ fontSize: isMobile && "16px" }}>
              {config?.footer?.subheading_text || "Welcome To Our Booking Portal"}
            </div>
            <div className="noto-font light-weight-400 font-size-18px text-justify welcome-text" style={{ fontSize: isMobile && "14px" }}>
              {config?.footer?.paragraph_one || "Welcome To Our Booking Portal"}
            </div>
            <div className="noto-font light-weight-400 font-size-16px text-justify welcome-text" style={{ fontSize: isMobile && "12px" }}>
              {config?.footer?.paragraph_two || "Welcome To Our Booking Portal"}
            </div>





            {/* {isMobile && 
            <div className="index-book-arrow d-flex justify-content-center">
             
              <img src={bgImg} alt="image" style={{ width: '90%' }}  />
            </div>
            } */}

            {/* btn section */}
            {!isMobile &&
              renderButton()
            }

          </div>
          {isMobile &&
            <div className="index-book-arrow d-flex justify-content-center">
              <img src={bgImg} alt="image" style={{ width: '100%' }} onError={(e) => e.target.src = imageBG} />
            </div>
          }
        </div>

        {/* Right section */}
        {!isMobile &&
          <div className="d-flex col-lg-6 ">
            <img src={bgImg} alt="image" style={{ width: '100%' }} onError={(e) => e.target.src = imageBG} />
          </div>
        }

      </div>
      <FooterSection padding={isMobile ? "90px" : "6px"} />
    </>
  );
};

export default React.memo(Home);
