import { createSlice } from "@reduxjs/toolkit";
import { getSlots } from "./thunk";

const InitialState={
    type:null,
    loading: false,
    error: false,
    timeSlots: null,
    disabledDates: null,
    enabledDate: null,
    week: null,
    selectedDateIndex: -1,
    selectDate: new Date(),
    selectedSlotIndex: {
        morning: -1,
        noon: -1,
        evening: -1
    }
};

const calendarSlice= createSlice({
    name:"Calendar",
    initialState: InitialState,
    reducers: {
        // setDoctor(state, action) {
        //     state.doctor = action.payload
        // }
        setCalendarData(state, {payload}) {
            state.timeSlots = payload.timeSlots || state.timeSlots;
            state.disabledDates = payload.disabledDates || state.disabledDates;
            state.enabledDate = payload.enabledDate || state.enabledDate;
            // return state;
        },
        setCalendarWeek(state, {payload}) {
            state.week = payload.week;
            state.timeSlots = null;
            state.selectedSlotIndex = {
                morning: -1,
                noon: -1,
                evening: -1
            }
            state.selectedTime = null;
            state.selectedDateIndex = -1;
        },
        setSlot(state, {payload}) {
            state.selectedSlotIndex = payload.selectedSlotIndex || state.selectedSlotIndex;
            state.selectedTime = payload.selectedTime || state.selectedTime;
        },
        resetCalendar(state, action) {
            return InitialState;
        },
        setSelectDate(state, action) {
            state.selectDate = action.payload || new Date();
        }
    },
    extraReducers: (builder) => {
        // get facilities
        builder.addCase(getSlots.pending, (state, action) => {
            const payload = action?.meta?.arg;
            state.loading = true;
            state.selectedDateIndex = payload.hasOwnProperty("selectedDateIndex") ? payload.selectedDateIndex : 0;
            state.timeSlots = null;
            state.selectedSlotIndex = {
                morning: -1,
                noon: -1,
                evening: -1
            };
        })
        .addCase(getSlots.fulfilled, (state,{payload})=>{
            state.loading=false;
            state.timeSlots = payload.timeSlots || state.timeSlots;
            state.type = payload.type || state.type;
        })
        .addCase(getSlots.rejected, (state,{payload})=>{
            state.loading = false;
            state.services = [];
            state.error = true;
            state.message=payload;
        });
    },
});

export const { setCalendarData, setCalendarWeek, setSlot, resetCalendar, setSelectDate } = calendarSlice.actions;
export default calendarSlice.reducer;