export const LANGUAGES = {
    JP: "jp",
    EN: "en"

}
export const baseUrl = (()=>{
    const ENV_BASE_PATH = process.env.REACT_APP_BASE_URL
    const hostname = window.location.hostname
    const finalBase = ENV_BASE_PATH?.replace('domain', hostname)
    return finalBase;
}) ()
