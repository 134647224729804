import useConfig from "./useConfig";

function useCustomTheme() {
    const {config} = useConfig();
    if (!config || !config.footer) return {};

    const {
        background_color,
        btn_clinic_bg_color,
        btn_clinic_text_color,
        btn_telehealth_bg_color,
        btn_telehealth_text_color,
        lp_bg_color,
    } = config?.footer;

    const activeClass = {
      backgroundColor: btn_clinic_bg_color,
      color: btn_clinic_text_color
    }

    return {
      background_color,
      btn_clinic_bg_color,
      btn_clinic_text_color,
      btn_telehealth_bg_color,
      btn_telehealth_text_color,
      lp_bg_color,
      activeClass
    };
}

export default useCustomTheme;