import React from "react";
import { Images } from "../../Constants/Images";
const Button = (props) => {
  const {style} = props
  return (
    <>
      <button className={props?.className} onClick={props?.onClick} type={props?.type} style={style || {}}>
        {props?.isLeft ? (
          <span className="font-gottam semi-bold"><img src={Images.left_btn_arrow} alt="left arrow"/>  {props?.text}</span>
        ) : (
          <span className="font-gottam semi-bold">{props?.text}  <img src={Images.right_btn_arrow} alt="right arrow"/></span>
        )}
      </button>
    </>
  );
};

export default React.memo(Button);