import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios  from "../../Services/Api/Index";
import { API_PATH } from '../../Constants/ApiPath';
import { API_TYPE } from "../../Constants/ApiType";
import { LANGUAGES } from "../../Constants/Constant";


export const getConfig = createAsyncThunk(
  "facilities/getConfig",
  async(payload,{rejectWithValue})=>{
    try{
        const lang_code = localStorage.getItem("lang_type");
        const response = await Axios({
            method: API_TYPE.GET,
            url: API_PATH.CONFIG,
            headers: {
              "Accept-Language": lang_code || LANGUAGES.JP,
            },
        });
        const data = await response.data;
        if (data.status === "success" && data.statuscode === 200) {
          return data.data;
        }
        throw new Error(data.message || "Something went wrong!");
    }catch(err){
        console.log("err - ",err);
        return rejectWithValue("Opps there seems to be an error");
    }
  }
);