import { createSlice } from "@reduxjs/toolkit";
import { getConfig } from "./thunk";
import { LANGUAGES } from "../../Constants/Constant";

const InitialState = {
    primaryColor: "",
    secondaryColor: "",
    logoUrl: "",
    footerUrl: "",
    homeImageUrl: "",
    footer: null,
    loading: true,
    error: false,
    homeBgUrl: "",
    lang: LANGUAGES.JP,
    imageBaseUrl: null,
    steps: 6
    // facilities: [],
    // facility: null
};

const configSlice = createSlice({
    name:"Config",
    initialState: InitialState,
    reducers: {
        setLang(state, action) {
            state.lang = action.payload;
        },
        setStep(state, action) {
            state.steps = action.payload || state.steps;
        }
    },
    extraReducers: (builder) => {
        // get config
        builder.addCase(getConfig.pending, (state) => {
            state.loading = true;
        })
        .addCase(getConfig.fulfilled,(state,{payload})=>{
            state.loading=false;
            state.logoUrl = payload.top_logo;
            state.footer = payload.footerSetting;
            state.homeBgUrl = payload.page_bg_image;
            state.imageBaseUrl = payload.imageBaseUrl;
            state.footerLogo = payload.footer_logo;
        })
        .addCase(getConfig.rejected,(state,payload)=>{
            state.loading = false;
            // state.visits = [];
            state.error = true;
        });
    },
});

export const { setLang, setStep } = configSlice.actions;
export default configSlice.reducer;