import { useTranslation } from 'react-i18next';

const envLang = process.env.REACT_APP_LANG_TYPE;

const useCurrentLanguage = () => {
    const { i18n } = useTranslation();
    const getLanguage = i18n.language;
    const langLocal = localStorage.getItem("lang_type");
    
    return getLanguage || langLocal || envLang || "jp";
};

export default useCurrentLanguage;
