import { Outlet, useParams } from "react-router";
import { Images } from "../Constants/Images";
import useConfig from "../hooks/useConfig";
import "./AuthLayout.css";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import LanguageChange from "../components/Footer/LanguageChange";
import FooterSection from "../components/Footer/FooterSection";
import { useLocation } from 'react-router-dom';
import logo from "../images/logo.png";
import imageBG from "../images/bgImg.jpg";

export default function AuthLayout(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => setWidth(window.innerWidth);
    const isMobile = width <= 568;

    useEffect(() => {
        window.scroll(0, 0);
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };

    }, []);
    const isLoginPage = location.pathname === '/auth/login';

    const { config } = useConfig();
    const { homeBgUrl, logoUrl } = config;
    const homeBgSection = {
        // backgroundImage: homeBgUrl ? `url(${homeBgUrl})` : `url(${imageBG})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: `${isMobile ? "center 30%" : "center"}`,
        height: `${isMobile && "190px"}`,
        width: `${isMobile && "90%"}`,
        backgroundSize: `${!isMobile ? "100% 100%" : "cover"}`,
        margin: "auto"
    }
    return (
        <>
            {/* <section id="auth-layout" className="d-flex flex-column-reverse flex-sm-row vh-100">
                <div className="w-100 w-sm-100 h-100">
                    {props.children}
                </div>
                <div className={`auth-bg-section w-100 w-sm-50`} style={homeBgSection}>
                </div>
            </section> */}
            <section id="auth-layout" className="row">
            <div className="col-lg-6">
                <div className="auth-logo-wrapper-mobile justify-content-between align-items-center my-sm-2 my-0 px-3">
                    <div className='login-header-logo-container mb-3 mt-2'>
                        <img
                            className="a_alda_logo_horizontal-2-2 fixed-box-image cursor-pointer"
                            src={logoUrl}
                            // src={logoUrl}
                            alt="A_alda_logo_horizontal 2 2"
                            onClick={() => {
                                navigate('/');
                            }}
                            onError={(e) => e.target.src = logo}
                        /> 
                    </div>
                    <div className="text-nowrapr mobile-language-change">
                        {/* <LanguageChange display={"d-flex"}/> */}
                    </div>
                </div>
                <div id='image-container' className="mt-4 mt-sm-0 img-container">
                    <img
                        className={`auth-bg-section  w-sm-50`} 
                        style={homeBgSection}
                        src={homeBgUrl}
                        onError={(e) => e.target.src = imageBG}
                    >
                    </img>
                </div>
                </div>
                <div className="col-lg-6">
                <div className="w-sm-50 w-100 ">
                    <div className="w-100 d-flex justify-content-between align-items-center mt-2 px-3 ">
                        <div className='auth-logo-wrapper login-header-logo-container'>
                            <img
                                className="a_alda_logo_horizontal-2-2 fixed-box-image cursor-pointer"
                                // src={logoUrl}
                                src={logoUrl}
                                alt="A_alda_logo_horizontal 2 2"
                                onClick={() => {
                                    navigate('/');
                                }}
                                onError={(e) => e.target.src = logo}
                            />
                        </div>
                        <div className="text-nowrap auth-logo-wrapper">
                            {/* <LanguageChange display={"d-flex"}/> */}
                        </div>
                    </div>
                    <div className="" style={isLoginPage ? mainpagecontent : {}}>
                        {props.children}
                    </div>

                </div>
                </div>
                <div className='position-relative'>
                <FooterSection padding={"6px"} />
            </div>
            </section>
        </>
    )
}
const mainpagecontent = {
    height: 'calc(100% - 50px)',
    overflow: 'auto',
};