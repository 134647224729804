import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Images } from "../../Constants/Images";
import "./Calendar.css";
import NoDataAvailable from "../NoDataAvailable";
import { useTranslation } from "react-i18next";
import { setSlot } from "../../Redux/calendar/slice";
import useCustomTheme from "../../hooks/useCustomTheme";
import useCurrentLanguage from "../../hooks/useCurrentLanguage";
import { LANGUAGES } from "../../Constants/Constant";

const TimeSlots = (props) => {
  const { activeClass } = useCustomTheme();
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState()
  const { t } = useTranslation();
  const frontLabels = t("data.frontLabels", { returnObjects: true });
  const calendarReducer = useSelector((state) => state.calendar);
  const { timeSlots, selectedSlotIndex, type } = calendarReducer;
  const morningSlot = timeSlots !== null ? timeSlots?.filter((time) => time < "12:00") : [];
  const afternoonSlot = timeSlots !== null ? timeSlots?.filter((time) => time >= "12:00" && time < "17:00") : [];
  const afternoonSlotJP = timeSlots !== null ? timeSlots?.filter((time) => time >= "12:00" && time < "23:59") : [];
  const eveningSlot = timeSlots !== null ? timeSlots?.filter((time) => time >= "17:00" && time <= "23:59") : [];

  const handleSlot = (session, item, selected) => {
    const payload = {
      selectedSlotIndex: {
        morning: -1,
        noon: -1,
        evening: -1
      },
      selectedTime: item,
      // session,

    };
    payload["selectedSlotIndex"][session.toLowerCase()] = selected;
    dispatch(setSlot(payload));
  };

  const renderTimeSlots = (session, slots) => {
    return (
      slots?.length > 0 &&
      slots?.map((item, index) => (
        <div className="booking-slots" key={index}>
          <div className="side-padding">
            <button
              className={`time-slots noto-font`}
              style={selectedSlotIndex?.[session.toLowerCase()] === index ? activeClass : {}}
              onClick={() => handleSlot(session, item, index)}
            >
              {item}
            </button>
          </div>
        </div>
      ))
    );
  };
  const lang = useCurrentLanguage();
  return (
    <>
      {timeSlots?.length > 0 ? (
        <div className="pt-4">
          {morningSlot?.length > 0 && (
            <div className="times mt-4 display-time-section d-flex align-items-center">
              <div className="left-part">
                <img src={Images.morning_icon} alt="morning slot" />
                <div className="noto-font mt-1 session-text-color">{frontLabels?.booking?.morning}</div>
              </div>
              <div className="d-flex flex-wrap w-100 manage-alignment">
                {renderTimeSlots(
                  frontLabels?.booking?.morning,
                  morningSlot?.map((slot) => slot)
                )}
              </div>
            </div>
          )}

          {morningSlot?.length > 0 && <div className="line-shade"></div>}

          {lang === LANGUAGES.JP ?<div>
            {afternoonSlotJP?.length > 0  && (
              <div className="times mt-3  display-time-section d-flex align-items-center">
                <div className="left-part">
                  <img src={Images.noon_icon} alt="noon slot" />
                  <div className="noto-font mt-1 session-text-color">{frontLabels?.booking?.noon}</div>
                </div>
                <div className="d-flex flex-wrap w-100 manage-alignment">
                  {renderTimeSlots(
                    frontLabels?.booking?.noon,
                    afternoonSlotJP?.map((slot) => slot)
                  )}
                </div>
              </div>
            )}
            {afternoonSlotJP?.length > 0  && <div className="line-shade"></div>}

          </div> : <div>
            {afternoonSlot?.length > 0 && (
              <div className="times mt-3  display-time-section d-flex align-items-center">
                <div className="left-part">
                  <img src={Images.noon_icon} alt="noon slot" />
                  <div className="noto-font mt-1 session-text-color">{frontLabels?.booking?.noon}</div>
                </div>
                <div className="d-flex flex-wrap w-100 manage-alignment">
                  {renderTimeSlots(
                    frontLabels?.booking?.noon,
                    afternoonSlot?.map((slot) => slot)
                  )}
                </div>
              </div>
            )}
            {afternoonSlot?.length > 0 && <div className="line-shade"></div>}

            {eveningSlot?.length > 0  && (
            <div className="times mt-3  display-time-section d-flex align-items-center">
              <div className="left-part">
                <img src={Images.evening_icon} alt="evening slot" />
                <div className="noto-font mt-1 session-text-color">{frontLabels?.booking?.evening}</div>
              </div>
              <div className="d-flex flex-wrap w-100 manage-alignment">
                {renderTimeSlots(
                  frontLabels?.booking?.evening,
                  eveningSlot?.map((slot) => slot)
                )}
              </div>
            </div>
          )}
           </div> }
        </div>
      ) : (<NoDataAvailable images={Images.no_slot} headMessage={frontLabels?.booking?.no_slots} subHeadMessage={frontLabels?.booking?.please_change_the_date} message={frontLabels?.booking?.no_slots} />)}
    </>
  );
};

export default React.memo(TimeSlots);
