import React from 'react'
import App from './App'
import CacheBuster from './CacheBuster'

export const MegaApp = () => {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }
        return (
          <App />
        );
      }}
    </CacheBuster>
  )
}
