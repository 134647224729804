import React, { useState, useEffect } from "react";
import StepHeader from "../StepHeader/StepHeader";
// import Button from "../Button/Button";
import SessionSummary from "../SessionSummary/SessionSummary";
import { useDispatch, useSelector } from "react-redux";
// import { Images } from "../../Constants/Images";
import { useTranslation } from "react-i18next";
import "../SelectFacility/SelectFacility.css";
import "./EnterInfo.css";
import useCustomTheme from "../../hooks/useCustomTheme";
import { createAppointment } from "../../Redux/appointment/thunk";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { TOAST_TIMER } from "../../Constants/Others";
import Button from "../Button/Button";

const EnterInfoExistingAndManageUsers = (props) => {
  let globaltimeout = null;
  const { handleStepAction, statusName } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    activeClass
  } = useCustomTheme();
  const [notes, setNotes] = useState("");
  const [recentError, setRecentError] = useState(null);
  const frontLabels = t("data.frontLabels", { returnObjects: true });
  const frontMessages = t("data.frontMessages", { returnObjects: true });
  const doctorReducer = useSelector(state => state.doctor);
  const facilityReducer = useSelector(state => state.facility);
  const serviceReducer = useSelector(state => state.service);
  const calendarReducer = useSelector(state => state.calendar);
  const petReducer = useSelector(state => state.pet);
  const appointmentReducer = useSelector(state => state.appointment);
  // const { config } = useSelector(state => state.config);
  const config = useSelector(state => state.config);
  const { steps } = config || { steps: 6 };
  const { appointmentError, appointmentSuccess, loading } = appointmentReducer;
  const { doctor } = doctorReducer;
  const { facility } = facilityReducer;
  const { service } = serviceReducer;
  const { week, selectedDateIndex, selectedTime } = calendarReducer;
  const { pet } = petReducer;
  const [width, setWidth] = useState(window.innerWidth);
  // const switchToContactInfo = () => {
  //   toggleElementDisplay("contact-info", "none");
  //   toggleElementDisplay("pet-info", "block");
  // };
  // const backToEnterInfo = () => {
  //   toggleElementDisplay("contact-info", "block");
  //   toggleElementDisplay("pet-info", "none");
  // };

  // const toggleElementDisplay = (elementId, displayValue) => {
  //   let element = document.getElementById(elementId);
  //   if (element) {
  //     element.style.display = displayValue;
  //   }
  // };
  const handleWindowSizeChange = () => setWidth(window.innerWidth);
  useEffect(() => {
    window.scrollTo(0, 0);
    // getPreferedSpeciesApiCall();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (appointmentSuccess) {
      toast.success(appointmentSuccess);
      handleStepAction(4);
    }
    if (appointmentError && recentError!==appointmentError) {
      toast.error(appointmentError, { autoClose: TOAST_TIMER });
      setRecentError(appointmentError)
      if(globaltimeout) clearTimeout(globaltimeout)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      globaltimeout = setTimeout(()=>{
        setRecentError(null)
      },2500)
    }
  }, [appointmentSuccess, appointmentError]);

  const isMobile = width <= 768;
  const onHandleSubmit = () => {
    const payload = {
      type: "in_person",
      clinic: facility.id,
      duration: service.duration,
      service: service.id,
      selectdate: week[selectedDateIndex]["date"],
      timeSlot: selectedTime,
      appointment_notes: notes,
      pet_id: pet.id,
      any_doctor: doctor && doctor.user_id === "Any" ? "0" : "1",
      provider: doctor && doctor.user_id !== "Any" ? doctor.user_id : "any"
    }
    dispatch(createAppointment(payload));
  }
  return (
    <div className="background-color-info enterInfoExistingAndManageUsers-container">
      <div className={`padding-130 first-step pointer ${statusName === frontLabels?.booking?.manage_booking ? "min-height-area background-color-info" : ""}`}>
        <section
          className="container first-section pt-5 pt-sm-1  "
          id="contact-info"
        >
          <div className="div-desktop">
            {!isMobile ? <StepHeader
              title={`${`${service && service.service_for === "provider" ? "06" : "05"}. ${frontLabels?.booking?.ntr_appt_notes || "Enter Your Appointment Notes "}`}`}
              step={`${frontLabels?.booking?.STEP} ${service && service.service_for === "provider" ? "06" : "05"} ${frontLabels?.booking?.OF} 0${steps}`} />
              : <StepHeader
                title={`${`${service && service.service_for === "provider" ? "06" : "05"}. ${frontLabels?.booking?.ntr_appt_notes || "Enter Your Appointment Notes "}`}`}
                step={`${frontLabels?.booking?.STEP} ${service && service.service_for === "provider" ? "07" : "05"} ${frontLabels?.booking?.OF} 0${steps}`} />}
          </div>
          <div
            className="appointment-notes-summary pt-2 pt-sm-1 pb-4 ms-lg-2 ms-0"
          >
            <div className="d-lg-flex gap-5 justify-content-center flex-row-reverse appointment-note-container">
              {/* session summary */}
              <div className="w-100 w-md-50 ">
                {/* <div className="summary noto-font">{frontLabels?.booking?.summary}</div> */}
                <SessionSummary />
              </div>
              {/* test box */}
              <div className="w-100 w-md-50 mb-2 px-3 px-md-0">
                <div className="form-group">
                  {/* <label className="summary noto-font">Appointment Notes</label> */}
                  <textarea
                    rows="7"
                    placeholder={frontMessages?.BOOK_CTL_APPT_NOTE_REQ || "Enter appointment Notes"}

                    className="form-control h-50 mt-2 px-2 appointment-note-textarea"
                    value={notes}
                    maxLength={500}
                    onChange={(e) => {
                      setNotes(e.target.value)
                    }}
                  // row={5}
                  />
                  <span className={`float-end ${notes.length >= 500 && `text-danger`}`}><small>{`${notes.length}/500`}</small></span>
                  {
                    ((notes.length >= 500) && <span className="float-start text-danger"><small>{frontLabels?.booking?.max_char_limit || "maximum number of characters is 500"}</small></span>)
                  }

                </div>
              </div>
            </div>

          </div>

        </section>

        {/* test */}
        <div className="hide-for-mobile">
          <div className="show-next-page container next-previous-button">
            <Button
              className={`btn btn-lg previous-button-style font-text btn-prv-color`}
              isLeft
              text={frontLabels?.booking?.previous}
              onClick={() => handleStepAction(2)}
            />
            {!loading ? <Button
              className={`btn btn-lg font-text`}
              style={activeClass}
              text={frontLabels?.booking?.book_submit}
              onClick={onHandleSubmit}
            /> : <CircularProgress />}


          </div>
        </div>
      </div>
      {isMobile &&
        <div className={`show-next-page container padding-zero `} s >
          <Button
            className="next-button previous-button switch-to-facility"
            // style={activeClass}
            isLeft
            text={frontLabels?.booking?.previous}
            onClick={() => {
              if (service.service_for === "provider") {
                handleStepAction(2)
              } else {
                handleStepAction(1)
              }
            }}
          />
          <div></div>
          {!loading ? <Button
            className="next-button switch-to-facility"
            style={activeClass}
            text={frontLabels?.booking?.book_submit}
            onClick={onHandleSubmit}
          /> : <div style={{ marginRight: "11%" }}>
            <CircularProgress />
          </div>}

        </div>
      }
    </div>
  );
};

export default React.memo(EnterInfoExistingAndManageUsers);
