import React from "react";
import './ErrorBoundray.css';
import SomeThingWrong from "../../images/SomeThingWrong.png";
import FooterSection from "../Footer/FooterSection";
import { Button } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log(error)
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.log(error, errorInfo)
  }

  handleBackToHome = () => {
    // Navigate to the booking page
    window.location.href = "/booking"
  }

  render() {
    const { t } = this.props;
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <div className="error-container">
            <h1 className="error-heading">{t("data.frontLabels.booking.someting_wrong_text")}</h1>
            <Button
              variant="contained"
              style={{ marginTop: '12px', backgroundColor: '#02043A', color: 'white' }}
              onClick={this.handleBackToHome}
            >
              {t("data.frontLabels.booking.back_to_home_button")}
            </Button>
            <img
              src={SomeThingWrong}
              alt="Error Illustration"
              className="error-image"
            />
          </div>
          <FooterSection />
        </>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
