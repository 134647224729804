import React from "react";

const NoDataAvailable = (props) => {
  const { images, headMessage, subHeadMessage,message } = props;
  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center text-center margin-top-4rem noto-font more-bold no-slot-color pb-5">
        <div>
          {/* <img src={images} alt={message} /> */}
        </div>
        <div className="font-size-1-125rem">{headMessage}</div>
        <div className="font-size-1-375rem">{subHeadMessage}</div>
      </div>
    </>
  );
};

export default NoDataAvailable;
