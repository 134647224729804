import { useEffect, useState } from 'react';
import packageJson from '../package.json';

global.appVersion = packageJson.version;

const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);
    const versionsB = versionB.split(/\./g);

    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());
        const b = Number(versionsB.shift());

        if (a === b) continue;

        return a > b || isNaN(b);
    }

    return false;
};

const CacheBuster = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [isLatestVersion, setIsLatestVersion] = useState(false);

    const refreshCacheAndReload = () => {
        console.log('Clearing cache and hard reloading...')

        if (caches) {
            caches.keys().then(function (names) {
                for (let name of names) caches.delete(name);
            });
        }
        window.location.reload(true);
    };

    useEffect(() => {
        fetch(`/meta.json`
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((meta) => {
                const latestVersion = meta.version;
                const currentVersion = global.appVersion;

                const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
                if (shouldForceRefresh) {
                    console.log(`We have a new version - ${latestVersion}. Should force refresh`);
                    setIsLatestVersion(false);
                } else {
                    console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                    setIsLatestVersion(true);
                }
                setLoading(false);
            });
    }, []);

    if (loading) return null;

    if (!loading && !isLatestVersion) {
        refreshCacheAndReload();
    }

    return children({ loading, isLatestVersion, refreshCacheAndReload });
};

export default CacheBuster;