import { createSlice } from "@reduxjs/toolkit";
import { getDoctors } from "./thunk";

const InitialState={
    loading: false,
    doctorError: false,
    doctors: [],
    doctor: null
};

const doctorSlice= createSlice({
    name:"Doctors",
    initialState: InitialState,
    reducers: {
        setDoctor(state, action) {
            state.doctor = action.payload
        },
        resetDoctor(state, action) {
            return InitialState
        }
    },
    extraReducers: (builder) => {
        // get facilities
        builder.addCase(getDoctors.pending, (state) => {
            state.loading = true;
        })
        .addCase(getDoctors.fulfilled, (state,{payload})=>{
            state.loading=false;
            state.doctors = payload.providers;
            state.type = payload.type;
        })
        .addCase(getDoctors.rejected, (state,payload)=>{
            state.loading = false;
            state.services = [];
            state.doctorError = true;
        });
    },
});

export const { setDoctor, resetDoctor } = doctorSlice.actions;
export default doctorSlice.reducer;